<template>
    <el-dialog
        v-if="visible"
        title="生成规划"
        class="add-dialog"
        width="8rem"
        :visible.sync="visible"
        :before-close="handleCancel"
        :destroy-on-close="true"
        :append-to-body="true"
    >
        <div class="mb20 d-flex">
            <span>规划时间：</span>
            <el-date-picker
                v-model="form.dataYearMonth"
                class="w240"
                type="month"
                placeholder="选择年月"
                value-format="yyyy-MM"
            >
            </el-date-picker>
            <el-input
                v-model="keyword"
                class="w240 ml20"
                type="text"
                clearable
                placeholder="输入组织名称筛选组织"
            ></el-input>
        </div>
        <el-table
            :data="allChildDepsForKeyword"
            border
            style="width: 100%;"
            max-height="400"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                type="selection"
                width="55"
            >
            </el-table-column>
            <el-table-column
                prop="groupName"
                align="center"
                label="组织"
                show-overflow-tooltip
            >
            </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
import accountSvc from 'services/accounting'
import moment from 'moment'

export default {
    name: 'RulesDialog',
    data () {
        return {
            keyword: '',
            form: {
                groupIds: [],
                dataYearMonth: null
            },
            visible: false
        }
    },
    computed: {
        ...mapState({
            allChildDeps: state => state.customer.allChildDeps
        }),
        allChildDepsForKeyword () {
            return this.allChildDeps.filter(item => {
                return item.groupName.indexOf(this.keyword) > -1
            })
        }
    },
    methods: {
        handleOpenDialog () {
            this.getRules()
            this.form.dataYearMonth = moment().format('YYYY-MM')
            this.handleVisible(true)
        },

        handleVisible (visible) {
            this.visible = visible
            if (!visible) {
                this.resetQuery()
            }
        },
        handleCancel () {
            this.handleVisible(false)
        },
        async handleConfirm () {
            if (this.form.groupIds.length === 0) {
                this.$message.warning('请选择组织')
                return
            }
            if (!this.form.dataYearMonth) {
                this.$message.warning('请选择规划时间')
                return
            }
            const data = await accountSvc.addAccountingPlan(this.form)
            this.$emit('handle-rules')
            this.handleVisible(false)
        },
        resetQuery () {
            // 重置
            this.keyword = ''
        },
        getRules () {
            // 获取规则
        },
        handleSelectionChange (val) {
            this.form.groupIds = val.map(({ id }) => id)
        }
    }
}
</script>
<style lang="scss" scoped>
.mb20 {
    margin-bottom: 20px;
}

.w240 {
    width: 240px;
}

.ml20 {
    margin-left: 20px;
}

.d-flex {
    display: flex;
    align-items: center;
}
</style>
