<template>
    <div class="weeks-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            @change="handleChange"
        >
            <el-option
                v-for="item in weekss"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'WeeksSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            maxweeks: 7,
            selected: ''
        }
    },
    computed: {
        weekss () {
            const titles = {
                1: '一',
                2: '二',
                3: '三',
                4: '四',
                5: '五',
                6: '六',
                7: '七'
            }
            return new Array(this.maxweeks).fill(0).map((_, index) => {
                return {
                    label: `星期${titles[index + 1]}`,
                    value: index + 1
                }
            })
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.weeks-select {
    display: inline-block;
    width: 90px;
}
</style>
