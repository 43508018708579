<template>
    <div class="planning-fill-query">
        <search-query
            v-bind="$attrs"
            :is-show-fold="true"
            v-on="$listeners"
        >
            <template v-slot:default="{ query, handleSearch }">
                <!-- <el-cascader
                    ref="cascaderHandle"
                    v-model="query.groupId"
                    class="w240 mr40"
                    :options="groupTree"
                    :show-all-levels="false"
                    :props="{
                        checkStrictly: true,
                        value: 'id',
                        label: 'groupName',
                        emitPath: false,
                    }"
                    clearable
                    :filterable="true"
                    placeholder="请选择组织"
                    @change="handleClose($event)"
                ></el-cascader> -->
                <el-select
                    v-model="query.groupId"
                    filterable
                    placeholder="请选择组织"
                    class="w240 mr40"
                    clearable
                    @change="handleSearch"
                >
                    <el-option
                        v-for="leafDept in allChildDeps"
                        :key="leafDept.id"
                        :value="leafDept.id"
                        :label="leafDept.groupName"
                    >
                    </el-option>
                </el-select>
                <el-date-picker
                    v-model="query.dataYearMonth"
                    class="w240 mr40"
                    type="month"
                    placeholder="选择日期"
                    value-format="yyyy-MM"
                    @change="handleSearch"
                >
                </el-date-picker>
                <!-- <el-select
                    v-model="query.statuss"
                    class="w240 mr40"
                    placeholder="提交状态"
                    :filterable="true"
                    :multiple="true"
                    clearable
                    collapse-tags
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in budgetList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    >
                    </el-option>
                </el-select> -->
                <gb-dictionary
                    v-model="query.statuss"
                    class="w240 mr40"
                    group="管会规划状态"
                    :clearable="true"
                    :filterable="true"
                    :multiple="true"
                    collapse-tags
                    placeholder="提交状态"
                    @change="handleSearch"
                ></gb-dictionary>
                <!-- <el-input
                    v-model="query.approvalCode"
                    class="w240 mr40"
                    placeholder="关联审批单号"
                    clearable
                    @keyup.enter="handleSearch"
                    @change="handleSearch"
                >
                </el-input> -->
                <el-input
                    v-model="query.submitter"
                    class="w240 mr40"
                    placeholder="提交人"
                    clearable
                    @keyup.enter="handleSearch"
                    @change="handleSearch"
                >
                </el-input>
            </template>
        </search-query>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'
import gbDictionary from '@/components/dictionary'
import utils from 'utils'
import oaSvc from 'services/oa'

const SearchQuery = () => import('components/search-query')

export default {
    name: 'BillQuery',
    // inject: ['transfer'],
    components: {
        SearchQuery,
        gbDictionary
    },
    computed: {
        ...mapState({
            allChildDeps: state => state.customer.allChildDeps
        }),
        groupTree () {
            if (_.isEmpty(this.gropus)) {
                return []
            }
            return utils.makeDataToTree(
                this.gropus,
                'parentId',
                'id',
                'groupName'
            )
        }
    },
    methods: {
        handleClose (val) {
            const cascader = this.$refs['cascaderHandle']
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.planning-fill-query {
    .w240 {
        width: 240px;

        ::v-deep.el-select {
            width: 240px !important;
        }
    }

    .wrem350 {
        width: 4.5rem;
    }

    .mrrem40 {
        margin-right: 0.4rem;
    }

    .mbrem10 {
        margin-bottom: 0.1rem;
    }

    .mtrem20 {
        margin-top: 0.2rem;
    }

    .mbrem20 {
        margin-bottom: 0.2rem;
    }

    .mbrem30 {
        margin-bottom: 0.3rem;
    }

    .custom-h {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .w60 {
        width: 60px;
    }

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}
</style>
