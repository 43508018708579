<template>
    <div class="budget-projects">
        <!-- todo查询 -->
        <query
            ref="query"
            :cate-dicts="dictsObj.cateDicts"
            :peoperty-dicts="dictsObj.peopertyDicts"
            @handle-search="initData"
        ></query>
        <list
            :list-data="list"
            :show-all="showAll"
            @handle-search="resetQuery"
        ></list>
        <div class="page-bottom">
            <el-button
                v-if="!showAll"
                type="text"
                @click="expandAll(true)"
            >
                展开全部表格
            </el-button>
            <el-button
                v-else
                type="text"
                @click="expandAll(false)"
            >
                收起全部
            </el-button>
            <!-- <el-pagination
                v-if="tableData && tableData.length"
                :current-page="current"
                @current-change="handlesCurrentChange"
                @size-change="handlesSizeChange"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination> -->
        </div>
    </div>
</template>

<script>
import BreadSlotMixin from 'mixins/breadSlot'
import { list, query, breadSlot } from './components/index'
import accountingSvc from 'services/accounting'
import comSvc from 'services/common'
// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })
export default {
    name: 'BudgetProjects',
    components: { list, query },
    mixins: [breadSlotMixin],
    data () {
        return {
            tableData: [],
            list: [],
            // size: 20,
            // current: 1,
            // total: 0,
            showAll: false,
            cateDicts: [],
            peopertyDicts: [],
            huanbijizhunDicts: [],
            budgetTypeDicts: [],
            planTypeDicts: [],
            dailyTypeDicts: [],
            actualTypeDicts: [],
            dictsObj: {
                budgetOption: []
            }
        }
    },
    created () {
        this.initData()
        this.getDictionary()
        this.getBudgetListCommon()
    },
    provide () {
        return {
            dictsObj: this.dictsObj
        }
    },
    methods: {
        async initData (query = { enabled: true }) {
            // query.size = this.size
            // query.current = this.current
            const res = await accountingSvc.getAccoutingProjects(query)
            // this.total = res.total
            this.list = res.data
            // this.tableData = makeDataToTree(res.data, 'parentId', 'id')
        },
        async getDictionary () {
            const { data = [] } = await comSvc.getDictionary(
                '管会收支项目分类,管会收支项目属性,管会同环比基准,管会预算填写方式,管会迭代规划方式,管会日常管理方式,管会实际取数方式'
            )
            this.dictsObj.cateDicts = data.filter(
                item => item.itemGroup === '管会收支项目分类'
            )
            this.dictsObj.peopertyDicts = data.filter(
                item => item.itemGroup === '管会收支项目属性'
            )
            this.dictsObj.huanbijizhunDicts = data.filter(
                item => item.itemGroup === '管会同环比基准'
            )
            this.dictsObj.budgetTypeDicts = data.filter(
                item => item.itemGroup === '管会预算填写方式'
            )
            this.dictsObj.planTypeDicts = data.filter(
                item => item.itemGroup === '管会迭代规划方式'
            )
            this.dictsObj.dailyTypeDicts = data.filter(
                item => item.itemGroup === '管会日常管理方式'
            )
            this.dictsObj.actualTypeDicts = data.filter(
                item => item.itemGroup === '管会实际取数方式'
            )
        },
        // 获取收支项目列表
        async getBudgetListCommon () {
            const { data = [] } = await accountingSvc.getBudgetListCoefficient()
            this.dictsObj.budgetOption = data
        },
        expandAll (isExpand) {
            this.showAll = !this.showAll
            // this.expandRowKeys = this.tableData.map(item => item.id + '')
        },
        resetQuery (data, isEdit) {
            // 新增收支项目后需要清空查询条件 重新查询
            // 编辑收支项目保存后 需要保持查询条件 不需要重置查询
            let param = isEdit ? this.$refs.query.searchForm : data
            this.initData(param)
        }
    }
}
</script>
<style lang="less" scoped>
.page-bottom {
    display: flex;
    padding: 15px 0;

    .el-pagination {
        flex: 1;
        text-align: right;
    }
}
</style>
