<template>
    <el-form class="page-search" inline>
        <div class="left">
            <el-form-item label="">
                <el-input
                    v-model="searchForm.titleLike"
                    placeholder="收支项目"
                    clearable
                    @clear="queryData"
                    @keyup.native.enter="queryData"
                ></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-input
                    v-model="searchForm.itemNoLike"
                    placeholder="编码"
                    clearable
                    @keyup.native.enter="queryData"
                    @clear="queryData"
                ></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select
                    v-model="searchForm.itemCategory"
                    placeholder="分类"
                    clearable
                    @change="queryData"
                >
                    <el-option
                        v-for="item in cateDicts"
                        :key="item.value"
                        :value="item.itemValue"
                        :label="item.title"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-select
                    v-model="searchForm.internalFlag"
                    placeholder="是否是系统"
                    clearable
                    @change="queryData"
                >
                    <el-option :value="true" label="是"></el-option>
                    <el-option :value="false" label="否"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-select
                    v-model="searchForm.resultSign"
                    placeholder="请选择属性"
                    clearable
                    @change="queryData"
                >
                    <el-option
                        v-for="item in peopertyDicts"
                        :key="item.value"
                        :value="item.itemValue"
                        :label="item.title"
                    ></el-option>
                </el-select>
            </el-form-item>
        </div>
        <div class="right">
            <div class="search-btn">
                <div class="custom-btn">
                    <el-button
                        type="primary"
                        @click="queryData"
                    >
                        查询
                    </el-button>
                    <el-button @click="restSearchForm">重置</el-button>
                </div>
            </div>
        </div>
    </el-form>
</template>

<script>
export default {
    name: 'BudgetProjectsQuery',
    // inject: ['dictsObj'],
    props: {
        cateDicts: {
            type: Array,
            default: null
        },
        peopertyDicts: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            searchForm: {
                enabled: true
            }
        }
    },
    methods: {
        queryData () {
            // 请求接口
            this.$emit('handle-search', this.searchForm)
        },
        restSearchForm () {
            this.searchForm = {
                enabled: true
            }
            this.$emit('handle-search', this.searchForm)
        }
    }
}
</script>
<style lang="less" scoped>
//
</style>
