<template>
    <div class="accounting-daily">
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <el-form-item label="">
                    <department
                        ref="dept"
                        v-model="searchForm.groupId"
                        api-name="getOwnDepts"
                        :is-cascader="true"
                        value-filed="id"
                        label-filed="groupName"
                        @change="deptChange"
                    />
                </el-form-item>
                <el-form-item label="">
                    <div
                        v-for="(item, name) in dateGroup"
                        :key="name"
                        class="custom-radio-item"
                        :class="{ 'radio-checked': item.type === checkedName }"
                        @click="selectDateType(item)"
                    >
                        {{ item.title }}
                        <el-date-picker
                            :ref="`date-${item.type}`"
                            v-model="item.value"
                            :type="item.type"
                            :picker-options="pickerOptions"
                            @change="handleDateTypeChange(item.type)"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :clearable="false"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="">
                    <el-input
                        v-model="searchKey"
                        :placeholder="currentGroup.leaf ? '产品搜索' : '店铺搜索'"
                        clearable
                        @clear="querydata"
                        @keyup.native.enter="querydata"
                    ></el-input>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button type="primary" @click="querydata"> 查询 </el-button>
                        <!-- <el-button @click="reset">重置</el-button> -->
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="addData">
                    <i class="iconfont icon-bianji">数据补录</i>
                </span>
                <!-- <span>
          <i class="iconfont icon-shezhi">元素维护</i>
        </span> -->
                <span>
                    <el-dropdown trigger="click">
                        <i class="iconfont icon-gengduocaozuo">更多操作</i>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                @click.native="handleRefesh"
                            >刷新数据</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <span class="tip">
                    <!-- 最近更新时间：{{ $moment().format("YYYY/MM/DD HH:mm:ss") }} -->
                    展示比例为基于去退款后GMV的费率
                </span>
            </div>
            <div class="gb-table">
                <el-table ref="table" :data="tableData" height="5rem">
                    <template slot="empty">
                        <p>
                            骨贝小助手提醒您：当前组织暂无数据，请您在
                            <span
                                class="link"
                                @click="handleRouter('/finance/accounting/budget-setting')"
                            >收支设置</span>里配置对应计算方式，若已配置可通过
                            <span
                                class="link"
                                @click="querydata"
                            >刷新</span>数据更新查看
                        </p>
                    </template>
                    <el-table-column
                        type="index"
                        min-width="50"
                        label=""
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        :label="currentGroup.groupName ? currentGroup.groupName : ''"
                        min-width="100"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span v-if="!scope.row.isLeaf">
                                {{ scope.row.name }}
                            </span>
                            <el-link v-else type="primary" @click="changeGroup(scope.row)">
                                {{ scope.row.name }}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :label="column.label"
                        :header-align="column.hasChild ? 'center' : 'right'"
                    >
                        <template slot-scope="scope">
                            <div v-if="column.hasChild" class="column-with-child">
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row, 'value', column.label),
                                    }"
                                >
                                    {{ filterValue(scope.row, column.label, "value") }}
                                </span>
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row, 'rate', column.label)
                                            || isLessZero(scope.row, 'value', column.label),
                                    }"
                                >
                                    {{ filterValue(scope.row, column.label, "rate") }}
                                </span>
                            </div>
                            <div v-else class="column-number">
                                {{ filterValue(scope.row, column.label) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100"
                        align="center"
                        fixed="right"
                    >
                        <template slot-scope="scope">
                            <div class="operater">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="seeDetail(scope.row)"
                                >
                                    查看详情
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <upload-dialog ref="uploadDialog" />
        </div>
    </div>
</template>

<script>
import UploadDialog from '../uploadDialog'
import department from '@/components/Department'
import { moneyFilter } from '@/utils/index'
import oaSvc from 'services/oa'
import accountSvc from 'services/accounting'
import breadSlot from './components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot' // 插入自定义面包屑
import { getPrevWeek, getDiff, getCurrentMonthOrWeek } from '@/utils/date'
import { mapMutations } from 'vuex'

const breadSlotMixin = new BreadSlotMixin({ breadSlot })
export default {
    name: 'AccountingDaily',
    components: { UploadDialog, department },
    mixins: [breadSlotMixin],
    data () {
        return {
            pickerOptions: {
                // 限制预约时间
                disabledDate (time) {
                    return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
                },
                firstDayOfWeek: 1
            },
            searchForm: {
                tenantId: '',
                groupId: '',
                startDate: '',
                endDate: ''
            },
            tableData: [],
            radio: '2',
            dateRange: ['', ''],
            currentGroup: {
                groupName: ''
            },
            currentUser: {},
            allDepts: [],
            searchKey: '',
            tableColumns: [],

            pageSign: '日常报表',
            depts: [],
            dateGroup: [
                {
                    title: '年',
                    type: 'year',
                    value: null,
                    defalut: null,
                    visible: false
                },
                {
                    title: '月',
                    type: 'month',
                    value: null,
                    default: null,
                    visible: false
                },
                {
                    title: '周',
                    type: 'week',
                    value: null,
                    default: null,
                    visible: false
                }
            ], // 日期
            checkedName: 'week',
            dateTypeRadioObj: {}
        }
    },
    created () {
        this.dateRange = [
            getPrevWeek('start'),
            getPrevWeek('end')
        ]
        this.pageSign = this.$route.meta.title || '日常报表'
    },
    activated () {
        this.$nextTick(() => {
            this.$refs.table.doLayout()
        })
        this.addSlot(breadSlot)
    },
    mounted () {
        this.setDefaultDept()
    },
    methods: {
        ...mapMutations('transmit', {
            addSlot: 'UPDATE_BREAD_SLOT'
        }),
        // 选择年、月、周
        selectDateType (item) {
            this.$refs[`date-${item.type}`][0].showPicker()
            this.dateTypeRadioObj = item
            this.checkedName = this.dateTypeRadioObj.type
        },
        // 年、月、周时间组件值变化事件
        handleDateTypeChange (type) {
            // console.log(type, '====type')
            this.dateGroup.forEach(item => {
                if (item.type !== type) {
                    item.value = null
                }
            })
            this.setDateRange(this.dateTypeRadioObj)
        },
        // 根据年、月、周赋值时间区间
        setDateRange (item) {
            const { type, value } = item
            // this.dateRange = this.quickSearch.filter(
            //     (item) => Number(item.value) === Number(radio)
            // )[0].date
            this.dateRange = [
                getCurrentMonthOrWeek('start', value, type),
                getCurrentMonthOrWeek('end', value, type)
            ]
            this.querydata()
            // console.log(this.dateRange)
        },
        // 组织变化
        deptChange (item) {
            if (item) {
                this.currentGroup = item
                this.initData(this.currentGroup.leaf)
            }
        },
        // 默认组织
        async setDefaultDept () {
            const res = await oaSvc.getOwnDepts() // 获取当前用户所在组织及其子组织
            const res2 = await oaSvc.getEmployeeInfo() // 获取员工信息
            this.depts = res.data
            if (res.data && res.data.length > 0) {
                this.allDepts = res.data
                this.currentGroup = res.data.filter(
                    (item) => item.id === res2.data.employeeGroupList[0].groupId
                )[0]
                this.searchForm.groupId = this.currentGroup.id
                this.$refs.dept.cascaderValue = this.currentGroup.id
                this.initData(this.currentGroup.leaf)
            }
        },
        // 查询日期变化
        dateChange (val) {
            // console.log(val)
            this.querydata()
        },
        // 获取表格数据
        async initData (leaf) {
            this.tableData = []
            let apiName = ''
            if (this.pageSign === '日常报表') {
                apiName = leaf
                    ? 'getDailyReportProductList'
                    : 'getDailyReportNotLeafList'
            }
            if (this.pageSign === '实际报表') {
                apiName = leaf ? 'getActualListProduct' : 'getActualListNotLeaf'
            }
            // console.log(apiName, '=====apiName')
            const dateRange = this.getRealDateRange(this.dateRange)
            const param = {
                groupId: this.currentGroup.id,
                startDate: this.$moment(dateRange[0]).format('YYYY-MM-DD'),
                endDate: this.$moment(dateRange[1]).format('YYYY-MM-DD')
            }
            try {
                const { data = [] } = await accountSvc[apiName](param)
                if (this.searchKey && data.length > 0) {
                    this.tableData = data.filter((item) =>
                        item.name.includes(this.searchKey.replace(/\s+/g, ''))
                    )
                } else {
                    this.tableData = data
                    this.tableColumns = this.tableData[0].dmpTierList.map((item) => {
                        let obj = {}
                        obj.label = item.accountingItemTitle
                        obj.hasChild = item.hasOwnProperty('rate')
                        return obj
                    })
                }
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            } catch (error) {
                console.log(error, '====error')
            }
        },
        // 处理表格数据--金额/比例
        filterValue (row, propName, field = 'value') {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let value = ''
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    if (propName !== '销量') {
                        value =
              field === 'value'
                  ? '￥' + moneyFilter(res[0][field])
                  : Number(res[0][field]) ? res[0][field].toFixed(2) + '%' : '-'
                    } else {
                        value = Math.round(res[0].value)
                    }
                }
                return value
            }
        },
        // 处理表格数据--比例
        filterRate (row, propName) {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let value = ''
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    value = Number(res[0].rate).toFixed(2) + '%'
                }
                return value
            }
        },
        // 判断数据是否小于0
        isLessZero (row, prop, propName) {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let flag = false
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    flag = Number(res[0][prop]) < 0
                }
                return flag
            }
        },
        // 数据补录按钮事件
        addData () {
            this.$refs.uploadDialog.dialogVisible = true
            this.$refs.uploadDialog.show = true
        },
        // 查询按钮
        querydata () {
            this.initData(this.currentGroup.leaf)
        },
        // 查看详情
        seeDetail (row) {
            this.dateRange = [
                this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
                this.$moment(this.dateRange[1]).format('YYYY-MM-DD')
            ]
            let res = []
            if (row.name !== '店铺合计') {
                res = this.allDepts.filter((item) => Number(item.id) === Number(row.id))
            } else {
                res.push(this.currentGroup)
            }
            this.$router.push({
                path: 'detail',
                query: {
                    id: row.id,
                    checkedName: this.checkedName,
                    groupId: res.length > 0 ? res[0].id : this.currentGroup.id,
                    groupName:
            res.length > 0 ? res[0].groupName : this.currentGroup.groupName,
                    tenantId: row.tenantId,
                    name:
            row.name === '店铺合计' ? this.currentGroup.groupName : row.name,
                    isLeaf: res.length > 0 ? (res[0].leaf ? 1 : 0) : 2,
                    dateRange: JSON.stringify(this.dateRange)
                }
            })
        },
        // 刷新数据
        async handleRefesh () {
            const dateRange = this.getRealDateRange(this.dateRange)
            const param = {
                // tenantId: this.currentGroup.tenantId,
                groupId: this.currentGroup.id,
                startDate: this.$moment(dateRange[0]).format('YYYY-MM-DD'),
                endDate: this.$moment(dateRange[1]).format('YYYY-MM-DD')
            }
            let apiName =
        this.pageSign === '日常报表'
            ? 'dailyReportRefresh'
            : 'actualReportRefresh'
            try {
                this.$message.success('操作成功，数据更新后将通过钉钉通知，请稍候查看！')
                await accountSvc[apiName](param)
                // this.initData(this.currentGroup.leaf)
            } catch (error) {
                console.log(error, '=====error')
                this.$message.warning('操作失败！')
            }
        },
        // 点击叶子节点 切换组织查询
        changeGroup (data) {
            this.currentGroup = this.depts.filter((item) => item.id == data.id)[0]
            this.searchForm.groupId = this.currentGroup.id
            this.$refs.dept.cascaderValue = this.currentGroup.id
            this.initData(this.currentGroup.leaf)
        },
        // 判断开始时间、结束时间是否超过昨天
        // 如果开始时间超过昨天----》 开始和结束时间都取昨天值
        // 如果结束时间超过昨天 ---》结束时间取昨天值
        getRealDateRange (dateRange) {
            let res = null
            const yester = this.$moment().subtract('days', 1).format('YYYY-MM-DD')
            const start = this.$moment(dateRange[0]).format('YYYY-MM-DD')
            const end = this.$moment(dateRange[1]).format('YYYY-MM-DD')
            if (getDiff(start, yester) > 0) {
                res = [yester, yester]
            } else if (getDiff(end, yester) > 0) {
                res = [start, yester]
            } else {
                res = [start, end]
            }
            return res
        },
        handleRouter (path) {
            this.$router.push(path)
        }
    }
}
</script>
<style lang="less" scoped>
.custom-radio-item {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 48px;
    height: 40px;
    color: #333;
    text-align: center;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    // background-color: #3595e1;
    .el-date-editor {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &:hover {
        color: #3595e1;
        border: 1px solid #3595e1;
    }
}

.radio-checked {
    color: #fff;
    background-color: #3595e1;
    border: 1px solid #3595e1;

    &:hover {
        color: #fff;
    }
}

.custom-radio-item + .custom-radio-item {
    margin-left: 10px;
}

/deep/ .el-table {
    .el-table__header-wrapper {
        .el-table__header {
            thead tr th {
                .cell {
                    padding-left: 100px;
                }
            }

            thead tr th.is-leaf {
                .cell {
                    padding-left: 12px;
                }
            }
        }
    }

    .el-table__empty-text {
        width: 100%;
        line-height: 1.5;

        .link {
            color: #409eff;
            cursor: pointer;
        }

        .link:hover {
            text-decoration: underline;
        }
    }

    .column-number {
        text-align: right;
    }

    .column-with-child {
        display: flex;

        > span {
            flex: 1;
        }

        > span:nth-child(1) {
            text-align: right;
        }

        > span:nth-child(2) {
            text-align: left;
        }

        > span + span {
            margin-left: 15px;
        }
    }
}
</style>
