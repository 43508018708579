<template>
    <div class="fill__report">
        <div class="fill__report--header page-search">
            <el-descriptions title="" :column="5">
                <el-descriptions-item label="编码">
                    {{
                        currentInfo.planNo
                    }}
                </el-descriptions-item>
                <el-descriptions-item label="组织">
                    {{
                        currentInfo.groupName
                    }}
                </el-descriptions-item>
                <el-descriptions-item label="规划时间">
                    {{
                        currentInfo.dataYearMonth
                    }}
                </el-descriptions-item>
                <el-descriptions-item label="状态">
                    {{
                        currentInfo.status | dictionaryFilter(vueInstance, '管会规划状态')
                    }}
                </el-descriptions-item>
                <el-descriptions-item label="提交人">
                    {{ currentInfo.submitter }}
                </el-descriptions-item>
            </el-descriptions>
            <div class="search">
                <el-input
                    v-model="goodName"
                    placeholder="搜索货品名称"
                    @keyup.enter.native="handleSearch"
                ></el-input>
                <el-button type="primary" @click="handleSearch">查询</el-button>
            </div>
        </div>
        <div class="page-content">
            <div class="page-handle-btn">
                <span class="tip"> 展示比例为基于去退款后GMV的费率 </span>
            </div>
            <div class="gb-table">
                <el-table ref="table" :data="tableData" height="5rem">
                    <el-table-column
                        prop="name"
                        :label="currentInfo.groupName"
                        min-width="100"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span>
                                {{ scope.row.name }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(column, index) in tableColumns"
                        :key="index"
                        :label="column.label"
                        :header-align="column.hasChild ? 'center' : 'right'"
                    >
                        <template slot-scope="scope">
                            <div
                                v-if="column.hasChild"
                                class="column-with-child"
                            >
                                <span
                                    :class="{
                                        lessZero: isLessZero(
                                            scope.row,
                                            'value',
                                            column.label
                                        ),
                                    }"
                                >
                                    {{
                                        filterValue(
                                            scope.row,
                                            column.label,
                                            'value'
                                        )
                                    }}
                                </span>
                                <span
                                    :class="{
                                        lessZero:
                                            isLessZero(
                                                scope.row,
                                                'rate',
                                                column.label
                                            ) ||
                                            isLessZero(
                                                scope.row,
                                                'value',
                                                column.label
                                            ),
                                    }"
                                >
                                    {{
                                        filterValue(
                                            scope.row,
                                            column.label,
                                            'rate'
                                        )
                                    }}
                                </span>
                            </div>
                            <div v-else class="column-number">
                                {{ filterValue(scope.row, column.label) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <div class="operater">
                                <el-button
                                    type="text"
                                    size="small"
                                    @click="seeDetail(scope.row)"
                                >
                                    查看详情
                                </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import accountSvc from 'services/accounting'
import { moneyFilter } from '@/utils/index'
import gbDictionary from '@/components/dictionary'
export default {
    name: 'PlanningFillReport',
    components: {},
    filters: {
        dictionaryFilter: gbDictionary.filter
    },
    data () {
        return {
            vueInstance: this,
            goodName: null,
            tableData: [],
            allData: [],
            tableColumns: [],
            currentInfo: {}
        }
    },
    // computed: {
    //     currentInfo () {
    //         return this.$route.query.fillData
    //             ? JSON.parse(this.$route.query.fillData)
    //             : ''
    //     }
    // },
    activated () {
        const query = JSON.parse(this.$route.query.fillData) || {}
        const id = query.id
        console.log(id, '=====', this.currentInfo.id)
        if (id !== this.currentInfo.id) {
            this.currentInfo = query
            this.goodName = null
            this.initData()
        }
    },
    created () {
        this.currentInfo = JSON.parse(this.$route.query.fillData) || {}
        // console.log(this.currentInfo)
        gbDictionary.init(this, ['管会规划状态'])
        this.initData()
        this.$nextTick(() => {
            this.$refs.table.doLayout()
        })
    },
    methods: {
        handleSearch () {
            if (this.goodName) {
                this.tableData = this.allData.filter((item) =>
                    item.name.includes(this.goodName)
                )
            } else {
                this.tableData = this.allData
            }
        },
        async initData () {
            const { data = [] } = await accountSvc.fillReportProductList({
                planId: this.currentInfo.id
            })
            this.allData = data
            this.tableData = data
            this.tableColumns = this.tableData[0].dmpTierList.map((item) => {
                let obj = {}
                obj.label = item.accountingItemTitle
                obj.hasChild = item.hasOwnProperty('rate')
                return obj
            })
        },
        seeDetail (row) {
            this.$router.push({
                path: 'planning-report/detail',
                query: {
                    planId: this.currentInfo.id,
                    spuId: row.id,
                    groupName: row.name === '店铺合计' ? this.currentInfo.groupName : row.name,
                    type: row.name === '店铺合计' ? 1 : 2
                }
            })
        },
        // 处理表格数据--比例
        filterRate (row, propName) {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let value = ''
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    value = Number(res[0].rate).toFixed(2) + '%'
                }
                return value
            }
        },
        // 处理表格数据--金额/比例
        filterValue (row, propName, field = 'value') {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let value = ''
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    if (propName !== '销量') {
                        value =
                            field === 'value'
                                ? '￥' + moneyFilter(res[0][field])
                                : Number(res[0][field])
                                    ? res[0][field].toFixed(2) + '%'
                                    : '-'
                    } else {
                        value = Math.round(res[0].value)
                    }
                }
                return value
            }
        },
        // 判断数据是否小于0
        isLessZero (row, prop, propName) {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let flag = false
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    flag = Number(res[0][prop]) < 0
                }
                return flag
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.fill__report {
    &--header {
        display: flex;
        align-items: center;

        .search {
            display: flex;
            min-width: 320px;

            .el-button {
                margin-left: 20px;
            }
        }
    }

    .page-content {
        margin-top: 15px;
    }

    .page-handle-btn {
        height: 44px;
        line-height: 44px;
    }

    ::v-deep .el-table {
        .column-number {
            text-align: right;
        }

        .column-with-child {
            display: flex;

            > span {
                flex: 1;
            }

            > span:nth-child(1) {
                text-align: right;
            }

            > span:nth-child(2) {
                text-align: left;
            }

            > span + span {
                margin-left: 15px;
            }
        }
    }
}
</style>
