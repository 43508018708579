<template>
    <div class="and-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            @change="handleChange"
        >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'AndSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Boolean,
            default: null
        }
    },
    data () {
        return {
            maxand: 31,
            selected: ''
        }
    },
    computed: {
        options () {
            return [
                { value: true, label: '且' },
                { value: false, label: '否' }
            ]
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.and-select {
    display: inline-block;
    width: 60px;
}
</style>
