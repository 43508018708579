<template>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
        <div slot="title" class="header-title">
            <span
                class="title-name"
                style="color: #606266;font-weight: 500;font-size: .18rem;"
                >数据上传</span
            >
            <span>
                <!-- <el-tooltip class="item" effect="dark" content="提示文字" placement="top">
          <i class="iconfont icon-shuoming" style="color:#69ACF1;margin-left:.05rem;cursor:pointer"></i>
        </el-tooltip> -->
            </span>
        </div>
        <el-upload
            class="upload-demo"
            ref="upload"
            :on-remove="handleRemove"
            :file-list="fileList"
            drag
            :auto-upload="false"
            :action="importUrl"
            :headers="{ authToken: sessionToken }"
            :on-change="uploadChange"
        >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" style="line-height:1.5" slot="tip">
                说明：
                <br />
                * 导入数据超过1000行时数据导入相对较慢，请耐心等待或拆分导入
                <br />
                *
                导入后若报错将自动下载错误原因表，可查看修改后直接用错误原因表导入，模板相同
                <br />
                * 当前导入仅可新增，不可更新原数据，请勿重复导入相同数据
            </div>
        </el-upload>
        <div slot="footer" class="dialog-footer">
            <div class="left">
                <span @click="downloadTemplate">下载模板</span>
                <span
                    v-show="show"
                    @click="
                        ;(dialogVisible = false),
                            $router.push('/finance/accounting/actual/list')
                    "
                    >查看已导入数据</span
                >
            </div>
            <div class="right">
                <el-button
                    :loading="loading"
                    type="primary"
                    @click="handeleConfirm"
                    >确 定</el-button
                >
                <el-button @click=";(fileList = []), (dialogVisible = false)"
                    >取 消</el-button
                >
            </div>
        </div>
    </el-dialog>
</template>

<script>
import settings from '@/settings'
import axios from 'axios'
import { downloadExcel } from '@/utils'
import qs from 'qs'
export default {
    name: '',
    components: {},
    data() {
        return {
            dialogVisible: false,
            show: false,
            fileList: [],
            sessionToken: '',
            importUrl: '',
            currentFile: null,
            loading: false,
            fillId: ''
        }
    },
    created() {
        let currentUser = this.$store.getters['currentUser']
        if (currentUser) {
            this.sessionToken = currentUser.id
        }
        this.importUrl =
            settings.servers.accountingServer +
            '/accounting/budgetactual/import-data'
    },
    methods: {
        // 点击下载模板按钮
        downloadTemplate() {
            axios({
                method: 'post',
                url:
                    settings.servers.accountingServer +
                    '/accounting/accounting-fill-item-export-template', // 请求地址
                // '/accounting/budgetactual/export-template', // 请求地址

                data: qs.stringify({ fillId: this.fillId }),
                responseType: 'blob', // 表明返回服务器返回的数据类型
                headers: {
                    authToken: this.sessionToken || '',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(response => {
                downloadExcel(response.data, '预算填报模板.xlsx')
            })
        },
        uploadChange(file) {
            this.fileList = []
            let fileName = file.name
            let fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
            if (!['xlsx', 'xls'].includes(fileType)) {
                this.$message.error('请上传正确的文件格式！')
                return false
            }
            if (this.size && file.size / 1024 / 1024 > 2) {
                this.$message.error(`上传图片大小不能超过2MB！`)
                return false
            }
            this.currentFile = file
            this.fileList.push(file)
            file.status = 'success'
        },
        // beforeUpload(file) {
        //   // this.fileTemp = file.raw
        //   console.log(file)
        //   let fileName = file.name
        //   let fileType = fileName.substring(fileName.lastIndexOf('.') + 1)
        //   if (!['xlsx', 'xls'].includes(fileType)) {
        //     this.$message.error('请上传正确的格式')
        //     return false
        //   }
        //   if (this.size && file.size / 1024 / 1024 > 2) {
        //     this.$message.error(`上传图片大小不能超过2MB!`)
        //     return false
        //   }
        //   return new Promise((resolve, reject) => {
        //     axios({
        //       method: 'post',
        //       url: settings.servers.accountingServer + '/accounting/budgetactual/import-data', // 请求地址
        //       data: { file },
        //       headers: {
        //         authToken: this.sessionToken || '',
        //         'Content-Type': 'multipart/form-data',
        //       },
        //     }).then((res) => {
        //       console.log(res)
        //       resolve(res)
        //     })
        //   })
        // },
        // 删除文件触发的事件
        handleRemove(file, fileList) {
            console.log(file, fileList)
            this.fileList.splice(this.fileList.indexOf(file), 1)
        },
        // 点击确认按钮
        handeleConfirm() {
            this.loading = true
            if (this.fileList.length > 0) {
                const formData = new FormData()
                formData.append('file', this.currentFile.raw)
                formData.append('fillId', this.fillId)
                axios({
                    method: 'post',
                    url:
                        settings.servers.accountingServer +
                        // '/accounting/budgetactual/import-data', // 请求地址
                        '/accounting/accounting-fill-item-import-data', // 请求地址
                    data: formData,
                    responseType: 'arraybuffer',
                    headers: {
                        authToken: this.sessionToken || ''
                        // 'Content-Type':
                        //   'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq',
                    }
                }).then(res => {
                    console.log(res)
                    this.loading = false
                    try {
                        let enc = new TextDecoder('utf-8')
                        let data = JSON.parse(
                            enc.decode(new Uint8Array(res.data))
                        )
                        this.$message.success(
                            `上传成功！`
                        )
                        this.$emit('initData')
                        this.dialogVisible = false
                        this.fileList = []
                    } catch {
                        if (!res.data.byteLength) {
                            this.$message.warning(
                                '导入信息不符合模板规范，请重新上传！'
                            )
                        } else {
                            this.$message.warning('导入信息有误，请重新上传！')
                            downloadExcel(res.data, '错误信息.xlsx')
                        }
                        this.fileList = []
                    }
                })
            } else {
                this.$message.warning('请上传文件！')
            }
        },
        buf2hex(buffer) {
            // buffer is an ArrayBuffer
            return Array.prototype.map
                .call(new Uint8Array(buffer), x =>
                    ('00' + x.toString(16)).slice(-2)
                )
                .join('')
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    border-radius: 0.2rem;
    width: 6rem;
    .el-upload {
        .el-upload-dragger {
            width: 5rem;
            height: 1.6rem;
            .el-icon-upload {
                margin: 0;
                margin-top: 0.4rem;
            }
        }
    }
    .el-dialog__body {
        .upload-demo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .el-upload {
            width: 100%;
            .el-upload-dragger {
                width: 100%;
                height: 1.8rem;
            }
        }
        .el-upload__tip {
            width: 100%;
        }
    }
    .el-dialog__footer {
        .dialog-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
                span {
                    font-size: 0.14rem;
                    color: #3595e1;
                    cursor: pointer;
                }
                span + span {
                    margin-left: 0.3rem;
                }
            }
            .right {
                .el-button {
                    width: 0.64rem;
                    height: 0.4rem;
                    // background: #3595e1;
                    border-radius: 0.08rem;
                    padding: 0;
                    font-size: 0.14rem;
                }
            }
        }
    }
}
</style>
