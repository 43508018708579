<template>
    <div class="planning-fill">
        <query
            class="query"
            :query="query"
            @handle-search="handleCallSearch"
            @handle-reset="handleReset"
        ></query>
        <operations @handle-rules="handleRefresh"></operations>
        <list v-loading="listLoading" :list="list" @handle-search="handleSearch"></list>
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="query.current"
            :page-sizes="[50, 100, 200]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import components from './components/index'
import oaSvc from 'services/oa'
import accountSvc from 'services/accounting'

export default {
    name: 'PlanningFill',
    components: {
        ...components
    },
    beforeRouteLeave (from, to, next) {
        this.setAllChildDeps(null)
        next()
    },
    data () {
        return {
            query: {
                current: 1,
                size: 10,
                groupId: null,
                statuss: null, // 状态合集
                submitter: null // 提交人模糊查询
                // approvalCode: null // 关联审批单编号模糊查询
            },
            listLoading: false,
            list: [],
            total: 0

        }
    },
    created () {
        this.getAllData()
    },
    activated () {
        this.getAllData()
    },
    methods: {
        handleSizeChange (size) {
            this.query.size = size
            this.handleSearch()
        },
        handleCurrentChange (current) {
            this.query.current = current
            this.handleSearch()
        },
        handleRefresh () {
            this.handleReset({
                current: 1,
                size: 10,
                groupId: null,
                statuss: null, // 状态合集
                submitter: null, // 提交人模糊查询
                approvalCode: null // 关联审批单编号模糊查询
            })
        },
        handleReset (query) {
            // 重置
            this.query = _.cloneDeep(query)
            this.handleSearch()
        },
        handleCallSearch (form) {
            this.query = { ...form }
            this.query.current = 1
            this.handleSearch(this.query)
        },
        async handleSearch () {
            this.listLoading = true
            const query = _.cloneDeep(this.query)
            const { data, total } = await accountSvc.getAccountingPlans(query)
            this.list = data
            this.total = total
            console.log(data)
            this.listLoading = false
        },
        handleRules () {},
        // async getAllDepts () {
        //     // 获取管理部门
        //     const { data = [] } = await oaSvc.getAllDepts()
        //     this.setAllDepts(data)
        // },
        async getOwnLeafDepts () {
            const { data = [] } = await oaSvc.getOwnDepts()
            this.depts = data.filter(item => item.leaf)
            this.setAllChildDeps(data)
        },
        async getAllData () {
            try {
                // this.getAllDepts()
                this.getOwnLeafDepts()
                await this.handleSearch()
            } catch (e) {
                console.log(e)
            } finally {
            }
        },
        ...mapMutations('customer', {
            setAllChildDeps: 'UPDATE_ALL_CHILD_DEPS'
        })
    }
}
</script>
<style lang="scss" scoped>
.custom-page {
    margin: 0.2rem 0 0.1rem;
    text-align: right;
}
</style>
