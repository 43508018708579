<template>
    <el-dialog
        class="set-dialog"
        title="设置收支"
        :visible.sync="visible"
        :lock-scroll="false"
        width="1200px"
        :close-on-click-modal="false"
    >
        <el-form :model="searchForm" inline>
            <el-form-item>
                <el-input
                    v-model="searchForm.titleLike"
                    placeholder="收支项目"
                    @input="queryData"
                ></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select
                    placeholder="请选择属性"
                    v-model="searchForm.resultSign"
                    clearable
                    @change="queryData"
                >
                    <el-option
                        v-for="item in peopertyDicts"
                        :key="item.value"
                        :value="item.itemValue"
                        :label="item.title"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-select
                    placeholder="分类"
                    v-model="searchForm.itemCategory"
                    clearable
                    @change="queryData"
                >
                    <el-option
                        v-for="item in cateDicts"
                        :key="item.value"
                        :value="item.itemValue"
                        :label="item.title"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-input
                    v-model="searchForm.descriptionLike"
                    placeholder="描述"
                    @input="queryData"
                ></el-input>
            </el-form-item>
            <el-button type="primary">查询</el-button>
        </el-form>
        <el-table
            :data="tableData"
            style="width: 100%;"
            size="medium"
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
            :tree-props="{ children: 'children' }"
            :header-cell-style="getRowClass"
            row-key="id"
            @select="rowSelect"
            @select-all="selectAll"
            v-loading="loading"
        >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
                v-for="(column, index) in columns"
                :key="index"
                :prop="column.prop"
                :label="column.label"
                :min-width="column.minWidth || 100"
                :formatter="column.formatter"
                :align="column.align ? column.align : 'center'"
                :show-overflow-tooltip="column.showTip"
            >
            </el-table-column
        ></el-table>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountingSvc from 'services/accounting'
import { makeDataToTree } from '@/utils'
import tableMethods from 'utils/table'
export default {
    name: 'SetDialog',
    components: {},
    props: {
        cateDicts: {
            type: Array,
            default: null
        },
        peopertyDicts: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            searchForm: {
                filterAaccountingItemIds: [],
                titleLike: '',
                resultSign: '',
                itemCategory: '',
                descriptionLike: ''
            },
            visible: false,
            tableData: [],
            columns: [
                {
                    prop: 'levelPathName',
                    label: '收支项目',
                    minWidth: '100',
                    align: 'left',
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'resultSign',
                    label: '属性',
                    minWidth: '100',
                    hidden: false,
                    formatter: this.peopertyDictsFilter,
                    showTip: true
                },
                {
                    prop: 'itemCategory',
                    label: '分类',
                    hidden: false,
                    minWidth: '100',
                    formatter: this.cateDictsFilter,
                    showTip: true
                },
                {
                    prop: 'itemPriority',
                    label: '优先级',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'taxRate',
                    label: '税率',
                    hidden: false,
                    minWidth: '150',
                    formatter: this.percent,
                    showTip: true
                },
                {
                    prop: 'description',
                    label: '描述',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                }
            ],
            selected: [],
            groupId: '',
            categoryOptions: [
                { value: 21, label: '销售收入' },
                { value: 22, label: '计入毛利' },
                { value: 23, label: '计入产品边际贡献' },
                { value: 24, label: '计入组织编辑贡献' },
                { value: 25, label: '计入剩余盈余' },
                { value: 26, label: '仅展示' }
            ],
            loading: false
        }
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.searchForm = {}
                this.getTableData()
            } else {
                this.tableData = []
            }
        }
    },
    methods: {
        ...tableMethods,
        async getTableData() {
            this.loading = true
            this.searchForm.enabled = true
            const { data = [] } = await accountingSvc.getAccoutingProjects(
                this.searchForm
            )
            // this.tableData = makeDataToTree(data, 'parentId', 'id')
            this.tableData = data.map(item => {
                item.levelPathName = item.levelPathName.replace(/\|/g, '>')
                return item
            })
            this.loading = false
        },
        /*注意在获取初始数据时，所有节点（包括子节点）都增加一个isChecked 标志参数*/
        rowSelect(selection, row) {
            if (row.children) {
                //只对有子节点的行响应
                if (!row.isChecked) {
                    //由行数据中的元素isChecked判断当前是否被选中
                    row.children.map(item => {
                        //遍历所有子节点
                        this.$refs.multipleTable.toggleRowSelection(item, true) //切换该子节点选中状态
                        /*
                方法名                    说明                                      参数
                                     用于多选表格，切换某一行的选中状态，         row, selected
                toggleRowSelection   如果使用了第二个参数，则是设置这一行
                                     选中与否（selected 为 true 则选中）
                 */
                        item.isChecked = true
                    })
                    row.isChecked = true //当前行isChecked标志元素切换为false
                } else {
                    row.children.map(item => {
                        this.$refs.multipleTable.toggleRowSelection(item, false)
                        item.isChecked = false
                    })
                    row.isChecked = false
                }
                // console.log(this.multipleSelection, row);
            }
        },
        selectAll(selection) {
            // selection 是选中的数据集合
            this.$refs.multipleTable.data.map(items => {
                //使用$ref获取注册的子组件信息，用data获取所有行，并用map函数遍历行
                if (items.children) {
                    if (!items.isChecked) {
                        //若遍历出来的行未选中
                        this.$refs.multipleTable.toggleRowSelection(items, true) //行变为选中状态
                        items.isChecked = true //更新标志参数
                        items.children.map(item => {
                            //遍历子节点并改变状态与标志参数
                            this.$refs.multipleTable.toggleRowSelection(
                                item,
                                true
                            )
                            item.isChecked = true
                        })
                    } else {
                        //选中状态同理
                        this.$refs.multipleTable.toggleRowSelection(
                            items,
                            false
                        )
                        items.isChecked = false
                        items.children.map(item => {
                            this.$refs.multipleTable.toggleRowSelection(
                                item,
                                false
                            )
                            item.isChecked = false
                        })
                    }
                } else {
                    if (!items.isChecked) items.isChecked = true
                    else items.isChecked = false
                }
            })
            // console.log(this.orgs)
        },
        handleSelectionChange(val) {
            this.selected = val
        },
        handleCancel() {
            this.visible = false
        },
        async handleConfirm() {
            console.log(this.selected)
            const param = {
                groupId: this.groupId,
                accountingItemList: this.selected
            }
            await accountingSvc.addGroupBudgetList(param)
            this.$message.success('添加成功')
            this.visible = false
            this.$emit('handle-refresh')
        },
        getRowClass({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color: #f5f7fa;'
            } else {
                return ''
            }
        },
        queryData() {
            this.getTableData()
        },
        cateDictsFilter(row, column, value, index) {
            const res = this.cateDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        peopertyDictsFilter(row, column, value, index) {
            const res = this.peopertyDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        }
    }
}
</script>
<style lang="less" scoped>
@import '../../../../../styles/variables.less';

.set-dialog {
    /deep/ .el-dialog {
        max-height: 740px;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        .el-form {
            .el-input,
            .el-input-number {
                width: 240px;
                .el-input__inner {
                    border-radius: 2px;
                }
            }
        }
        .el-dialog__body {
            flex: 1;
            overflow-y: auto;
        }
        .el-dialog__footer {
            height: 60px;
            padding: 10px;
            line-height: 40px;
            border-top: 2px solid #f0f0f0;
        }
    }
    /deep/ .el-table {
        .el-table__body tr.el-table__row:hover td {
            background-color: @tableRowHoverBgc !important;
        }
        .cell {
            position: relative;
            padding-left: 25px;
            .el-table__indent {
                padding-left: 0 !important;
            }
            .el-table__expand-icon {
                position: absolute;
                left: 0;
            }
        }
        .el-table__row.el-table__row--level-1 {
            background-color: #f7f9fc;
        }
    }
}
</style>
