<template>
    <div class="day-group-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            multiple
            collapse-tags
            @change="handleChange"
        >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'DayGroupSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            selected: ''
        }
    },
    computed: {
        options () {
            const obj = {
                1: '1日',
                2: '7日',
                3: '15日',
                4: '30日',
                5: '60日',
                6: '90日',
                7: '180日',
                8: '365日'
            }
            return Object.keys(obj).map((item, index) => {
                return {
                    label: obj[item],
                    value: index + 1
                }
            })
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.day-group-select {
    display: inline-block;
    width: 150px;
}
</style>
