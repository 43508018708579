<template>
    <i class="iconfont icon-daoru data-export" @click="handleExport">{{ viewName }}</i>
</template>
<script>
import { exportData } from 'utils/request'

export default {
    name: 'DataExport',
    props: {
        name: {
            type: String,
            default: ''
        },
        serverName: {
            type: String,
            default: ''
        },
        params: {
            type: Object,
            default: () => {}
        },
        path: {
            type: String,
            default: ''
        }
    },
    computed: {
        viewName () {
            return this.name || '数据导出'
        }
    },
    methods: {
        handleExport () {
            exportData({
                name: this.viewName,
                serverName: this.serverName,
                path: this.path,
                params: this.params
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.data-export {
    font-size: 0.14rem;
    color: #3595e1;
    cursor: pointer;

    &::before {
        margin-right: 0.1rem;
    }
}
</style>
