<template>
    <div class="page-content">
        <div class="page-handle-btn">
            <span @click="handleAdd">
                <i class="iconfont icon-benzhouxinzengfenxiaoshang">新增</i>
            </span>
            <span @click="handleBdgSort">
                <i class="iconfont">调整顺序</i>
            </span>
            <!-- <span @click="handleDel">
                <i class="iconfont icon-shanchu">删除</i>
            </span> -->
            <!-- <span @click="toRubish">
                <i class="iconfont icon-huishouzhan1">回收站</i>
            </span> -->
        </div>
        <div class="gb-table">
            <el-table
                ref="table"
                border
                height="500"
                :data="tableData"
                row-key="id"
                :expand-row-keys="expandRowKeys"
                @selection-change="handleSelectionChange"
            >
                <!-- <el-table-column type="selection" fixed="left" min-width="55">
                </el-table-column> -->
                <el-table-column type="index" align="center" fixed="left">
                    <template slot="header">
                        <i
                            class="el-icon-s-tools"
                            @click="showColumnSortDia"
                        ></i>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(column, index) in activeColumns"
                    :key="index"
                    :prop="column.prop"
                    :label="column.label"
                    :min-width="column.minWidth || 100"
                    :formatter="column.formatter"
                    :align="column.align ? column.align : 'center'"
                    :show-overflow-tooltip="column.showTip"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    :min-width="150"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleDel(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <add-dialog
            ref="addDialog"
            :list-data="listData"
            @handle-search="handleSearch"
        ></add-dialog>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
        <bdgSortDialog ref="bdgSortDialog" :list-data="tableData" @handle-refresh="handleSearch" />
    </div>
</template>

<script>
import addDialog from './addDialog'
import utilsTable from '@/utils/table'
import columnSortDia from './columnSortDialog'
import bdgSortDialog from './budget-projects-sort'
import _ from 'lodash'
import accountingSvc from 'services/accounting'
import { makeDataToTree } from '@/utils'

export default {
    name: 'BudgetProjectsList',
    inject: ['dictsObj'],
    components: { columnSortDia, addDialog, bdgSortDialog },
    // 继承所有父组件的内容
    inheritAttrs: false,
    props: {
        listData: {
            type: Array,
            default: () => []
        },
        expandRowKeys: {
            type: Array,
            default: () => []
        },
        showAll: {
            type: Boolean,
            dafault: false
        }
    },
    data () {
        return {
            vueInstance: this,
            columns: [
                {
                    prop: 'title',
                    label: '收支项目',
                    minWidth: '100',
                    align: 'left',
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'itemNo',
                    label: '编码',
                    minWidth: '100',
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'itemPriority',
                    label: '优先级',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'description',
                    label: '描述',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'parentId',
                    label: '父收支项目',
                    hidden: false,
                    minWidth: '150',
                    formatter: this.parentIdFilter,
                    showTip: true
                },
                {
                    prop: 'itemCategory',
                    formatter: this.cateDictsFilter,
                    label: '分类',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'internalFlag',
                    label: '系统',
                    hidden: false,
                    minWidth: '100',
                    formatter: this.syncStatus,
                    showTip: true
                },
                {
                    prop: 'taxRate',
                    label: '税率',
                    hidden: false,
                    minWidth: '100',
                    formatter: this.percent,
                    showTip: true
                },
                {
                    prop: 'resultSign',
                    label: '属性',
                    hidden: false,
                    minWidth: '100',
                    formatter: this.peopertyDictsFilter,
                    showTip: true
                },
                {
                    prop: 'rateBase',
                    label: '同环比基准',
                    hidden: false,
                    minWidth: '150',
                    formatter: this.huanbijizhunDictsFilter,
                    showTip: true
                },
                {
                    prop: 'chineseFormula',
                    label: '公式',
                    minWidth: '150',
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'budgetType',
                    label: '预算填写方式',
                    minWidth: '150',
                    hidden: false,
                    formatter: this.budgetTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'dailyType',
                    label: '日常管理方式',
                    minWidth: '150',
                    hidden: false,
                    formatter: this.dailyTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'planType',
                    label: '规划方式',
                    minWidth: '100',
                    hidden: false,
                    formatter: this.planTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'dataFrom',
                    label: '实际方式',
                    minWidth: '100',
                    hidden: false,
                    formatter: this.actualTypeDictsFilter,
                    showTip: true
                }
            ],
            multipleSelection: [],
            budgetOptions: []
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        },
        tableData () {
            return makeDataToTree(this.listData, 'parentId', 'id')
        }
    },
    watch: {
        showAll (newValue) {
            this.forArr(this.listData, newValue)
        }
    },
    mounted () {
        this.getAccoutingProjects()
        this.tableReLoayout('table')
    },
    activated () {
        this.tableReLoayout('table')
    },
    methods: {
        ...utilsTable,
        async getAccoutingProjects () {
            const { data = [] } = await accountingSvc.getAccoutingProjects()
            this.budgetOptions = data.filter(item => Number(item.parentId) === 0 && item.enabled)
            this.budgetOptions = this.budgetOptions.map(item => {
                item.id = Number(item.id)
                return item
            })
        },
        handleSearch (isEdit) {
            const param = {
                enabled: true
            }
            this.$emit('handle-search', param, isEdit)
        },
        handleSelectionChange (val) {
            this.multipleSelection = val
        },
        handleAdd () {
            this.$refs.addDialog.isEdit = false
            this.$refs.addDialog.form = {
                title: null,
                parentId: null,
                description: null,
                leafFlag: true,
                itemPriority: 0,
                itemCategory: null,
                taxRate: 0,
                resultSign: null,
                rateBase: null,
                platformId: null,
                tradeType: null,
                budgetType: null,
                dailyType: null,
                dataFrom: null,
                planType: null,
                formula: '',
                beforeDate: 0,
                beforeDateCount: 0,
                beforeDateRate: 0,
                basedItemId: null
            }
            this.$refs.addDialog.visible = true
        },
        // 顺序调整
        handleBdgSort () {
            const _ref = this.$refs.bdgSortDialog
            _ref.visible = true
        },
        handleEdit (row) {
            const _ref = this.$refs.addDialog
            row.formula = row.chineseFormula ? row.chineseFormula : ''
            row.parentId = row.parentId == 0 ? null : row.parentId
            row.basedItemId = row.basedItemId == 0 ? null : row.basedItemId
            _ref.form = _.cloneDeep(row)
            _ref.isEdit = true
            _ref.visible = true
        },
        handleDel (row) {
            this.$confirm('将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    await accountingSvc.delBudgetProject({
                        id: row.id
                    })
                    this.$emit('handle-search')
                    this.getAccoutingProjects()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        // // 进入回收站
        // toRubish() {},
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        // 列表展开和收起
        forArr (arr, isExpand) {
            arr.forEach(i => {
                this.$refs.table.toggleRowExpansion(i, isExpand)
                if (i.children) {
                    this.forArr(i.children, isExpand)
                }
            })
        },
        cateDictsFilter (row, column, value, index) {
            const res = this.dictsObj.cateDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        peopertyDictsFilter (row, column, value, index) {
            const res = this.dictsObj.peopertyDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        huanbijizhunDictsFilter (row, column, value, index) {
            const res = this.dictsObj.huanbijizhunDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        budgetTypeDictsFilter (row, column, value, index) {
            const res = this.dictsObj.budgetTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        planTypeDictsFilter (row, column, value, index) {
            const res = this.dictsObj.planTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        dailyTypeDictsFilter (row, column, value, index) {
            const res = this.dictsObj.dailyTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        actualTypeDictsFilter (row, column, value, index) {
            const res = this.dictsObj.actualTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        parentIdFilter (row, column, value, index) {
            let res = this.dictsObj.budgetOption.find(item => item.id == value)
            return res && res.title ? res.title : ''
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-table {
    .el-icon-s-tools {
        cursor: pointer;
    }
    // .el-table_1_column_2 {
    .cell {
        position: relative;
        padding-left: 25px;

        .el-table__indent {
            padding-left: 0 !important;
        }

        .el-table__expand-icon {
            position: absolute;
            left: 0;
        }
    }
    // }
    .el-table__row.el-table__row--level-1 {
        background-color: #f7f9fc;
    }
}

/deep/ .el-table__fixed-right::before {
    height: 0;
}
</style>
