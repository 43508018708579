<template>
    <div class="day-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            @change="handleChange"
        >
            <el-option
                v-for="item in days"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'DaySelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: null
        },
        isLastDay: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            maxDay: 31,
            selected: ''
        }
    },
    computed: {
        days () {
            const days = Array(this.maxDay).fill(0).map((_, index) => {
                return {
                    label: `${index + 1}`,
                    value: index + 1
                }
            })
            if (this.isLastDay) {
                days.push({
                    label: '最后一天',
                    value: -1
                })
            }
            return days
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.day-select {
    display: inline-block;
    width: 70px;
}
</style>
