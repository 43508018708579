<template>
    <div class="gb-tracking-order-query">
        <search-query
            v-bind="$attrs"
            :is-show-fold="false"
            :is-show-drawer="true"
            :drawer-query-field="drawerQueryField"
            :drawer-query="drawerQuery"
            :more-filters="moreFilters"
            @handle-drawer-reset="handleDrawerReset"
            v-on="$listeners"
        >
            <template v-slot:default="{ query, handleSearch }">
                <!-- <el-input
                    v-model.trim="query.tradeNo"
                    class="w240 mr40"
                    placeholder="订单编号"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"
                ></el-input>
                <el-input
                    v-model.trim="query.onlineTradeNo"
                    class="w240 mr40"
                    placeholder="网店订单号"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch"
                ></el-input> -->
                <el-select
                    v-model="query.channelIds"
                    class="w240 mr40"
                    placeholder="渠道选择"
                    :filterable="true"
                    :multiple="true"
                    clearable
                    collapse-tags
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in channels"
                        :key="item.id"
                        :label="item.channelName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-select
                    v-model="query.skuIds"
                    class="w240 mr40 search-input"
                    filterable
                    remote
                    reserve-keyword
                    :multiple="true"
                    clearable
                    collapse-tags
                    placeholder="输入搜索货品SKU快速增加"
                    :remote-method="
                        skuKeyword => {
                            querySearchSku(skuKeyword, query)
                        }
                    "
                    :loading="skuLoading"
                    @change="handleSearch"
                >
                    <el-option
                        v-for="item in skuList"
                        :key="item.id"
                        class="custom-select-item"
                        :label="item.skuTitle"
                        :value="item.id"
                    >
                        <span>{{ item.skuTitle }}</span>
                        <div>
                            <el-tag v-if="item.skuProperty" size="mini">
                                规格：{{ item.skuProperty }}
                            </el-tag>
                            <el-tag v-if="item.skuBarCode" size="mini">
                                条码：{{ item.skuBarCode }}
                            </el-tag>
                        </div>
                    </el-option>
                </el-select>
                <!-- todo: 单据类型 订单状态 -->
                <gb-dictionary
                    v-model="query.detailTypes"
                    class="w240 mr40"
                    group="交易订单货品明细类型"
                    :clearable="true"
                    :filterable="true"
                    placeholder="单据类型"
                    multiple
                    collapse-tags
                    @change="handleSearch"
                >
                </gb-dictionary>
                <gb-dictionary
                    v-model="query.tradeStatus"
                    class="w240 mr40"
                    group="交易订单状态"
                    :clearable="true"
                    :filterable="true"
                    placeholder="订单状态"
                    multiple
                    collapse-tags
                    @change="handleSearch"
                >
                </gb-dictionary>
            </template>

            <template v-slot:drawer="{ query, drawerQueryView }">
                <el-select
                    v-model="query.searchType"
                    class="wrem350 mrrem40 mbrem10"
                    placeholder="请选择查询条件"
                    :filterable="true"
                >
                    <el-option
                        v-for="item in searchTypes"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-input
                    v-model="query.searchTypeValue"
                    class="mbrem10"
                    type="textarea"
                    :autosize="{ minRows: 6, maxRows: 8 }"
                    placeholder="可输入多个，多个时可以用逗号、空格或换行分开"
                >
                </el-input>
                <h3 class="custom-h mtrem20 mbrem10">管理组织</h3>
                <el-cascader
                    ref="cascaderHandle"
                    v-model="query.groupId"
                    class="wrem350 mbrem10"
                    :options="groupTree"
                    :show-all-levels="false"
                    :props="{
                        checkStrictly: true,
                        value: 'id',
                        label: 'groupName',
                        emitPath: false,
                    }"
                    clearable
                    :filterable="true"
                    placeholder="请选择组织"
                    @change="handleClose($event, drawerQueryView)"
                ></el-cascader>
                <h4 class="custom-h mbrem10">还原单据时间</h4>
                <el-date-picker
                    v-model="query.lastTradeTime"
                    class="wrem350 mbrem10"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :value-format="dateFormat"
                    :default-time="defaultTime"
                >
                </el-date-picker>
                <h4 class="custom-h mbrem10">下单时间</h4>
                <el-date-picker
                    v-model="query.tradeTime"
                    class="wrem350 mbrem10"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :value-format="dateFormat"
                    :default-time="defaultTime"
                >
                </el-date-picker>
                <h4 class="custom-h mbrem10">付款时间</h4>
                <el-date-picker
                    v-model="query.payTime"
                    class="wrem350 mbrem10"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :value-format="dateFormat"
                    :default-time="defaultTime"
                >
                </el-date-picker>
            </template>
        </search-query>
    </div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import { mapState } from 'vuex'
import customerSvc from 'services/customer'
import sellSvc from 'services/sell'
import GbEmployee from 'components/Department'
import gbDictionary from '@/components/dictionary'
import utils from 'utils'

const SearchQuery = () => import('components/search-query')

export default {
    name: 'TrackOrderQuery',
    // inject: ['transfer'],
    components: {
        SearchQuery,
        gbDictionary
    },
    data () {
        return {
            skuLoading: false,
            skuList: [],
            skuKeyword: '',
            searchTypes: [
                { id: '1', name: '按照订单编号查询' },
                { id: '2', name: '按网店订单号查询' }
                // { id: '3', name: '按账单流水号查询' }
            ],
            drawerQueryField: [
                'searchType',
                'searchTypeValue',
                'groupId',
                'lastTradeTime',
                'tradeTime',
                'payTime'
            ], // 抽屉不重置的字段
            drawerQuery: {
                groupId: null
            },
            dateFormat: 'yyyy-MM-dd HH:mm:ss',
            format: 'YYYY.MM.DD HH:mm:ss',
            defaultTime: ['00:00:00', '23:59:59']
        }
    },
    computed: {
        moreFilters () {
            const filters = {}
            const fields = {
                searchTypeValue: '查询值',
                groupId: '组织',
                lastTradeTime: '还原单据时间',
                tradeTime: '下单时间',
                payTime: '付款时间'
            }
            const query = _.cloneDeep(this.$attrs.query)
            // todo： searchType
            const search = this.searchTypes.find(item => item.id === query.searchType)
            if (search) {
                fields.searchTypeValue = search.name
            }
            Object.keys(fields).forEach((key, index) => {
                if (!_.isEmpty(query[key])) {
                    let queryVal = query[key]
                    if (this.drawerQuery[key] !== undefined) {
                        queryVal = this.drawerQuery[key]
                    } else if (key.includes('Time')) {
                        const [start, end] = queryVal
                        queryVal = moment(start).format(this.format) + '-' + moment(end).format(this.format)
                    } else if (key === 'searchTypeValue') {
                        queryVal = queryVal.split(/[(\s\r\n,，)\s\r\n,，]+/g)
                    }
                    filters[key] = `${fields[key]}：${queryVal}`
                }
            })
            return filters
        },
        ...mapState({
            channels: state => state.customer.channels,
            gropus: state => state.customer.allDeps
        }),
        groupTree () {
            if (_.isEmpty(this.gropus)) {
                return []
            }
            return utils.makeDataToTree(
                this.gropus,
                'parentId',
                'id',
                'groupName'
            )
        }
    },
    methods: {
        async querySearchSku (queryString) {
            // 快速增加sku
            if (!queryString) {
                return
            }
            this.skuLoading = true
            // 远程搜索
            const query = {
                enabled: true, // 启用状态
                noOrTitleLike: queryString, // 渠道名称
                current: 1,
                size: 10
            }
            const { data = [], total = 0 } = await customerSvc.getSkulistPage(
                query
            )
            this.skuLoading = false
            this.skuList = data
        },
        handleClose (val, drawerQuery) {
            const cascader = this.$refs['cascaderHandle']
            const target = this.gropus.find(item => item.id === val) || {}
            drawerQuery.groupId = target.groupName || null
            cascader.dropDownVisible = false
            if (!val) {
                // 清空已选择项
                cascader.$refs.panel.checkedValue = [] // 也可以是指定的值
                cascader.$refs.panel.calculateCheckedNodePaths()
            }
        },
        handleDrawerReset (val) {
            this.drawerQuery = _.cloneDeep(val)
        }
    }
}
</script>

<style lang="scss" scoped>
.gb-tracking-order-query {
    .w240 {
        width: 240px;

        ::v-deep.el-select {
            width: 240px !important;
        }
    }

    .wrem350 {
        width: 4.5rem;
    }

    .mrrem40 {
        margin-right: 0.4rem;
    }

    .mbrem10 {
        margin-bottom: 0.1rem;
    }

    .mtrem20 {
        margin-top: 0.2rem;
    }

    .mbrem20 {
        margin-bottom: 0.2rem;
    }

    .mbrem30 {
        margin-bottom: 0.3rem;
    }

    .custom-h {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
    }

    .w160 {
        width: 160px;
    }

    .w340 {
        width: 340px;
    }

    .mr40 {
        margin-right: 40px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .w60 {
        width: 60px;
    }

    // .monitoring-time {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     font-size: 14px;

    //     span {
    //         margin: 0 10px;
    //         color: #c0c4cc;

    //         &:first-of-type {
    //             margin-left: 0;
    //         }
    //     }
    // }

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}

.custom-select-item {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 50px;
    line-height: 50px;

    & > div,
    & > span {
        line-height: 1;
    }

    & > div {
        font-size: 12px;
        color: #b4b4b4;

        .el-tag {
            &:not(:first-of-type) {
                margin-left: 10px;
            }
            color: #b4b4b4;
        }
    }
}
</style>
