<template>
    <div class="daily-detail">
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <el-form-item label="">
                    <div
                        v-for="item in dateGroup"
                        :key="item.type"
                        class="custom-radio-item"
                        :class="{ 'radio-checked': item.type === checkedName }"
                        @click="selectDateType(item)"
                    >
                        {{ item.title }}
                        <el-date-picker
                            :ref="`date-${item.type}`"
                            v-model="item.value"
                            :picker-options="pickerOptions"
                            :type="item.type"
                            @change="handleDateTypeChange(item.type)"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="dateChange"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="同期同比">
                    <el-radio-group v-model="signRadio">
                        <el-radio-button :label="1">年</el-radio-button>
                        <el-radio-button :label="2">月</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button type="primary" @click="querydata"> 查询 </el-button>
                        <!-- <el-button @click="reset">重置</el-button> -->
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="addData">
                    <i class="iconfont icon-bianji">数据补录</i>
                </span>
                <span>
                    <el-dropdown trigger="click">
                        <i class="iconfont icon-gengduocaozuo">更多操作</i>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                @click.native="handleRefesh"
                            >刷新数据</el-dropdown-item>
                            <el-dropdown-item
                                @click.native="handleDataPull"
                            >账单重新拉取</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <span class="tip">
                    <!-- 最近更新时间：{{ $moment().format("YYYY/MM/DD HH:mm:ss") }} -->
                    展示比例为基于去退款后GMV的费率
                </span>
            </div>
            <div class="gb-table">
                <el-table
                    v-if="show"
                    ref="table"
                    :style="'padding-bottom: 44px'"
                    border
                    height="4rem"
                    :data="tableData"
                    row-key="accountingItemId"
                    :cell-class-name="tableColumnClassName"
                >
                    <el-table-column
                        type="index"
                        min-width="50"
                        label=""
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="accountingItemTitle"
                        :label="storeName"
                        min-width="200"
                        :show-overflow-tooltip="true"
                        align="left"
                    >
                        <template slot="header">
                            <p v-if="isLeaf === 2">
                                {{ $route.query.groupName }}
                            </p>
                            <p>{{ storeName }}</p>
                        </template>
                        <template slot-scope="scope">
                            <!-- <i v-if="scope.row.levelNumber===2" class="el-icon-arrow-right"></i> -->
                            <span
                                class="indent"
                                :class="{
                                    trident: scope.row.levelNumber === 2,
                                }"
                            >
                                {{ scope.row.accountingItemTitle }}
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content">
                                        {{ scope.row.accountingItemTitle }}
                                        <span v-if="scope.row.description">
                                            <br />
                                            描述：{{ scope.row.description | nothingFilter }}
                                        </span>
                                        <span v-if="scope.row.chineseFormula">
                                            <br />
                                            公式：{{ scope.row.chineseFormula | nothingFilter }}
                                        </span>
                                        <span v-if="dailyTypeDictsFilter(scope.row.dailyType)">
                                            <br />
                                            日常：{{ dailyTypeDictsFilter(scope.row.dailyType).title }}
                                        </span>
                                        <span v-if="actualTypeDictsFilter(scope.row.dataFrom)">
                                            <br />
                                            实际：{{ actualTypeDictsFilter(scope.row.dataFrom).title }}
                                        </span>
                                        <span v-if="budgetTypeDictsFilter(scope.row.budgetType)">
                                            <br />
                                            预计：{{ budgetTypeDictsFilter(scope.row.budgetType).title }}
                                        </span>
                                        <span v-if="huanbijizhunDictsFilter(scope.row.rateBase)">
                                            <br />
                                            同环比基准：{{ huanbijizhunDictsFilter(scope.row.rateBase).title }}
                                        </span>
                                        <div v-if="$route.query.isLeaf == 0">
                                            以上为默认逻辑，当前计算为下级组织计算后结果加和，详细方式可见对应收支设置
                                        </div>
                                    </div>
                                    <i class="el-icon-warning" style="cursor: pointer;"></i>
                                </el-tooltip>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sales"
                        label="当期"
                        align="center"
                        width="200"
                    >
                        <template slot="header">
                            <p>当期</p>
                            <p>
                                {{
                                    $moment(dateRange[0]).format("YYYY.MM.DD") +
                                        "-" +
                                        $moment(dateRange[1]).format("YYYY.MM.DD")
                                }}
                            </p>
                        </template>
                        <el-table-column label="金额" align="right">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.accountingItemTitle !== '销量'"
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentValue),
                                    }"
                                >
                                    {{ scope.row.currentValue | moneyFilter }}
                                </span>
                                <span
                                    v-else
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentValue),
                                    }"
                                >
                                    {{ scope.row.currentValue | roundFilter }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费率" align="left">
                            <template slot-scope="scope">
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentRate),
                                    }"
                                >
                                    {{ filterRate(scope.row.currentRate) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column
                        prop="sales"
                        label="过去同期"
                        align="center"
                        width="200"
                    >
                        <template slot="header">
                            <p>上个周期</p>
                            <p>
                                {{ lasetTDateRange[0] + "-" + lasetTDateRange[1] }}
                            </p>
                        </template>
                        <el-table-column label="金额" align="right">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.accountingItemTitle !== '销量'"
                                    :class="{
                                        lessZero: isLessZero(scope.row.historyValue),
                                    }"
                                >
                                    {{ scope.row.historyValue | moneyFilter }}
                                </span>
                                <span
                                    v-else
                                    :class="{
                                        lessZero: isLessZero(scope.row.historyValue),
                                    }"
                                >
                                    {{ scope.row.historyValue }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费率" align="left">
                            <template slot-scope="scope">
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row.historyRate)
                                            || isLessZero(scope.row.historyValue),
                                    }"
                                >
                                    {{ filterRate(scope.row.historyRate) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="环比">
                        <template slot-scope="scope">
                            <span>
                                {{ filterRate(scope.row.ringRate) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sales"
                        label="上月"
                        align="center"
                        width="200"
                    >
                        <template slot="header">
                            <tempalte v-if="pageSign === '日常报表详情'">
                                <p>{{ signRadio === 1 ? "上年同期实际" : "上月同期实际" }}</p>
                            </tempalte>
                            <tempalte v-else>
                                <p>{{ signRadio === 1 ? "上年同期" : "上月同期" }}</p>
                            </tempalte>
                            <p>
                                {{ monthOrYeaStartDate + "-" + monthOrYeaEndDate }}
                            </p>
                        </template>
                        <el-table-column label="金额" align="right">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.accountingItemTitle !== '销量'"
                                    :class="{
                                        lessZero: isLessZero(scope.row.monthOrYearValue),
                                    }"
                                >
                                    {{ scope.row.monthOrYearValue | moneyFilter }}
                                </span>
                                <span
                                    v-else
                                    :class="{
                                        lessZero: isLessZero(scope.row.monthOrYearValue),
                                    }"
                                >
                                    {{ scope.row.monthOrYearValue }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费率" align="left">
                            <template slot-scope="scope">
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row.monthOrYearRate)
                                            || isLessZero(scope.row.monthOrYearValue),
                                    }"
                                >
                                    {{ filterRate(scope.row.monthOrYearRate) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="同比">
                        <template slot-scope="scope">
                            <span>
                                {{ filterRate(scope.row.withRate) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-if="pageSign === '日常报表详情'"
                        prop="sales"
                        label="当期实际"
                        align="center"
                        width="200"
                    >
                        <template slot="header">
                            <p>当期实际</p>
                            <p>
                                {{
                                    $moment(dateRange[0]).format("YYYY.MM.DD") +
                                        "-" +
                                        $moment(dateRange[1]).format("YYYY.MM.DD")
                                }}
                            </p>
                        </template>
                        <el-table-column label="金额" align="right">
                            <template slot-scope="scope">
                                <span
                                    v-if="scope.row.accountingItemTitle !== '销量'"
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentOrderValue),
                                    }"
                                >
                                    {{ scope.row.currentOrderValue | moneyFilter }}
                                </span>
                                <span
                                    v-else
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentOrderValue),
                                    }"
                                >
                                    {{ scope.row.currentOrderValue }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="费率" align="left">
                            <template slot-scope="scope">
                                <span
                                    :class="{
                                        lessZero: isLessZero(scope.row.currentOrderRate)
                                            || isLessZero(scope.row.currentOrderValue),
                                    }"
                                >
                                    {{ filterRate(scope.row.currentOrderRate) }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
            <upload-dialog ref="uploadDialog" />
            <pullDataDialog ref="pullDataDialog" @handle-refresh="querydata" />
        </div>
    </div>
</template>

<script>
import UploadDialog from '../uploadDialog'
import utils from '@/utils/index'
import moment from 'moment'
import accountSvc from 'services/accounting'
import commonSvc from 'services/common'
import breadSlot from './components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot'
import { mapMutations } from 'vuex' // 插入自定义面包屑
import pullDataDialog from './components/dataPullDia'

import { getTheSamePeriod, getDiff, getCurrentMonthOrWeek } from 'utils/date'

const breadSlotMixin = new BreadSlotMixin({ breadSlot })
export default {
    name: 'DailyReportDetail',
    components: { UploadDialog, pullDataDialog },
    filters: {
        moneyFilter: function (value) {
            return '￥' + utils.moneyFilter(value)
        },
        nothingFilter: function (value) {
            return value || '-'
        },
        roundFilter: function (value) {
            return Math.round(value)
        }
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            id: null, // 主键id
            pickerOptions: {
                // 限制预约时间
                disabledDate (time) {
                    return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
                },
                firstDayOfWeek: 1
            },
            storeName: '',
            searchForm: {
                tenantId: '',
                groupId: '',
                startDate: '',
                endDate: ''
            },
            tableData: [],
            signRadio: 2, // 同期同比的值
            dateRange: ['', ''],
            isLeaf: '',
            tenantId: '',
            show: false,
            pageSign: '日常报表详情',
            dateGroup: [
                {
                    title: '年',
                    type: 'year',
                    value: null,
                    defalut: null,
                    visible: false
                },
                {
                    title: '月',
                    type: 'month',
                    value: null,
                    default: null,
                    visible: false
                },
                {
                    title: '周',
                    type: 'week',
                    value: null,
                    default: null,
                    visible: false
                }
            ], // 日期
            checkedName: 'week',
            dateTypeRadioObj: {},
            huanbijizhunDicts: [],
            budgetTypeDicts: [],
            planTypeDicts: [],
            dailyTypeDicts: [],
            actualTypeDicts: []
        }
    },
    computed: {
    // 上个周期时间
        lasetTDateRange () {
            const days = this.$moment(this.dateRange[1]).diff(
                this.$moment(this.dateRange[0]),
                'days'
            )
            const yesterday = this.$moment(this.dateRange[0])
                .subtract(1, 'days')
                .format('YYYY.MM.DD')
            const lastTFirstDay = this.$moment(this.dateRange[0])
                .subtract(1 + days, 'days')
                .format('YYYY.MM.DD')
            return [lastTFirstDay, yesterday]
        },
        // 去年、上月同期开始时间
        monthOrYeaStartDate () {
            return getTheSamePeriod(
                this.dateRange[0],
                1,
                this.signRadio === 1 ? 'year' : 'month',
                'YYYY.MM.DD'
            )
        },
        // 去年、上月同期结束时间
        monthOrYeaEndDate () {
            return getTheSamePeriod(
                this.dateRange[1],
                1,
                this.signRadio === 1 ? 'year' : 'month',
                'YYYY.MM.DD'
            )
        }
    },
    watch: {
        radio (newValue, oldValue) {
            if (newValue) {
                this.setDateRange(newValue)
            }
        }
        // $route (newValue) {
        //     let query = JSON.stringify(newValue.query)
        //     if (query !== '{}') {
        //         this.initQueryData(newValue)
        //         this.initData(this.isLeaf)
        //     }
        // }
    },
    activated () {
        this.$nextTick(() => {
            if (this.$refs.table) {
                this.$refs.table.doLayout()
            }
        })
        // this.handleDateTypeChange('week')
        this.addSlot(breadSlot)
        this.resetDate()
        const id = this.$route.query.id
        console.log(id, '====', this.id)
        if (id != this.id) {
            this.initQueryData(this.$route)
            this.initData(this.isLeaf)
            this.id = this.$route.query.id
        }
    },
    created () {
    // this.setDateRange(this.radio)
        this.id = this.$route.query.id
        this.pageSign = this.$route.meta.title || '日常报表详情'
        this.resetDate()
        this.getDicts()
        // 获取当前登录人信息
        this.initQueryData(this.$route)
        this.initData(this.isLeaf)
        this.$nextTick(() => {
            if (this.$refs.table) {
                this.$refs.table.doLayout()
            }
        })
    },
    methods: {
        ...mapMutations('transmit', {
            addSlot: 'UPDATE_BREAD_SLOT'
        }),
        // 账单数据重新拉取
        handleDataPull () {
            const _ref = this.$refs.pullDataDialog
            _ref.dateRange = this.dateRange
            _ref.groupId = this.$route.query.groupId
            _ref.visible = true
        },
        // 重置日期
        resetDate () {
            this.dateGroup.forEach((item) => {
                item.value = null
            })
        },
        // 获取数据字典
        async getDicts () {
            try {
                const { data = [] } = await commonSvc.getDictionary(
                    '管会收支项目分类,管会收支项目属性,管会同环比基准,管会预算填写方式,管会迭代规划方式,管会日常管理方式,管会实际取数方式'
                )
                this.huanbijizhunDicts = data.filter(
                    (item) => item.itemGroup === '管会同环比基准'
                )
                console.log(this.huanbijizhunDicts)
                this.budgetTypeDicts = data.filter(
                    (item) => item.itemGroup === '管会预算填写方式'
                )
                this.planTypeDicts = data.filter(
                    (item) => item.itemGroup === '管会迭代规划方式'
                )
                this.dailyTypeDicts = data.filter(
                    (item) => item.itemGroup === '管会日常管理方式'
                )
                this.actualTypeDicts = data.filter(
                    (item) => item.itemGroup === '管会实际取数方式'
                )
            } catch (error) {}
        },
        // 选择年、月、周
        selectDateType (item) {
            this.$refs[`date-${item.type}`][0].showPicker()
            this.dateTypeRadioObj = item
            this.checkedName = this.dateTypeRadioObj.type
        },
        // 年、月、周时间组件值变化事件
        handleDateTypeChange (type) {
            this.dateGroup.forEach(item => {
                if (item.type !== type) {
                    item.value = null
                }
            })
            this.setDateRange(this.dateTypeRadioObj)
        },
        // 根据年、月、周赋值时间区间
        setDateRange (item) {
            const { type, value } = item
            this.dateRange = [
                getCurrentMonthOrWeek('start', value, type),
                getCurrentMonthOrWeek('end', value, type)
            ]
            this.querydata()
            console.log(this.dateRange)
        },
        // 初始化查询数据
        initQueryData (route) {
            const query = route.query
            this.searchForm.groupId = query.groupId
            this.storeName = query.name
            this.tenantId = query.tenantId
            this.checkedName = query.checkedName
            this.isLeaf = Number(query.isLeaf)
            this.dateRange = JSON.parse(query.dateRange)
        },
        // 查询日期变化
        dateChange (val) {
            const res = this.quickSearch.filter((item) => {
                return (
                    moment(item.date[0]).format('YYYYY-MM-DD') ===
            moment(val[0]).format('YYYYY-MM-DD') &&
          moment(item.date[1]).format('YYYYY-MM-DD') ===
            moment(val[1]).format('YYYYY-MM-DD')
                )
            })
            this.radio = res.length > 0 ? res[0].value : ''
        },
        // 获取表格数据
        async initData (isLeaf) {
            this.show = false
            this.tableData = []
            let apiName = isLeaf
                ? isLeaf === 1
                    ? 'getActualListLeafDetail'
                    : 'getActualListProductDetail'
                : 'getActualListNotLeafDetail'

            if (this.pageSign === '日常报表详情') {
                switch (isLeaf) {
                case 0:
                    // 店铺
                    apiName = 'getDailyReportNotLeafDetail'
                    break
                case 1:
                    // 产品
                    apiName = 'getDailyReportLeafDetail'
                    break
                default:
                    // 收支项目
                    apiName = 'getDailyReportProductDetail'
                    break
                }
            } else if (this.pageSign === '实际报表详情') {
                switch (isLeaf) {
                case 0:
                    // 店铺
                    apiName = 'getActualListNotLeafDetail'
                    break
                case 1:
                    // 产品
                    apiName = 'getActualListLeafDetail'
                    break
                default:
                    // 收支项目
                    apiName = 'getActualListProductDetail'
                    break
                }
            }
            const dateRange = this.getRealDateRange(this.dateRange)
            const param = {
                tenantId: this.tenantId,
                groupId: this.searchForm.groupId,
                startDate: this.$moment(dateRange[0]).format('YYYY-MM-DD'),
                endDate: this.$moment(dateRange[1]).format('YYYY-MM-DD'),
                historyStartDate: this.$moment(this.lasetTDateRange[0]).format(
                    'YYYY-MM-DD'
                ),
                historyEndDate: this.$moment(this.lasetTDateRange[1]).format(
                    'YYYY-MM-DD'
                ),
                monthOrYeaStartDate: this.$moment(this.monthOrYeaStartDate).format(
                    'YYYY-MM-DD'
                ),
                monthOrYeaEndDate: this.$moment(this.monthOrYeaEndDate).format(
                    'YYYY-MM-DD'
                )
            }
            // 产品详情
            if (isLeaf === 2) {
                param.spuId = this.$route.query.id
            }
            try {
                const { data = [] } = await accountSvc[apiName](param)
                this.tableData = utils.makeDataToTree(
                    data,
                    'parentId',
                    'accountingItemId'
                )
                this.show = true
            } catch (error) {
                console.log(error)
            }
        },
        // 处理表格数据--金额
        filterValue (row, propName) {
            if (row.dmpTierList && row.dmpTierList.length > 0) {
                let value = ''
                const res = row.dmpTierList.filter((item) => {
                    return item.accountingItemTitle == propName
                })
                if (res.length > 0) {
                    value =
            propName !== '销量'
                ? '￥' + utils.moneyFilter(res[0].value)
                : Math.round(res[0].value)
                }
                return value
            }
        },
        // 处理表格数据--比例
        filterRate (rate) {
            return rate ? Number(rate).toFixed(2) + '%' : '-'
        },
        // 判断数据是否小于0
        isLessZero (val) {
            if (!isNaN(Number(val))) {
                return Number(val) < 0
            }
        },
        // 数据补录按钮事件
        addData () {
            this.$refs.uploadDialog.dialogVisible = true
            this.$refs.uploadDialog.show = true
        },
        // 查询按钮
        querydata () {
            this.initData(this.isLeaf)
        },
        handleClick () {},
        // 刷新数据
        async handleRefesh () {
            const dateRange = this.getRealDateRange(this.dateRange)
            const param = {
                groupId: this.searchForm.groupId,
                startDate: this.$moment(dateRange[0]).format('YYYY-MM-DD'),
                endDate: this.$moment(dateRange[1]).format('YYYY-MM-DD')
            }
            let apiName =
        this.pageSign === '日常报表详情'
            ? 'dailyReportRefresh'
            : 'actualReportRefresh'
            try {
                this.$message.success('操作成功，数据更新后将通过钉钉通知，请稍候查看！')
                await accountSvc[apiName](param)
                // this.initData(this.isLeaf)
            } catch (error) {
                console.log(error, '=====error')
                this.$message.warning('操作失败！')
            }
        },
        // 判断开始时间、结束时间是否超过昨天
        // 如果开始时间超过昨天----》 开始和结束时间都取昨天值
        // 如果结束时间超过昨天 ---》结束时间取昨天值
        getRealDateRange (dateRange) {
            let res = null
            const yester = this.$moment().subtract('days', 1).format('YYYY-MM-DD')
            const start = this.$moment(dateRange[0]).format('YYYY-MM-DD')
            const end = this.$moment(dateRange[1]).format('YYYY-MM-DD')
            if (getDiff(start, yester) > 0) {
                res = [yester, yester]
            } else if (getDiff(end, yester) > 0) {
                res = [start, yester]
            } else {
                res = [start, end]
            }
            return res
        },
        // 给某一列增加class类
        tableColumnClassName ({ columnIndex }) {
            if (columnIndex === 1) {
                return 'level'
            }
        },
        huanbijizhunDictsFilter (value) {
            const res = this.huanbijizhunDicts.find(
                (item) => Number(item.itemValue) === Number(value)
            )
            return res
        },
        budgetTypeDictsFilter (value) {
            const res = this.budgetTypeDicts.find(
                (item) => Number(item.itemValue) === Number(value)
            )
            return res
        },
        planTypeDictsFilter (value) {
            const res = this.planTypeDicts.find(
                (item) => Number(item.itemValue) === Number(value)
            )
            return res
        },
        dailyTypeDictsFilter (value) {
            const res = this.dailyTypeDicts.find(
                (item) => Number(item.itemValue) === Number(value)
            )
            return res
        },
        actualTypeDictsFilter (value) {
            const res = this.actualTypeDicts.find(
                (item) => Number(item.itemValue) === Number(value)
            )
            return res
        }
    }
}
</script>
<style lang="less" scoped>
.custom-radio-item {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    width: 48px;
    height: 40px;
    color: #333;
    text-align: center;
    cursor: pointer;
    border: 1px solid #dcdfe6;
    // background-color: #3595e1;
    .el-date-editor {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        opacity: 0;
    }

    &:hover {
        color: #3595e1;
        border: 1px solid #3595e1;
    }
}

.radio-checked {
    color: #fff;
    background-color: #3595e1;
    border: 1px solid #3595e1;

    &:hover {
        color: #fff;
    }
}

.custom-radio-item + .custom-radio-item {
    margin-left: 10px;
}

/deep/ .el-table {
    .level {
        .cell {
            position: relative;
            padding-left: 25px;

            .el-table__indent {
                padding-left: 0 !important;
            }

            .el-table__expand-icon {
                position: absolute;
                left: 0;
            }
        }
    }

    .el-table__row.el-table__row--level-1 {
        background-color: #f7f9fc;
    }
}

/deep/ .el-table__fixed-right::before {
    height: 0;
}
</style>
