<template>
    <el-dialog
        class="add-period"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-form :model="form" :show-message="false" ref="form">
            <el-table :data="form.list">
                <el-table-column label="名称" align="center" prop="title">
                    <template slot="header">
                        <span class="star">*</span>
                        <span class="starName">名称</span>
                    </template>
                    <template slot-scope="scope">
                        <span v-if="!scope.row.edit">{{
                            scope.row.title
                        }}</span>
                        <el-form-item
                            v-else
                            :prop="`list.${scope.$index}.title`"
                            :rules="tableRules.title"
                        >
                            <el-input
                                placeholder="请输入名称"
                                v-model="scope.row.title"
                            ></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                    label="时间从"
                    align="center"
                    prop="periodStart"
                    ><template slot="header">
                        <span class="star">*</span>
                        <span class="starName">时间从</span>
                    </template>
                    <template slot-scope="scope">
                        <span v-if="!scope.row.edit">{{
                            scope.row.periodStart
                        }}</span>
                        <el-form-item
                            v-else
                            :prop="`list.${scope.$index}.title`"
                            :rules="tableRules.title"
                        >
                            <el-date-picker
                                v-model="scope.row.periodStart"
                                type="month"
                                value-format="yyyy-MM"
                                placeholder="选择月"
                            >
                            </el-date-picker> </el-form-item></template
                ></el-table-column>
                <el-table-column label="时间至" align="center" prop="periodEnd">
                    <template slot="header">
                        <span class="star">*</span>
                        <span class="starName">时间至</span>
                    </template>
                    <template slot-scope="scope">
                        <span v-if="!scope.row.edit">{{
                            scope.row.periodEnd
                        }}</span>
                        <el-form-item
                            v-else
                            :prop="`list.${scope.$index}.title`"
                            :rules="tableRules.title"
                        >
                            <el-date-picker
                                v-model="scope.row.periodEnd"
                                type="month"
                                value-format="yyyy-MM"
                                placeholder="选择月"
                            >
                            </el-date-picker> </el-form-item></template
                ></el-table-column>
                <el-table-column label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button
                            v-if="scope.row.edit"
                            type="danger"
                            icon="el-icon-delete"
                            size="mini"
                            @click="handleDel(scope.$index)"
                        ></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="add-footer">
                <span @click="handleAddOption">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                </span>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountSvc from 'services/accounting'
export default {
    name: 'AddPeriod',
    components: {},
    data() {
        return {
            visible: false,
            title: '预算期间管理',
            form: {
                list: []
            },
            tableRules: {
                title: [{ required: true }],
                periodStart: [{ required: true }],
                periodEnd: [{ required: true }]
            }
        }
    },
    created() {},
    watch: {
        visible(newValue, oldValue) {
            if (newValue) {
                // this.getPeriodList()
            }
        }
    },
    methods: {
        handleConfirm() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    const param = this.form.list.filter(item => item.edit)
                    await accountSvc.addPeriodList(param)
                    this.$message.success('添加成功！')
                    this.visible = false
                    this.$emit('handle-refresh')
                } else {
                    console.log('error submit')
                    this.$message.warning('请完善表格内容！')
                }
            })
        },
        handleCancel() {
            this.visible = false
        },
        handleAddOption() {
            this.form.list.push({
                title: '',
                periodStart: '',
                periodEnd: '',
                edit: true
            })
        },
        handleDel(index) {
            this.form.list.splice(index, 1)
        }
    }
}
</script>
<style lang="scss" scoped>
.add-period {
    ::v-deep .el-dialog__body {
        max-height: 600px;
        overflow-y: auto;
        padding: 17px 37px 24px;
        .star {
            color: #f56c6c;
            font-size: 14px;
            margin-right: 6px;
        }
        .starName {
            font-size: 14px;
            // font-weight: 500;
            // color: #333;
        }
        .el-form-item {
            margin: 0;
            .el-form-item__content {
                height: 40px;
            }
        }
        .el-input,
        input {
            height: 100%;
            max-width: 220px;
            border-radius: 0;
        }
    }
    .add-footer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        font-size: 16px;
        font-weight: 400;
        color: #3595e1;
        span {
            cursor: pointer;
        }
        i {
            margin-right: 7px;
        }
    }
}
</style>
