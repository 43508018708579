<template>
    <div v-if="!pageVisible" class="account-setting">
        <el-form
            label-position="top"
            label-width="80px"
            class="custom-form"
        >
            <el-form-item label="规划设置">
                <h4>规划审批</h4>
                <div class="align-center">
                    <el-checkbox
                        v-model="form.planSetting.planApprovalEnable"
                        :disabled="!editStatus"
                    >
                    </el-checkbox>
                    启用规划审批，引用审批流
                    <el-input
                        v-model="form.planSetting.cpprovalNo"
                        class="ml20 w240"
                        :disabled="!editStatus"
                    ></el-input>
                </div>
                <h4>规划创建</h4>
                <div>
                    <div class="align-center">
                        <el-checkbox
                            v-model="form.planSetting.taskPlanCreateEnable"
                            :disabled="!editStatus"
                        >
                        </el-checkbox>
                        启用自动生成规划，生成时间为每月 日
                        <day-select
                            v-model="form.planSetting.createDate"
                            class="ml20"
                            :disabled="!editStatus"
                        ></day-select>
                    </div>
                    <div>
                        <el-radio
                            v-model="groupId"
                            :label="1"
                            :disabled="!editStatus"
                        >
                            有渠道关联的组织
                        </el-radio>
                        <el-radio v-model="groupId" :label="2" :disabled="!editStatus">
                            有收支设置的组织
                        </el-radio>
                        <el-radio v-model="groupId" :label="3" :disabled="!editStatus">
                            以下选择的组织
                        </el-radio>
                        <gb-department
                            ref="oaGroup"
                            v-model="form.planSetting.groupIds"
                            class="oaGroup"
                            multiple
                            collapse-tags
                            placeholder="全部部门"
                            api-name="getAllDepts"
                            :is-cascader="true"
                            :show-all-levels="false"
                            value-filed="id"
                            :expand-trigger="'click'"
                            label-filed="groupName"
                            :disabled="!editStatus"
                            :is-init-val="true"
                            style="display: inline-block;"
                        >
                        </gb-department>
                    </div>
                </div>
                <div class="pl20">
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planTodoAssociated.groupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内岗位
                        <job-select
                            v-model="
                                form.planSetting.planTodoAssociated.groupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planTodoAssociated
                                    .groupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内负责人
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planTodoAssociated
                                    .parentGroupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织岗位
                        <job-select
                            v-model="
                                form.planSetting.planTodoAssociated
                                    .parentGroupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planTodoAssociated
                                    .parentGroupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织负责人
                    </div>
                </div>
                <h4>规划截止预警</h4>
                <div class="mb10">
                    <div>
                        <el-checkbox
                            v-model="form.planSetting.planSubmitRemindEnable"
                            :disabled="!editStatus"
                        >
                        </el-checkbox>
                        启用规划提交提醒，若每月
                        <day-select
                            v-model="form.planSetting.remindDate"
                            class="ml20"
                            :disabled="!editStatus"
                        ></day-select>
                        日前未提交则对组织以下人员发送提醒
                    </div>
                </div>
                <div class="pl20">
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .groupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内岗位
                        <job-select
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .groupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .groupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内负责人
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .parentGroupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织岗位
                        <job-select
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .parentGroupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.planSetting.planSubmitRemindAssociated
                                    .parentGroupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织负责人
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="日常报表补录提醒">
                <div>
                    <el-checkbox
                        v-model="form.dailyRemind.todoEnable"
                        :disabled="!editStatus"
                    >
                        启用自动生成补录待办，给以下设置人员创建待办，发送提醒，
                    </el-checkbox>
                    <and-select
                        v-model="form.dailyRemind.repeatEnable"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></and-select>
                    重复设置根据
                    <date-type-select
                        v-model="form.dailyRemind.remindDate"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></date-type-select>
                    生成
                    <skip-select
                        v-model="form.dailyRemind.weekdayEnable"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></skip-select>
                    工作日
                    <!-- todo:日选择 -->
                    <day-select
                        v-if="form.dailyRemind.remindDate === 1"
                        v-model="form.dailyRemind.remindNum"
                        :disabled="!editStatus"
                        class="ml20"
                        :is-last-day="true"
                    ></day-select>
                    <weeks-select
                        v-if="form.dailyRemind.remindDate === 2"
                        v-model="form.dailyRemind.remindNum"
                        :disabled="!editStatus"
                        class="ml20"
                    ></weeks-select>
                    <month-select
                        v-if="form.dailyRemind.remindDate === 3"
                        v-model="form.dailyRemind.remindNum"
                        :disabled="!editStatus"
                        class="ml20"
                    ></month-select>
                </div>
                <div class="pl20">
                    <div>
                        <el-checkbox
                            v-model="
                                form.dailyRemind.dailyRemindAssociated
                                    .groupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内岗位
                        <job-select
                            v-model="
                                form.dailyRemind.dailyRemindAssociated.groupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.dailyRemind.dailyRemindAssociated
                                    .groupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内负责人
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.dailyRemind.dailyRemindAssociated
                                    .parentGroupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织岗位
                        <job-select
                            v-model="
                                form.dailyRemind.dailyRemindAssociated
                                    .parentGroupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.dailyRemind.dailyRemindAssociated
                                    .parentGroupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织负责人
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="实际报表补录提醒">
                <div>
                    <el-checkbox
                        v-model="form.actualRemind.todoEnable"
                        :disabled="!editStatus"
                    >
                        启用自动生成补录待办，给以下设置人员创建待办，发送提醒，
                    </el-checkbox>
                    <and-select
                        v-model="form.actualRemind.repeatEnable"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></and-select>
                    重复设置根据
                    <date-type-select
                        v-model="form.actualRemind.remindDate"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></date-type-select>
                    生成
                    <skip-select
                        v-model="form.actualRemind.weekdayEnable"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></skip-select>
                    工作日
                    <!-- todo:日选择 -->
                    <day-select
                        v-if="form.actualRemind.remindDate === 1"
                        v-model="form.actualRemind.remindNum"
                        :is-last-day="true"
                        :disabled="!editStatus"
                        class="ml20"
                    ></day-select>
                    <weeks-select
                        v-if="form.actualRemind.remindDate === 2"
                        v-model="form.actualRemind.remindNum"
                        :disabled="!editStatus"
                        class="ml20"
                    ></weeks-select>
                    <month-select
                        v-if="form.actualRemind.remindDate === 3"
                        v-model="form.actualRemind.remindNum"
                        :disabled="!editStatus"
                        class="ml20"
                    ></month-select>
                </div>
                <div class="pl20">
                    <div>
                        <el-checkbox
                            v-model="
                                form.actualRemind.dailyRemindAssociated
                                    .groupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内岗位
                        <job-select
                            v-model="
                                form.actualRemind.dailyRemindAssociated.groupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.actualRemind.dailyRemindAssociated
                                    .groupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        组织内负责人
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.actualRemind.dailyRemindAssociated
                                    .parentGroupEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织岗位
                        <job-select
                            v-model="
                                form.actualRemind.dailyRemindAssociated
                                    .parentGroupIds
                            "
                            class="ml20"
                            :disabled="!editStatus"
                        ></job-select>
                    </div>
                    <div>
                        <el-checkbox
                            v-model="
                                form.actualRemind.dailyRemindAssociated
                                    .parentGroupLeaderEnable
                            "
                            :disabled="!editStatus"
                        ></el-checkbox>
                        当前组织父组织负责人
                    </div>
                </div>
                <div>
                    <el-checkbox
                        v-model="form.actualRemind.taskVersionEnable"
                        :disabled="!editStatus"
                    >
                        启动实际数据版本自动记录,记录周期为
                    </el-checkbox>
                    <no-day-select
                        v-model="form.actualRemind.weeksOrMonth"
                        :disabled="!editStatus"
                        class="ml20 mr20"
                    ></no-day-select>
                    自动刷新记录时间为
                    <day-group-select
                        v-model="form.actualRemind.dateList"
                        :disabled="!editStatus"
                        class="ml20"
                    ></day-group-select>
                </div>
            </el-form-item>
            <el-form-item label="报表展示">
                <div>
                    报表页面展示的费率基准为
                    <el-select
                        v-model="form.reportsShow"
                        class="ml20"
                        clearable
                        filterable
                        :disabled="!editStatus"
                    >
                        <el-option
                            v-for="item in budgetList"
                            :key="item.value"
                            :value="item"
                            :label="item.label"
                        ></el-option>
                    </el-select>
                </div>
            </el-form-item>
        </el-form>
        <div class="footer">
            <el-button
                v-if="!editStatus"
                type="primary"
                @click="handleEdit(true)"
            >
                编辑
            </el-button>
            <template v-else>
                <el-button type="primary" @click="setAccountSetting">保存</el-button>
                <el-button @click="handleCancel(false)">取消</el-button>
            </template>
        </div>
    </div>
</template>
<script>
import _ from 'lodash'
import accountSvc from 'services/accounting'
import gbDepartment from '@/components/Department'
import components from './components/index'

export default {
    name: 'AccountSetting',
    components: {
        ...components,
        gbDepartment
    },
    data () {
        return {
            editStatus: false,
            groupId: null,
            form: {},
            budgetList: []
        }
    },
    computed: {
        pageVisible () {
            console.log(_.isEmpty(this.form))
            return _.isEmpty(this.form)
        }
    },
    created () {
        this.getAllData()
    },
    methods: {
        async handleCancel () {
            await this.getAccountSetting()
            this.handleEdit()
        },
        handleEdit (status) {
            this.editStatus = status
        },
        async setAccountSetting () {
            const form = _.cloneDeep(this.form)
            const {
                reportsShow
            } = form
            const {
                label: rateBaseItemName,
                value: rateBaseItemId
            } = reportsShow
            form.reportsShow = {
                rateBaseItemName,
                rateBaseItemId
            }
            const groups = ['accountingGroupEnable', 'channelGroupEnable', 'planApprovalEnable']
            groups.forEach((item, index) => {
                form.planSetting[item] = index + 1 === this.groupId
            })
            console.log(form)
            const params = {
                accountingSetting: JSON.stringify(form)
            }
            const { data } = await accountSvc.setAccountSetting(params)
            this.handleEdit()
        },
        async getAccountSetting () {
            const { data } = await accountSvc.getAccountSetting()
            let {
                actualRemind,
                reportsShow,
                dailyRemind,
                planSetting
            } = data
            const {
                rateBaseItemName: label,
                rateBaseItemId: value
            } = reportsShow
            reportsShow = {
                label,
                value
            }
            this.groupId = null
            if (planSetting.accountingGroupEnable) {
                this.groupId = 1
            } else if (planSetting.channelGroupEnable) {
                this.groupId = 2
            } else if (planSetting.planApprovalEnable) {
                this.groupId = 3
            }
            this.form = {
                actualRemind,
                reportsShow,
                dailyRemind,
                planSetting
            }
        },
        async getBudgetListCommon () {
            const { data = {} } = await accountSvc.getBudgetListCommon()
            this.budgetList = data.map(item => {
                return {
                    label: item.title,
                    value: item.id
                }
            })
        },
        async getAllData () {
            await this.getBudgetListCommon()
            await this.getAccountSetting()
        }
    }
}
</script>
<style lang="scss" scoped>
.account-setting {
    .ml20 {
        margin-left: 20px;
    }

    .mr20 {
        margin-right: 20px;
    }

    .mb10 {
        margin-bottom: 10px;
    }

    .w240 {
        width: 240px;
    }

    .pl20 {
        padding-left: 20px;
    }

    h4 {
        padding: 5px 0;
        font-size: 14px;
        color: #606266;
    }

    .align-center {
        display: flex;
        align-items: center;
    }

    ::v-deep {
        .el-form-item__label {
            font-size: 16px;
            font-weight: bold;
            color: #333;
        }
    }

    .custom-form {
        flex: 1;
        padding: 0 30px;
        overflow-y: auto;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
</style>
