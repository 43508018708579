<template>
    <div>
        <el-row class="total-list" type="flex">
            <el-col
                v-for="{label, endVal,options, key} in totalListView"
                :key="key"
                class="custom-col"
            >
                <div class="title">{{ label }}</div>
                <div class="num">
                    <span v-if="!endVal" v-loading="loading">
                        {{ endVal === null ? '-' : 0 }}
                    </span>
                    <i-count-up
                        v-if="endVal"
                        :key="`${key}${endVal}`"
                        v-loading="loading"
                        :delay="delay"
                        :end-val="endVal"
                        :options="options"
                        @ready="onReady"
                    >
                    </i-count-up>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import _ from 'lodash'
import ICountUp from 'vue-countup-v2'

export default {
    name: 'Total',
    components: {
        ICountUp
    },
    props: {
        loading: {
            type: Boolean,
            default: false
        },
        totalList: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            delay: 1000,
            names: {
                sellCount: '销量合计', // 销量合计
                contributions: '实付金额合计', // 实付金额合计
                detailCost: '采购成本合计', // 采购成本合计
                skuCost: '标准成本合计', // 标准成本合计
                sumCost: '综合成本合计' // 综合成本合计
            }
        }
    },
    computed: {
        totalListView () {
            if (_.isEmpty(this.totalList)) {
                return Object.keys(this.names).map(key => {
                    return {
                        label: this.names[key],
                        key,
                        endVal: 0,
                        options: {
                            decimalPlaces: 0
                        }
                    }
                })
            }
            const decimalPlaces = (val) => val && /\.+/.test(val) ? val.toString().split('.')[1].length : 0
            return Object.keys(this.totalList).map(key => {
                return {
                    label: this.names[key],
                    key,
                    endVal: this.totalList[key],
                    options: {
                        decimalPlaces: decimalPlaces(this.totalList[key])
                    }
                }
            })
        }
    },
    methods: {
        onReady (instance, countUp) {
            if (instance.endVal === null) {
                instance.pauseResume()
            } else {
                instance.start()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.total-list {
    flex-wrap: wrap;

    .title {
        // line-height: 0.24rem;
        padding: 0.04rem 0;
        font-size: 0.16rem;
        font-weight: 500;
        color: #909399;
    }

    .num {
        // line-height: 0.43rem;
        padding: 0.035rem 0;
        font-size: 0.36rem;
        font-weight: 500;
        color: #606266;
    }

    .custom-col {
        width: auto;
        min-width: 2.6rem;
        padding-right: 0.6rem;
    }
}
</style>
