import { mapMutations } from 'vuex'

export default function (config) {
    return {
        beforeRouteLeave (to, from, next) {
            this.addSlot()
            this.$bus.$offAll() // 移除所有监听
            next()
        },
        created () {
            this.addSlot(config.breadSlot)
        },
        mounted() {
        },
        methods: {
            ...mapMutations('transmit', {
                addSlot: 'UPDATE_BREAD_SLOT'
            })
        }
    }
}
