var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gb-tracking-order-query"},[_c('search-query',_vm._g(_vm._b({attrs:{"is-show-fold":false,"is-show-drawer":true,"drawer-query-field":_vm.drawerQueryField,"drawer-query":_vm.drawerQuery,"more-filters":_vm.moreFilters},on:{"handle-drawer-reset":_vm.handleDrawerReset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var query = ref.query;
var handleSearch = ref.handleSearch;
return [_c('el-select',{staticClass:"w240 mr40",attrs:{"placeholder":"渠道选择","filterable":true,"multiple":true,"clearable":"","collapse-tags":""},on:{"change":handleSearch},model:{value:(query.channelIds),callback:function ($$v) {_vm.$set(query, "channelIds", $$v)},expression:"query.channelIds"}},_vm._l((_vm.channels),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.channelName,"value":item.id}})}),1),_c('el-select',{staticClass:"w240 mr40 search-input",attrs:{"filterable":"","remote":"","reserve-keyword":"","multiple":true,"clearable":"","collapse-tags":"","placeholder":"输入搜索货品SKU快速增加","remote-method":function (skuKeyword) {
                        _vm.querySearchSku(skuKeyword, query)
                    },"loading":_vm.skuLoading},on:{"change":handleSearch},model:{value:(query.skuIds),callback:function ($$v) {_vm.$set(query, "skuIds", $$v)},expression:"query.skuIds"}},_vm._l((_vm.skuList),function(item){return _c('el-option',{key:item.id,staticClass:"custom-select-item",attrs:{"label":item.skuTitle,"value":item.id}},[_c('span',[_vm._v(_vm._s(item.skuTitle))]),_c('div',[(item.skuProperty)?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v(" 规格："+_vm._s(item.skuProperty)+" ")]):_vm._e(),(item.skuBarCode)?_c('el-tag',{attrs:{"size":"mini"}},[_vm._v(" 条码："+_vm._s(item.skuBarCode)+" ")]):_vm._e()],1)])}),1),_c('gb-dictionary',{staticClass:"w240 mr40",attrs:{"group":"交易订单货品明细类型","clearable":true,"filterable":true,"placeholder":"单据类型","multiple":"","collapse-tags":""},on:{"change":handleSearch},model:{value:(query.detailTypes),callback:function ($$v) {_vm.$set(query, "detailTypes", $$v)},expression:"query.detailTypes"}}),_c('gb-dictionary',{staticClass:"w240 mr40",attrs:{"group":"交易订单状态","clearable":true,"filterable":true,"placeholder":"订单状态","multiple":"","collapse-tags":""},on:{"change":handleSearch},model:{value:(query.tradeStatus),callback:function ($$v) {_vm.$set(query, "tradeStatus", $$v)},expression:"query.tradeStatus"}})]}},{key:"drawer",fn:function(ref){
                    var query = ref.query;
                    var drawerQueryView = ref.drawerQueryView;
return [_c('el-select',{staticClass:"wrem350 mrrem40 mbrem10",attrs:{"placeholder":"请选择查询条件","filterable":true},model:{value:(query.searchType),callback:function ($$v) {_vm.$set(query, "searchType", $$v)},expression:"query.searchType"}},_vm._l((_vm.searchTypes),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),_c('el-input',{staticClass:"mbrem10",attrs:{"type":"textarea","autosize":{ minRows: 6, maxRows: 8 },"placeholder":"可输入多个，多个时可以用逗号、空格或换行分开"},model:{value:(query.searchTypeValue),callback:function ($$v) {_vm.$set(query, "searchTypeValue", $$v)},expression:"query.searchTypeValue"}}),_c('h3',{staticClass:"custom-h mtrem20 mbrem10"},[_vm._v("管理组织")]),_c('el-cascader',{ref:"cascaderHandle",staticClass:"wrem350 mbrem10",attrs:{"options":_vm.groupTree,"show-all-levels":false,"props":{
                    checkStrictly: true,
                    value: 'id',
                    label: 'groupName',
                    emitPath: false,
                },"clearable":"","filterable":true,"placeholder":"请选择组织"},on:{"change":function($event){return _vm.handleClose($event, drawerQueryView)}},model:{value:(query.groupId),callback:function ($$v) {_vm.$set(query, "groupId", $$v)},expression:"query.groupId"}}),_c('h4',{staticClass:"custom-h mbrem10"},[_vm._v("还原单据时间")]),_c('el-date-picker',{staticClass:"wrem350 mbrem10",attrs:{"type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":_vm.dateFormat,"default-time":_vm.defaultTime},model:{value:(query.lastTradeTime),callback:function ($$v) {_vm.$set(query, "lastTradeTime", $$v)},expression:"query.lastTradeTime"}}),_c('h4',{staticClass:"custom-h mbrem10"},[_vm._v("下单时间")]),_c('el-date-picker',{staticClass:"wrem350 mbrem10",attrs:{"type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":_vm.dateFormat,"default-time":_vm.defaultTime},model:{value:(query.tradeTime),callback:function ($$v) {_vm.$set(query, "tradeTime", $$v)},expression:"query.tradeTime"}}),_c('h4',{staticClass:"custom-h mbrem10"},[_vm._v("付款时间")]),_c('el-date-picker',{staticClass:"wrem350 mbrem10",attrs:{"type":"datetimerange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":_vm.dateFormat,"default-time":_vm.defaultTime},model:{value:(query.payTime),callback:function ($$v) {_vm.$set(query, "payTime", $$v)},expression:"query.payTime"}})]}}])},'search-query',_vm.$attrs,false),_vm.$listeners))],1)}
var staticRenderFns = []

export { render, staticRenderFns }