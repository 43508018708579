<template>
    <div class="data-track-order-list">
        <div class="data-track-order-list-main">
            <el-table
                :data="list"
                style="width: 100%;"
                height="500"
                row-key="id"
            >
                <el-table-column
                    align="left"
                    label="序号"
                    width="100"
                    prop="index"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="{prop, label, sortable} in tableColNames"
                    :key="prop"
                    align="center"
                    :label="label"
                    :prop="prop"
                    :sortable="sortable"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import gbDictionary from '@/components/dictionary'
import sellSvc from 'services/sell'

export default {
    name: 'OperationActionLibraryList',
    // filters: {
    //     dictionaryFilter: gbDictionary.filter
    // },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            tableColNames: [
                {
                    label: '账务流水号',
                    prop: 'billNo'
                },
                {
                    label: '结算账务',
                    prop: 'partnerAccountTitle'
                },
                {
                    label: '收支项目',
                    prop: 'accountItemTitle'
                },
                {
                    label: '收支金额',
                    prop: 'billCost',
                    sortable: true
                },
                {
                    label: '币种',
                    prop: 'currencyName'
                },
                {
                    label: '账单发生时间',
                    prop: 'bookTime'
                },
                {
                    label: '订单编号',
                    prop: 'originalTradeNo'
                },
                {
                    label: '网店订单号',
                    prop: 'onlineTradeNo'
                },
                {
                    label: '渠道',
                    prop: 'channelName'
                },
                {
                    label: '还原单据时间',
                    prop: 'lastTradeTime'
                },
                {
                    label: '货品编码',
                    prop: 'spuNo'
                },
                {
                    label: '货品',
                    prop: 'productTitle'
                },
                {
                    label: '规格',
                    prop: 'skuProperty'
                },
                {
                    label: '单据类型',
                    prop: 'detailTypeTitle'
                },
                {
                    label: '下单时间',
                    prop: 'tradeTime'
                },
                {
                    label: '付款时间',
                    prop: 'payTime'
                },
                {
                    label: '订单状态',
                    prop: 'tradeStatusName'
                }
            ]
        }
    },
    // created () {
    //     gbDictionary.init(this)
    // },
    methods: {
    }
}
</script>
<style lang="scss" scoped>
.data-track-order-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.14rem;

        & + div {
            margin-top: 0.19rem;
        }

        &-operation {
            display: flex;
            align-items: center;
        }

        &-operation .item {
            margin-right: 0.19rem;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        &-operation .item i {
            margin-right: 0.09rem;
            font-size: 0.12rem;
        }

        & > span {
            color: #606266;
        }
    }

    &-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    ::v-deep.el-table {
        .has-gutter th {
            font-size: 0.14rem;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;

            &:hover {
                background: #f7f9fc !important;
            }

            .el-pagination * {
                background: transparent;
            }
        }

        .el-table--fit {
            background-color: #f7f9fc;
        }
    }

    ::v-deep.el-tabs__nav-wrap {
        padding: 0 1.16rem;

        .el-tabs__item {
            color: #909399;
        }
    }

    ::v-deep.el-table th.is-leaf {
        font-size: 0.14rem;
        font-weight: 500;
        color: #606266;
        background-color: #f7f9fc;
    }

    ::v-deep.el-tabs__header {
        margin: 0;
    }

    ::v-deep.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
}
</style>
