
<template>
    <div class="no-day-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            @change="handleChange"
        >
            <el-option
                v-for="item in list"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'NoDaySelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            selected: null
        }
    },
    computed: {
        list () {
            const obj = {
                1: '周',
                2: '月',
                3: '周，月'
            }
            return Object.keys(obj).map((item, index) => {
                return {
                    label: obj[item],
                    value: index + 1
                }
            })
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.no-day-select {
    display: inline-block;
    width: 150px;

    ::v-deep.el-select__tags-text {
        display: inline-block;
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    ::v-deep.el-tag__close.el-icon-close {
        margin-bottom: 14px;
    }
}
</style>
