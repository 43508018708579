<template>
    <div class="planning-fill-list">
        <div class="planning-fill-list-main">
            <el-table
                :data="list"
                style="width: 100%;"
                height="500"
                row-key="id"
            >
                <!-- <el-table-column
                    align="left"
                    label="序号"
                    width="100"
                    prop="index"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column> -->
                <el-table-column
                    v-for="{ prop, label, sortable } in tableColNames"
                    :key="prop"
                    align="center"
                    :label="label"
                    :prop="prop"
                    :sortable="sortable"
                >
                    <template slot-scope="scope">
                        {{
                            prop !== 'status'
                                ? scope.row[prop] || '-'
                                : scope.row[prop]
                                | dictionaryFilter(
                                    vueInstance,
                                    '管会规划状态'
                                )
                        }}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    width="200"
                    prop="operation"
                >
                    <!-- todo: scope.status 状态值可能叫其他的名字-->
                    <template slot-scope="scope">
                        <el-button type="text" @click="toDetail(scope.row)">详情</el-button>
                        <el-button v-if="[1, 2, 3].includes(scope.row.status)" type="text" @click="toReport(scope.row)">报表</el-button>
                        <el-button
                            v-if="[1, 2, 3].includes(scope.row.status)"
                            type="text"
                            @click="handleStatus(scope.row)"
                        >
                            {{ scope.row.status | statusText }}
                        </el-button>
                        <el-button
                            v-if="scope.row.status === 1"
                            type="text"
                            @click="handleCancel(scope.row)"
                        >
                            取消
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import gbDictionary from '@/components/dictionary'
import accountSvc from 'services/accounting'

export default {
    name: 'OperationActionLibraryList',
    filters: {
        statusText (val) {
            const texts = {
                1: '提交',
                2: '撤回',
                3: '重新填报'
            }
            return texts[val]
        },
        dictionaryFilter: gbDictionary.filter
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            vueInstance: this,
            tableColNames: [
                {
                    label: '编码',
                    prop: 'planNo'
                },
                {
                    label: '组织',
                    prop: 'groupName'
                },
                {
                    label: '规划时间',
                    prop: 'dataYearMonth'
                },
                {
                    label: '状态',
                    prop: 'status'
                },
                {
                    label: '提交人',
                    prop: 'submitter'
                }
                // {
                //     label: '关联审批单编号',
                //     prop: 'approvalCode'
                // }
            ]
        }
    },
    created () {
        gbDictionary.init(this, ['管会规划状态'])
    },
    methods: {
        handleStatus (val) {
            //  提交/撤回/重新填报
            const status = val.status
            const statusText = this.$options.filters.statusText(status)
            this.$confirm(`确定要${statusText}吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                console.log(this.$options.filters)
                const type = {
                    1: 'submit', // '提交'
                    2: 'revocation', // '撤回'
                    3: 'again' // '重新填报'
                }
                await accountSvc[`${type[status]}Plan`]({ id: val.id })
                this.$message.success(`${statusText}成功`)
                this.$emit('handle-search')
            })
        },
        toDetail (row) {
            this.$router.push({
                path: '/document/excelInfo',
                query: { id: row.planNo, servername: row.serverName, type: row.type }
            })
        },
        async handleCancel (val) {
            // 取消
            this.$confirm('确定要取消吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                await accountSvc.cancelPlan({ id: val.id })
                this.$message.success('取消成功')
                this.$emit('handle-search')
            })
        },
        toReport (data) {
            this.$router.push({
                path: 'planning-report',
                query: {
                    fillData: JSON.stringify(data)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.planning-fill-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.14rem;

        & + div {
            margin-top: 0.19rem;
        }

        &-operation {
            display: flex;
            align-items: center;
        }

        &-operation .item {
            margin-right: 0.19rem;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        &-operation .item i {
            margin-right: 0.09rem;
            font-size: 0.12rem;
        }

        & > span {
            color: #606266;
        }
    }

    &-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    ::v-deep.el-table {
        .has-gutter th {
            font-size: 0.14rem;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;

            &:hover {
                background: #f7f9fc !important;
            }

            .el-pagination * {
                background: transparent;
            }
        }

        .el-table--fit {
            background-color: #f7f9fc;
        }
    }

    ::v-deep.el-tabs__nav-wrap {
        padding: 0 1.16rem;

        .el-tabs__item {
            color: #909399;
        }
    }

    ::v-deep.el-table th.is-leaf {
        font-size: 0.14rem;
        font-weight: 500;
        color: #606266;
        background-color: #f7f9fc;
    }

    ::v-deep.el-tabs__header {
        margin: 0;
    }

    ::v-deep.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
}
</style>
