<template>
    <el-dialog
        class="data-pull"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
        width="400px"
    >
        <el-form
            ref="form"
            :show-message="false"
        >
            <div class="tip">若账单存在批量新单据需要重新计算时进行该操作，拉取时间较长请耐心等待。</div>
            <el-form-item label="账单时间" required>
                <el-date-picker
                    v-model="dateRange"
                    :class="{required}"
                    type="daterange"
                    range-separator="至"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button
                type="primary"
                @click="handleConfirm"
            >保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountSvc from 'services/accounting'
export default {
    name: 'AddPeriod',
    components: {},
    data () {
        return {
            visible: false,
            title: '账单重新拉取',
            groupId: '',
            dateRange: [],
            required: false
            // loading: false
        }
    },
    computed: {
        valid () {
            return this.dateRange && this.dateRange[0] && this.dateRange[1]
        }
    },
    watch: {
        valid (newValue) {
            this.required = !newValue
        },
        visible (newValue) {
            if (newValue) {
                this.required = false
            }
        }
    },
    created () {},
    methods: {
        async handleConfirm () {
            if (this.valid) {
                try {
                    this.required = false
                    const param = {
                        groupId: this.groupId,
                        bookTimeStart: this.dateRange[0],
                        bookTimeEnd: this.dateRange[1]
                    }
                    console.log(param)
                    // this.loading = true
                    // this.loading = false
                    this.handleVisible(false)
                    // this.$emit('handle-refresh')
                    this.$message.success('操作成功，数据更新后将通过钉钉通知，请稍候查看！')
                    await accountSvc.jkyDataPull(param)
                } catch (error) {
                    console.log(error)
                    // this.loading = false
                }
            } else {
                this.required = true
                this.$message.warning('请选择账单日期！')
                return false
            }
        },
        handleCancel () {
            this.clearValidate()
            this.handleVisible(false)
        },
        handleVisible (boo) {
            this.dateRange = null
            this.visible = boo
        },
        clearValidate () {
            this.$refs.form.clearValidate()
        },
        resetForm () {
            this.$refs.form.resetFields()
        }
    }
}
</script>
<style lang="scss" scoped>
.data-pull {
    ::v-deep .el-dialog__body {
        min-height: 200px;

        .tip {
            padding-bottom: 20px;
            line-height: 1.5;
        }

        .required {
            border-color: #f56c6c;
        }
    }

    ::v-deep .el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }
}
</style>
