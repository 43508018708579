<template>
    <el-dialog
        class="filling-dialog"
        width="600px"
        top="25vh"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-form
            :model="form"
            ref="form"
            :show-message="false"
            label-position="top"
        >
            <el-row>
                <el-form-item label="从组织" prop="from" required>
                    <el-select v-model="form.from" filterable>
                        <el-option
                            v-for="(dept, index) in depts"
                            :key="index"
                            :value="dept.id"
                            :label="dept.groupName"
                            :disabled="dept.disabled"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-row>
            <el-row>
                <el-form-item prop="to" label="到组织" required>
                    <el-select
                        v-model="form.to"
                        multiple
                        collapse-tags
                        filterable
                    >
                        <el-option
                            v-for="(dept, index) in depts"
                            :key="index"
                            :value="dept.id"
                            :label="dept.groupName"
                            :disabled="dept.disabled"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-row>
        </el-form>
        <!-- <el-row>
          <el-tag v-for="item in selectedObjs" :key="item.id">{{ item.groupName }}</el-tag>
        </el-row> -->
        <span slot="footer" class="dialog-footer">
            <el-button
                type="primary"
                @click="handleConfirm"
                >保存</el-button
            >
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountSvc from 'services/accounting'
export default {
    name: '',
    components: {},
    data() {
        return {
            visible: false,
            title: '复制规则',
            form: {
                from: '',
                to: []
            },
            depts: [],
            loading: false,
            selectedObjs: []
        }
    },
    computed: {
        selected() {
            let arr = []
            arr.push(this.form.from)
            console.log(arr.concat(this.form.to));
            return arr.concat(this.form.to)
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.depts.forEach(item => {
                    item.disabled = false
                })
                this.form.from = ''
                this.form.to = []
                this.$nextTick(()=>{
                  this.$refs.form.clearValidate()
                })
            }
        },
        selected: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.depts.forEach(item => {
                    if (newValue.includes(`${item.id}`)) {
                        item.disabled = true
                    } else {
                      item.disabled = false
                    }
                })
            }
        },
        'form.to': {
            immediate: true,
            deep: true,
            handler(newVal) {
                this.selectedObjs = []
                if (newVal.length > 0) {
                    newVal.forEach(item => {
                        const r = this.depts.find(
                            d => Number(d.id) === Number(item)
                        )
                        this.selectedObjs.push(r)
                    })
                }
            }
        }
    },
    created() {},
    methods: {
        handleCancel() {
            this.visible = false
        },
        handleConfirm() {
            // this.loading = true
            this.$refs.form.validate(valid => {
                if (valid) {
                    const toNames = []
                    const fromName = this.depts.find(
                        item => Number(item.id) === Number(this.form.from)
                    ).groupName
                    this.depts.forEach(item => {
                        if (this.form.to.includes(`${item.id}`)) {
                            toNames.push(item.groupName)
                        }
                    })
                    const h = this.$createElement
                    this.$msgbox({
                        title: '注意',
                        message: h(
                            'div',
                            null,
                            [
                                h(
                                    'p',
                                    null,
                                    '当前复制不可撤销，复制后将清空对应组织下的收支设置放入来源组织的设置设置，是否确定进行当前操作!'
                                ),
                                h(
                                    'p',
                                    { style: 'color:teal' },
                                    '当前操作为将设置复制'
                                ),
                                h('p', { style: 'color:teal' }, [
                                    h('span', null, '从组织'),
                                    h(
                                        'span',
                                        { style: 'margin-left:5px' },
                                        `${fromName}`
                                    )
                                ]),
                                h('p', { style: 'color:teal' }, [
                                    h('span', null, '到组织'),
                                    h(
                                        'span',
                                        { style: 'margin-left:5px' },
                                        `${toNames.join('、')}`
                                    )
                                ])
                            ]
                            // h('p',{style: 'color:teal'},this.from)
                        ),
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        showCancelButton: true,
                        type: 'warning'
                    })
                        .then(async () => {
                            const param = {
                                sourceGroupId: this.form.from,
                                targetGroupIds: this.form.to
                            }
                            await accountSvc.copyGroupBudget(param)
                            this.$message({
                                type: 'success',
                                message: '复制成功!'
                            })
                            this.$emit('handle-refresh')
                            this.visible = false
                        })
                        .catch(() => {
                            this.$message({
                                type: 'info',
                                message: '已取消复制'
                            })
                        })
                } else {
                    console.log('error submit')
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.el-dialog {
    .el-row {
        line-height: 44px;
    }
    .el-select {
        width: 60%;
    }
}
</style>
