<template>
    <div class="budget-fill">
        <el-form class="page-search" :inline="true">
            <div class="left">
                <el-form-item>
                    <el-input
                        v-model="searchForm.titleLike"
                        placeholder="名称"
                        clearable
                        @keyup.native.enter="queryData"
                        @clear="queryData"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.periodId"
                        clearable
                        placeholder="期间选项"
                        @change="queryData"
                    >
                        <el-option
                            v-for="item in periodList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.groupId"
                        filterable
                        placeholder="组织"
                        clearable
                        @change="queryData"
                    >
                        <el-option
                            v-for="leafDept in depts"
                            :key="leafDept.id"
                            :value="leafDept.id"
                            :label="leafDept.groupName"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.status"
                        filterable
                        placeholder="状态"
                        clearable
                        @change="queryData"
                    >
                        <el-option
                            v-for="item in statusOptions"
                            :key="item.value"
                            :value="item.value"
                            :label="item.label"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="queryData"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="showFillDialog">
                    <i class="iconfont">生成预算填报</i>
                </span>
                <span @click="showPeriodDia">
                    <i class="iconfont">预算期间管理</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table
                    ref="table"
                    :data="tableData"
                    height="500"
                    row-key="id"
                >
                    <el-table-column type="index" align="center">
                        <template slot="header">
                            <i
                                class="el-icon-s-tools"
                                @click="showColumnSortDia"
                            ></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(column, index) in activeColumns"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :min-width="column.minWidth"
                        :formatter="column.formatter"
                        :align="column.align ? column.align : 'center'"
                        :show-overflow-tooltip="column.showTip"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        fixed="right"
                        :min-width="55"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="toDetail(scope.row)"
                            >
                                详情
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="tableData && tableData.length"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @current-change="handlesCurrentChange"
                    @size-change="handlesSizeChange"
                ></el-pagination>
            </div>
        </div>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
        <addPeriod ref="addPeriod" @handle-refresh="getPeriodList" />
        <addFill ref="addFill" @handle-refresh="getTableData" />
    </div>
</template>

<script>
import _ from 'lodash'
import utilsTable from '@/utils/table'
import accountSvc from 'services/accounting'
import oaSvc from 'services/oa'
import columnSortDia from '@/components/columnSortDialog'
import BreadSlotMixin from 'mixins/breadSlot'
import { breadSlot, addPeriod, addFill } from './components/index'
import bus from 'utils/eventBus'

// 插入自定义面包屑
const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'BudgetFill',
    components: { columnSortDia, addPeriod, addFill },
    mixins: [breadSlotMixin],
    data () {
        return {
            /**
             *  hidden: false, 是否隐藏列
             *  showTip: true, 文字过长悬浮提示
             */
            columns: [
                {
                    prop: 'fillNo',
                    label: '编码',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'title',
                    label: '名称',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'groupName',
                    label: '组织',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'accountingBudgetPeriodTitle',
                    label: '预算期间',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'periodStart',
                    label: '开始时间',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'periodEnd',
                    label: '结束时间',
                    showTip: true,
                    hidden: false
                },
                {
                    prop: 'status',
                    label: '填报状态',
                    formatter: this.statusFilter,
                    showTip: true,
                    hidden: false
                }
            ],
            searchForm: {
                current: 1,
                size: 20,
                status: '',
                groupId: '',
                periodId: '',
                titleLike: ''
            },
            total: 0,
            tableData: [],
            depts: [],
            periodList: [],
            statusOptions: [
                { value: 0, label: '待提交' },
                { value: 1, label: '已提交' },
                { value: 2, label: '已审核' }
            ]
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        }
    },
    created () {
        this.getTableData()
        this.getOwnLeafDepts()
        this.getPeriodList()
    },
    activated () {
        bus.$on('handle-refresh', () => {
            this.getTableData()
        })
    },
    methods: {
        ...utilsTable,
        async getPeriodList () {
            const { data = [] } = await accountSvc.getPeriodList()
            this.periodList = data
        },
        async getTableData () {
            const res = await accountSvc.getFillListByPage(this.searchForm)
            this.tableData = res.data
            this.total = res.total
        },
        async getOwnLeafDepts () {
            const { data = [] } = await oaSvc.getOwnDepts()
            this.depts = data.filter(item => item.leaf)
        },
        showPeriodDia () {
            const _ref = this.$refs.addPeriod
            _ref.form.list = _.cloneDeep(this.periodList)
            _ref.visible = true
        },
        showFillDialog () {
            const hasPeriod = this.periodList.length
            const _ref = this.$refs.addFill
            if (!hasPeriod) {
                this.$message.warning('请先添加预算期间')
                return
            }
            _ref.list = _.cloneDeep(this.periodList)
            _ref.depts = this.depts
            _ref.visible = true
        },
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        queryData () {
            this.searchForm.current = 1
            // this.searchForm.size = 20
            this.getTableData()
        },
        toDetail (data) {
            this.$router.push({
                path: 'budget-fill/detail',
                query: {
                    fillId: data.id
                }
            })
        },
        handlesCurrentChange (val) {
            this.searchForm.current = val
            this.getTableData()
        },
        handlesSizeChange (val) {
            this.searchForm.current = 1
            this.searchForm.size = val
            this.getTableData()
        },
        reset () {
            this.searchForm = {
                current: 1,
                size: 20
            }
            this.getTableData()
        },
        statusFilter (row, column, value, index) {
            return this.statusOptions.find(item => item.value === value).label
        }
    }
}
</script>
<style lang="scss" scoped>
.budget-fill {
    .el-icon-s-tools {
        cursor: pointer;
    }
}
</style>
