<template>
    <div class="budget-fill-detail">
        <el-card class="box-card">
            <div slot="header">
                <el-page-header :content="fillInfo.title" @back="goBack">
                </el-page-header>
                <span>编码：{{ fillInfo.fillNo }}</span>
                <!-- <span>名称：{{ fillInfo.title }}</span> -->
                <span class="group">组织：{{ fillInfo.groupName }}</span>
                <span>预算期间：{{ fillInfo.accountingBudgetPeriodTitle }}</span>
                <span>时间期间：{{ fillInfo.periodStart }} ~
                    {{ fillInfo.periodEnd }}</span>
                <span>状态：{{ fillInfo.status | statusFilter }}</span>
                <div class="custom-btn">
                    <!-- <el-button v-if="!isEdit" @click="getTableData()"
                        >刷新</el-button
                    > -->
                    <el-button
                        v-if="isEdit"
                        @click="cancelEdit"
                    >
                        取消
                    </el-button>
                    <el-button
                        v-if="fillInfo.status === 0 && !isEdit"
                        type="primary"
                        @click="handleSubmit"
                    >
                        提交
                    </el-button>
                    <el-button
                        v-if="fillInfo.status === 1 && !isEdit"
                        type="primary"
                        @click="handleApprove"
                    >
                        审核
                    </el-button>
                    <!-- <el-button
                        type="danger"
                        @click="handleDel"
                        v-if="fillInfo.status === 0 && !isEdit"
                        >删除</el-button
                    > -->
                    <el-button
                        v-if="fillInfo.status > 0 && !isEdit"
                        @click="handleReject"
                    >
                        驳回
                    </el-button>
                    <el-button
                        v-if="fillInfo.status === 1 && !isEdit"
                        type="info"
                        @click="handleWithdraw"
                    >
                        撤回
                    </el-button>
                    <el-button
                        v-if="fillInfo.status !== 2 && !isEdit"
                        type="success"
                        @click="changeComName"
                    >
                        编辑
                    </el-button>
                    <el-button
                        v-if="isEdit"
                        type="primary"
                        @click="handleSave"
                    >
                        保存
                    </el-button>
                    <!-- <el-button icon="el-icon-refresh" circle></el-button> -->
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="刷新"
                        placement="top"
                    >
                        <el-button
                            v-if="!isEdit"
                            style="border: none;"
                            icon="el-icon-refresh"
                            circle
                            @click="getTableData()"
                        ></el-button>
                    </el-tooltip>
                </div>
            </div>
            <div class="page-content">
                <div class="page-handle-btn">
                    <span v-if="fillInfo.status === 0" @click="handleInput">
                        <i class="iconfont icon-daoru">导入</i>
                    </span>
                    <!-- <span
                        v-if="fillInfo.status === 0 && !isEdit"
                        @click="changeComName()"
                    >
                        <i class="iconfont icon-bianji">编辑</i>
                    </span> -->
                    <span v-if="!isEdit" @click="handleDel()">
                        <i class="iconfont icon-huishouzhan">删除</i>
                    </span>
                </div>
                <div class="gb-table">
                    <component
                        :is="comName"
                        ref="fillTable"
                        :budget-type-options="budgetTypeOptions"
                        :com-name="comName"
                        :table-data="tableData"
                        :list="list"
                        :dynamic-columns="dynamicColumns"
                        @changeComName="changeComName"
                        @handle-refresh="getTableData()"
                    ></component>
                    <!-- <fillDetailTable
                        ref="fillDetailTable"
                        :comName="comName"
                        :tableData="list"
                        :dynamicColumns="dynamicColumns"
                    /> -->
                    <!-- <fillEditTable
                        ref="fillEditTable"
                        v-if="comName === 'fillEditTable'"
                        :tableData="tableData"
                        :dynamicColumns="dynamicColumns"
                    /> -->
                </div>
            </div>
        </el-card>
        <upload-dialog ref="uploadDialog" @initData="getTableData" />
    </div>
</template>

<script>
import _ from 'lodash'
import utilsTable from '@/utils/table'
import accountSvc from 'services/accounting'
import fillDetailTable from './components/fillDetailTable'
import fillEditTable from './components/fillEditTable'
import { makeDataToTree } from '@/utils'
import UploadDialog from './components/uploadDialog'
import comSvc from 'services/common'
import bus from 'utils/eventBus'
const statusOptions = [
    { value: 0, label: '待提交' },
    { value: 1, label: '已提交' },
    { value: 2, label: '已审核' }
]
export default {
    name: 'BudgetFillDetail',
    components: {
        fillDetailTable,
        fillEditTable,
        UploadDialog
    },
    filters: {
        statusFilter: function (value) {
            let res = statusOptions.find(item => item.value === Number(value))
            return res ? res.label : '-'
        }
    },
    data () {
        return {
            comName: 'fillDetailTable',
            /**
             *  hidden: false, 是否隐藏列
             *  showTip: true, 文字过长悬浮提示
             */
            columns: [
                {
                    prop: 'title',
                    label: '编码',
                    hidden: false
                }
            ],
            dynamicColumns: [],
            tableData: [],
            fillInfo: {},
            fillId: '',
            list: [],
            budgetTypeOptions: []
        }
    },
    computed: {
        isEdit () {
            return this.comName === 'fillEditTable'
        }
    },
    created () {
        // this.fillId = this.$route.query.fillId
        // // this.getFillInfo()
        // this.getTableData()
    },
    activated () {
        this.comName = 'fillDetailTable'
        this.fillId = this.$route.query.fillId
        this.getFillInfo()
        this.getTableData()
        this.getDictionary()
    },
    methods: {
        ...utilsTable,
        async getFillInfo () {
            const { data = [] } = await accountSvc.getFillList({
                id: this.fillId
            })
            if (data.length === 1) {
                this.fillInfo = data[0]
            }
        },
        async getDictionary () {
            const { data = [] } = await comSvc.getDictionary('管会预算填写方式')
            this.budgetTypeOptions = data
        },
        handleInput () {
            this.$refs.uploadDialog.fillId = this.fillId
            this.$refs.uploadDialog.dialogVisible = true
        },
        async getTableData () {
            this.dynamicColumns = []
            const { data = [] } = await accountSvc.getFillItemList({
                fillId: this.fillId
            })
            this.list = _.cloneDeep(data)
            console.log(this.list)
            this.tableData = makeDataToTree(
                data,
                'itemParentId',
                'itemId'
            )
            let startDate
            let count
            if (
                this.tableData[0] &&
                this.tableData[0].fillDetailDatas &&
                this.tableData[0].fillDetailDatas.length > 0
            ) {
                startDate = this.tableData[0].fillDetailDatas[0].dataYearMonth
                count = this.tableData[0].fillDetailDatas.length - 1
            }
            console.log(count)
            for (let index = 0; index <= count; index++) {
                let obj = {}
                obj.label = this.$moment(startDate)
                    .add(index, 'month')
                    .format('YYYY-MM')
                obj.itemValue = `value${index + 1}`
                obj.taxRate = `rate${index + 1}`
                this.dynamicColumns.push(obj)
            }
            this.$nextTick(() => {
                this.$refs.fillTable.tableLayout()
            })
            console.log(this.dynamicColumns)
        },
        changeComName () {
            this.comName =
                this.comName === 'fillDetailTable'
                    ? 'fillEditTable'
                    : 'fillDetailTable'
        },
        goBack () {
            this.$store.dispatch('tagsView/delView', this.$route)
            this.$router.push({ path: '/finance/accounting/budget-fill' })
        },
        cancelEdit () {
            this.comName = 'fillDetailTable'
        },
        handleDel () {
            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const param = {
                        id: this.fillId
                    }
                    await accountSvc.delFill(param)
                    this.goBack()
                    bus.$emit('handle-refresh')
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        handleSubmit () {
            this.$confirm('此操作将提交审核, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const param = {
                        id: this.fillId
                    }
                    await accountSvc.submitFill(param)
                    this.getFillInfo()
                    this.$message({
                        type: 'success',
                        message: '提交成功！'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消提交'
                    })
                })
        },
        handleApprove () {
            this.$confirm('此操作将审核该预算填报, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const param = {
                        id: this.fillId
                    }
                    await accountSvc.approveFill(param)
                    this.getFillInfo()
                    this.$message({
                        type: 'success',
                        message: '审核成功！'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消审核'
                    })
                })
        },
        handleWithdraw () {
            this.$confirm('此操作将撤回该预算填报, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const param = {
                        id: this.fillId
                    }
                    await accountSvc.withdrawFill(param)
                    this.getFillInfo()
                    this.$message({
                        type: 'success',
                        message: '撤回成功！'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消撤回'
                    })
                })
        },
        handleReject () {
            this.$confirm('此操作将驳回该预算填报, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    const param = {
                        id: this.fillId
                    }
                    await accountSvc.rejectFill(param)
                    this.getFillInfo()
                    this.$message({
                        type: 'success',
                        message: '驳回成功！'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消驳回'
                    })
                })
        },
        handleSave () {
            this.$refs.fillTable.tableSubmit()
        }
    }
}
</script>
<style lang="scss" scoped>
.budget-fill-detail {
    height: 100%;

    ::v-deep .el-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        .el-card__header > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .el-page-header__content {
                display: inline-block;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .el-card__body {
            flex: 1;
            overflow: auto;
            // display: flex;
            // flex-direction: column;
        }

        .page-content {
            // flex: 1;
            height: 100%;

            .fill.el-table {
                .cell {
                    position: relative;
                    padding-left: 25px;

                    .el-table__indent {
                        padding-left: 0 !important;
                    }

                    .el-table__expand-icon {
                        position: absolute;
                        left: 0;
                    }
                }
                // }
                .el-table__row.el-table__row--level-1 {
                    background-color: #f7f9fc;
                }
            }
        }

        .fill-info {
            margin-bottom: 15px;
            line-height: 54px;
            color: #909399;
        }
    }
}
</style>
