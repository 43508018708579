import request from '@/utils/request'
import axios from 'axios'

const fetch = (
    path = '',
    params = {},
    restConfig = {
        method: 'post',
        'Content-Type': 'application/json'
    }
) =>
    request('tradeServer', path, {
        params,
        ...restConfig
    })

export default {
    //                            _ooOoo_
    //                           o8888888o
    //                           88" . "88
    //                           (| -_- |)
    //                            O\ = /O
    //                        ____/'---'\____
    //                       .  ' \\| |// '  .
    //                       / \\||| : |||// \
    //                     / _||||| -:- |||||- \
    //                       | | \\\ - /// | |
    //                     | \_| ''\---/'' |_/  |
    //                      \ .-\__ `-` ___/-. /
    //                   ___`. .' /--.--\ '. .`___
    //                ."" '< '.___\_<|>_/___.' >' "".
    //               | | : `- `.;`\ _ /`;.`/ -` : | |
    //                 \ \ `-. \_ __\ /__ _/ .-` / /
    //         ======'-.____`-.___\_____/___.-`____.-'======
    //                            '=---='
    //         .............................................
    //                            供应链工作台
    getStockMoney (partnerId) {
        // 存货金额
        return fetch('/trade/stock/stockAmt', { partnerId })
    },
    getStockCost (query) {
        // 出库货值
        return fetch('/trade/stock/stockCost', query, 'json')
    },
    getUnSendOrder () {
        // 未发货订单
        return fetch('/trade/stock/unSendOrder')
    },
    getStockRate () {
        // 货值占比
        return fetch('/trade/stock/rate')
    },
    getStockMonitor (param) {
        // 库存监控及供应链日报多条件查询接口
        return fetch('/trade/stock/monitor', param)
    },
    // ------------end-------------
    //  /trade/stock/monitor
    //                            _ooOoo_
    //                           o8888888o
    //                           88" . "88
    //                           (| -_- |)
    //                            O\ = /O
    //                        ____/'---'\____
    //                       .  ' \\| |// '  .
    //                       / \\||| : |||// \
    //                     / _||||| -:- |||||- \
    //                       | | \\\ - /// | |
    //                     | \_| ''\---/'' |_/  |
    //                      \ .-\__ `-` ___/-. /
    //                   ___`. .' /--.--\ '. .`___
    //                ."" '< '.___\_<|>_/___.' >' "".
    //               | | : `- `.;`\ _ /`;.`/ -` : | |
    //                 \ \ `-. \_ __\ /__ _/ .-` / /
    //         ======'-.____`-.___\_____/___.-`____.-'======
    //                            '=---='
    //         .............................................
    //                            采购工作台
    getPurchaseFlow () {
        // 采购跟进
        return fetch('/trade/purchase/follow')
    },
    getSettlement () {
        // 入库结算
        return fetch('/trade/purchase/settlement')
    },
    getTradeMonitor () {
        // 到货监控
        return fetch('/trade/purchase/tradeMonitor')
    },
    getPurchaseIncrease () {
        // 采购价涨幅
        return fetch('/trade/purchase/purchaseIncrease')
    },
    getWarehouseList () {
        return fetch('/trade/stock/warehouse')
    },
    // 供应链日报列表
    getSupplyChainList (query) {
        return fetch('/trade/stock/monitorPage', query)
    },
    // 到货跟进列表
    getPurchaseFollowList (query) {
        return fetch('/trade/purchase/followDetail', query)
    },
    // 采购价格列表
    getPurchasePriceList (query) {
        return fetch('/trade/purchase/change', query)
    },
    // 获取供应商
    getVendors () {
        return fetch('/trade/purchase/vendor')
    },
    //  /trade/purchase/vendor
    // 货品警戒值查询
    getTradestockalarm (query) {
        return fetch('/tradestockalarm/tradestockalarm-page', query, 'json')
    },
    // 新增警戒值
    saveTradestockalarm (query) {
        return fetch('/tradestockalarm/save', query, 'json')
    },
    // 更新警戒值
    updateTradestockalarm (query) {
        return fetch('/tradestockalarm/update', query, 'json')
    },
    /* ------------------------------ 交易流通模块web接口start ----------------------------- */
    /* ----- https://i.gubeios.cn/wiki/pages/viewpage.action?pageId=4784328 ----- */
    getOrderPage (query) {
        // 1.1数据追踪-订单明细查询
        return fetch('/order/order-page', query)
    },
    getOrderSum (query) {
        // 1.1数据追踪-订单明细查询
        return fetch('/order/order-sum', query)
    },
    exportOrder (query) {
        return fetch('/order/order-export', query)
    },
    getBillPage (query) {
        // 1.1数据追踪-订单明细查询
        return fetch('/bill/bill-page', query)
    },
    getBillSum (query) {
        // 1.1数据追踪-订单明细查询
        return fetch('/bill/bill-sum', query)
    },
    exportBill (query) {
        return fetch('/bill/bill-export', query)
    }
    /* ------------------------------ 交易流通模块web接口end ----------------------------- */
}
