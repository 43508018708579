const vueFiles = require.context('./', false, /(\.\/)+\S+\.vue$/) // lazy是否懒加载 第四个参数不填默认同步
const target = {}
vueFiles.keys().forEach(item => {
    const fileName = item.split('/').pop().replace(/\.\w+$/, '')
    const config = vueFiles(item)
    // target[fileName] = config.default || config
    target[fileName] = config.default || config // 异步加载
})

export default target
