<template>
    <div class="empty">
        <img v-if="showImg" src="../assets/images/empty.png" alt="">
        <p>{{ text }}</p>
    </div>
</template>

<script>

export default {
    name: 'Empty',
    props: {
        showImg: {
            type: Boolean,
            default: true
        },
        text: {
            type: String,
            default: '暂无数据'
        }
    },
    data () {
        return {}
    },
    created () { },
    methods: {}
}
</script>
<style lang='less' scoped>
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    img {
        // width: 50%;
        // height: 50%;
    }

    p {
        color: #999;
    }
}
</style>
