<template>
    <el-dialog
        class="add-fill"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-form ref="form" :model="form" :show-message="false">
            <el-form-item label="预算期间" prop="periodId" required>
                <el-select v-model="form.periodId" placeholder="请选择预算期间">
                    <el-option
                        v-for="item in list"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-checkbox
                v-model="checkAll"
                :indeterminate="isIndeterminate"
                @change="handleCheckAllChange"
            >
                组织全选
            </el-checkbox>
            <el-checkbox-group
                v-model="checkedDepts"
                @change="handleCheckedDeptsChange"
            >
                <el-checkbox
                    v-for="dept in depts"
                    :key="dept.id"
                    :label="dept"
                >
                    {{ dept.groupName }}
                </el-checkbox>
            </el-checkbox-group>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountSvc from 'services/accounting'
export default {
    name: 'AddPeriod',
    components: {},
    data () {
        return {
            visible: false,
            title: '生成填报',
            list: [],
            checkedDepts: [],
            checkAll: false,
            isIndeterminate: false,
            depts: [],
            form: {
                periodId: ''
            }
        }
    },
    watch: {
        visible (newValue, oldValue) {
            if (newValue) {
                this.checkedDepts = []
                this.form = {}
                this.checkAll = false
                this.isIndeterminate = false
                this.$nextTick(() => {
                    this.$refs.form.clearValidate()
                })
            }
        }
    },
    methods: {
        handleConfirm () {
            this.$refs.form.validate(async valid => {
                if (!valid) {
                    this.$message.warning('请选择预算期间！')
                    return
                }
                if (!this.checkedDepts.length) {
                    this.$message.warning('请选择组织组织！')
                    return
                }
                const param = {
                    accountingBudgetPeriodId: this.form.periodId,
                    oaGroupDtoList: this.checkedDepts
                }
                await accountSvc.addFill(param)
                this.$message.success('添加成功！')
                this.$emit('handle-refresh')
                this.loading = false
                this.visible = false
            })
        },
        handleCancel () {
            this.visible = false
        },
        handleCheckAllChange (val) {
            this.checkedDepts = val ? this.depts : []
            this.isIndeterminate = false
        },
        handleCheckedDeptsChange (value) {
            console.log(value)
            let checkedCount = value.length
            this.checkAll = checkedCount === this.depts.length
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.depts.length
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
    min-width: 550px;

    input {
        width: 240px;
        border-radius: 0;
    }

    .el-dialog__body {
        max-height: 600px;
        overflow: auto;
    }

    .el-checkbox-group {
        .el-checkbox {
            width: 30%;
            padding: 5px 0;
        }
    }
}
</style>
