<template>
    <div class="">
        <div class="page-content">
            <div class="gb-table">
                <el-table
                    v-if="show"
                    ref="table"
                    :style="'padding-bottom: 44px'"
                    border
                    :indent="0"
                    height="4rem"
                    :data="tableData"
                    row-key="accountingItemId"
                    :cell-class-name="tableColumnClassName"
                >
                    <el-table-column
                        type="index"
                        min-width="50"
                        label=""
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        :label="groupName"
                        width="200"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.accountingItemTitle }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="currentValue"
                        label="金额"
                        width="200"
                        align="right"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span
                                v-if="scope.row.accountingItemTitle !== '销量'"
                                :class="{
                                    lessZero: isLessZero(scope.row.currentValue),
                                }"
                            >
                                {{ scope.row.currentValue | moneyFilter }}
                            </span>
                            <span
                                v-else
                                :class="{
                                    lessZero: isLessZero(scope.row.currentValue),
                                }"
                            >
                                {{ scope.row.currentValue }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="currentRate"
                        label="比例"
                        width="200"
                        align="left"
                        :show-overflow-tooltip="true"
                    >
                        <template slot-scope="scope">
                            <span
                                :class="{
                                    lessZero: isLessZero(scope.row.currentRate)
                                        || isLessZero(scope.row.currentValue),
                                }"
                            >
                                {{ filterRate(scope.row.currentRate) }}
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import accountSvc from 'services/accounting'
import utils from '@/utils/index'

export default {
    name: 'PlanningFillDetail',
    components: {},
    filters: {
        moneyFilter: function (value) {
            return '￥' + utils.moneyFilter(value)
        }
    },
    data () {
        return {
            tableData: [],
            show: true
        }
    },
    computed: {
        groupName () {
            return this.$route.query.groupName
        }
    },
    watch: {
        $route ({ name }, oldValue) {
            console.log(name, '=====name')
            if (name === 'PlanningFillDetail') {
                this.initData()
            }
        }
    },
    created () {
        this.initData()
    },
    methods: {
        async initData () {
            try {
                const type = this.$route.query.type
                const apiName = type == 1 ? 'fillReportLeaftDetail' : 'fillReportProductDetail'
                const param = {
                    planId: this.$route.query.planId,
                    spuId: this.$route.query.spuId
                }
                const { data = {} } = await accountSvc[apiName](param)
                this.tableData = utils.makeDataToTree(data, 'parentId', 'accountingItemId')
            } catch (error) {
                console.log(error)
            }
        },
        // 给某一列增加class类
        tableColumnClassName ({ columnIndex }) {
            if (columnIndex === 1) {
                return 'level'
            }
        },
        // 处理表格数据--金额
        filterValue (row, propName) {
            let value =
            propName !== '销量'
                ? '￥' + utils.moneyFilter(row.currentValue)
                : Math.round(row.currentValue)
            return value
        },
        // 处理表格数据--比例
        filterRate (rate) {
            return rate ? Number(rate).toFixed(2) + '%' : '-'
        },
        // 判断数据是否小于0
        isLessZero (val) {
            if (!isNaN(Number(val))) {
                return Number(val) < 0
            }
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-table {
    .level {
        .cell {
            position: relative;
            padding-left: 25px;

            .el-table__indent {
                padding-left: 0 !important;
            }

            .el-table__expand-icon {
                position: absolute;
                left: 0;
            }
        }
    }

    .el-table__row.el-table__row--level-1 {
        background-color: #f7f9fc;
    }
}
</style>
