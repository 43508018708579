<template>
    <div class="planning-fill-operations">
        <div class="main">
            <!-- <span><i class="iconfont">规划设置</i></span> -->
            <span><i class="iconfont" @click="handleRuleDialog">生成规划</i></span>
        </div>
        <rules-dialog ref="rules" v-on="$listeners"></rules-dialog>
    </div>
</template>
<script>
import rulesDialog from './rulesDialog'

export default {
    name: 'PlanningFillOperations',
    components: {
        rulesDialog
    },
    methods: {
        handleRuleDialog () {
            // 开启生成规则
            this.$refs.rules.handleOpenDialog()
        }
    }
}
</script>
<style lang="scss" scoped>
.planning-fill-operations {
    .main {
        span {
            display: inline-block;
            margin-bottom: 10px;
            margin-left: 20px;
            font-size: 0.14rem;
            color: #3595e1;
            cursor: pointer;

            &:first-of-type {
                margin-left: 0;
            }

            i {
                font-size: 0.14rem;
            }
        }
    }
}
</style>
