<template>
    <div class="job-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            multiple
            collapse-tags
            @change="handleChange"
        >
            <el-option
                v-for="item in jobs"
                :key="item.id"
                :label="item.jobName"
                :value="item.id"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import oaSvc from 'services/oa'

export default {
    name: 'JobSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            maxjob: 31,
            selected: '',
            jobs: []
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    created () {
        this.getJobListAll()
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        },
        async getJobListAll () {
            const { data = [] } = await oaSvc.getJobList()
            this.jobs = data
        }
    }
}
</script>
<style lang="scss" scoped>
.job-select {
    display: inline-block;
    width: 240px;
}
</style>
