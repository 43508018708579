<template>
    <el-table
        v-if="show"
        ref="editTable"
        class="fill"
        :data="tableData"
        :row-key="row => Number(row.itemId)"
        :header-cell-style="{ 'text-align': 'center' }"
        default-expand-all
        height="500"
    >
        <el-table-column
            prop="itemPathName"
            label="收支项目"
            fixed="left"
            :show-overflow-tooltip="true"
            min-width="150px"
        >
        </el-table-column>
        <!-- <el-table-column
            prop="budgetType"
            label="预算填写方式"
            fixed="left"
            align="center"
            :formatter="this.budgetTypeDictsFilter"
            :show-overflow-tooltip="true"
        >
        </el-table-column> -->
        <el-table-column
            prop="resultSign"
            label="属性"
            fixed="left"
            :show-overflow-tooltip="true"
            align="center"
        >
            <template slot-scope="scope">
                {{ Number(scope.row.resultSign) === 1 ? '正' : '负' }}
            </template>
        </el-table-column>
        <template v-for="(column, index) in dynamicColumns">
            <el-table-column :key="column.itemId" :label="column.label">
                <template slot-scope="scpoe">
                    <el-input
                        v-model="scpoe.row[`value${index + 1}`]"
                        onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                        maxlength="15"
                    ></el-input>
                </template>
            </el-table-column>
        </template>
    </el-table>
</template>

<script>
import { makeDataToTree, moneyFilter } from '@/utils'
import tableMethods from 'utils/table'
import _ from 'lodash'
import accountSvc from 'services/accounting'
export default {
    name: 'FillEditTable',
    props: {
        list: {
            type: Array,
            default: () => []
        },
        dynamicColumns: {
            type: Array,
            default: () => []
        },
        budgetTypeOptions: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            show: false,
            tableData: []
        }
    },
    computed: {
        getRowkeys (row) {
            return row.id
        }
        // list() {
        //     return makeDataToTree(this.tableData, 'itemParentId', 'itemId')
        // }
    },
    watch: {
        comName (newValue, oldValue) {
            console.log(newValue)
        }
    },
    mounted () {
        this.tableData = _.cloneDeep(this.list).filter(
            item => item.budgetType === 23
        )
        this.tableData.forEach(item => {
            item.itemPathName = item.itemPathName.replace(/\|/g, ' > ')
        })
        this.show = true
        this.$nextTick(() => {
            console.log('----')
            this.$refs.editTable.doLayout()
        })
    },
    methods: {
        moneyFilter,
        ...tableMethods,
        async tableSubmit () {
            const param = {
                fillId: this.$route.query.fillId,
                accountingFillItems: this.tableData
            }
            console.log(param)
            await accountSvc.editFill(param)
            this.$message.success('操作成功！')
            this.$emit('changeComName')
            this.$emit('handle-refresh')
        },
        budgetTypeDictsFilter (row, column, value, index) {
            const res = this.budgetTypeOptions.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        }
    }
}
</script>
