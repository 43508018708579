<template>
    <el-dialog
        class="filling-dialog"
        width="600px"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-row> 当前组织：{{ groupName }} </el-row>
        <el-row>
            当前收支设置生效时间
        </el-row>
        <el-row>
            <el-date-picker
                v-model="dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
            >
            </el-date-picker>
        </el-row>
        <el-row>
            说明：
            <el-input
                v-model="deacription"
                :rows="3"
                type="textarea"
            ></el-input>
        </el-row>
        <div class="tip">
            注意：该归档将把当前收支设置归档至对应时间范围下，一般用于出现新的合作模式造成费用或收入计算出现了变化，归档时时间范围不可与之前归档时间冲突，归档后不可修改编辑，所有在归档时间内的数据按照归档方式进行计算，非归档内时间按照当前收支设置进行计算
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            groupName: '',
            visible: false,
            title: '归档收支设置',
            deacription: '',
            dateRange: []
        }
    },
    created() {},
    methods: {
        handleConfirm() {
            const param = {}
        },
        handleCancel() {
            this.deacription = ''
            this.dateRange = []
            this.visible = false
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    .el-row {
        line-height: 54px;
    }
}
</style>
