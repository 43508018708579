<template>
    <div class="bread-slot">
        <el-dropdown class="item">
            <span class="el-dropdown-link">
                快捷入口<i class="iconfont icon-xiala"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/budget-fill')
                    "
                >
                    预算填报
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/actual/list')
                    "
                >
                    数据补录
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/budget-setting')
                    "
                >
                    收支设置
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/coefficient/list')
                    "
                >
                    系数维护
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="
                        pageSign === '实际报表' || pageSign === '实际报表详情'
                    "
                    @click.native="
                        $router.push('/finance/accounting/daily/list')
                    "
                >
                    日常报表
                </el-dropdown-item>
                <el-dropdown-item
                    v-if="
                        pageSign === '日常报表' || pageSign === '日常报表详情'
                    "
                    @click.native="
                        $router.push('/finance/accounting/actual-report/list')
                    "
                >
                    实际报表
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/data-tracking/order')
                    "
                >
                    订单明细
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/data-tracking/bill')
                    "
                >
                    账单明细
                </el-dropdown-item>
                <el-dropdown-item
                    @click.native="
                        $router.push('/finance/accounting/planning-fill')
                    "
                >
                    规划填报
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <div class="item">
            <i class="iconfont icon-huishouzhan1"></i>
            回收站
        </div>
        <div class="item">
            <i class="iconfont icon-shuoming"></i>
            帮助
        </div>
    </div>
</template>
<script>
export default {
    name: 'BreadSlot',
    computed: {
        pageSign () {
            return this.$route.meta.title || '日常报表'
        }
    }
}
</script>
<style lang="less" scoped>
.bread-slot {
    display: flex;

    .item {
        margin-left: 30px;
        font-size: 14px;
        color: #606266;
        cursor: pointer;

        &:first-of-type {
            margin-left: 0;

            & > span i {
                margin-left: 8px;
                font-size: 10px;
                color: #909399;
            }
        }

        &:not(:first-of-type) {
            display: flex;
            align-items: center;

            & > i {
                margin-right: 8px;
                color: #69acf1;
            }
        }
    }
}
</style>
