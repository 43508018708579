<template>
    <!-- 数据补录 -->
    <div class="distribution-list page">
        <!-- <gb-table :table="table" :isShowPagination="true" @pageChange="pageChange" @sizeChange="sizeChange" /> -->
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <el-form-item label="">
                    <department
                        ref="dept"
                        v-model="searchForm.groupId"
                        api-name="getOwnDepts"
                        placeholder="组织"
                        :is-cascader="true"
                        value-filed="id"
                        label-filed="groupName"
                        @change="querydata"
                    />
                </el-form-item>
                <el-form-item label="">
                    <el-select
                        v-model="searchForm.itemId"
                        clearable
                        placeholder="收支项目"
                        filterable
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in budgetProjects"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-input
                        v-model="searchForm.batchNoLike"
                        placeholder="导入批次"
                        clearable
                        @keyup.enter.native="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :clearable="false"
                        @change="querydata"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="handleInput">
                    <i class="iconfont icon-daoru">导入</i>
                </span>
            </div>
            <gb-table
                :table="table"
                :is-copy="true"
                :table-height="500"
                :is-show-pagination="true"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
            <upload-dialog ref="uploadDialog" @initData="initData" />
        </div>
    </div>
</template>

<script>
import UploadDialog from '../uploadDialog'
import department from '@/components/Department'
import accountingSvc from '@/services/accounting'
import { moneyFilter } from '@/utils/index'
export default {
    name: 'DistributionList',
    components: { UploadDialog, department },
    data () {
        return {
            pickerOptions: {
                // 限制预约时间
                disabledDate (time) {
                    return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
                },
                firstDayOfWeek: 1
            },
            table: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                firstColumn: { type: 'selection' },
                // 标题
                column: [
                    // { prop: 'budgetActualId', label: '编码', minWidth: '100' },
                    {
                        prop: 'startDate',
                        label: '开始日期',
                        minWidth: '100',
                        showTip: true,
                        formatter: val => {
                            return val.startDate
                                ? val.startDate.substring(0, 10)
                                : ''
                        }
                    },
                    {
                        prop: 'endDate',
                        label: '结束日期',
                        minWidth: '100',

                        formatter: val => {
                            return val.endDate
                                ? val.endDate.substring(0, 10)
                                : ''
                        }
                    },
                    { prop: 'groupName', label: '组织', minWidth: '150', showTip: true },
                    // { prop: 'projectName', label: '组织', minWidth: '100' },
                    // { prop: 'shopId ', label: '店铺', minWidth: '100' },
                    {
                        prop: 'productTitle',
                        label: '货品',
                        minWidth: '100',
                        showTip: true
                    },
                    {
                        // prop: 'budgetProjectName',
                        prop: 'accountingItemTitle',
                        label: '收支项目',
                        minWidth: '100'
                    },
                    // { prop: 'actualValue', label: '金额', minWidth: '100' },
                    {
                        prop: 'itemValue',
                        label: '金额',
                        minWidth: '100',
                        formatter: row => {
                            return '￥' + this.moneyFilter(row.itemValue)
                        }
                    },
                    {
                        prop: 'remark',
                        label: '备注',
                        minWidth: '100'
                    },
                    { prop: 'creator', label: '导入人员', minWidth: '100' },
                    // { prop: 'importBatch', label: '导入批次', minWidth: '100' },
                    { prop: 'batchNo', label: '导入批次', minWidth: '100' },
                    { prop: 'createTime', label: '导入时间', minWidth: '100' }
                ],
                operator: [
                    {
                        text: '删除',
                        fun: this.handleDel
                    }
                ],
                operatorConfig: {}
            },
            // 查询条件数据
            searchForm: {},
            dateRange: ['', ''],
            // 导入弹框
            dialogVisible: false,
            budgetProjects: [],
            formLabelWidth: '120px'
        }
    },
    created () {
        this.initData()
        this.getBudgetProjects()
    },
    methods: {
        moneyFilter,
        async initData () {
            const [startDate, endDate] = this.dateRange
            const param = Object.assign(
                {},
                {
                    size: this.table.pageSize,
                    current: this.table.currentPage,
                    startDate,
                    endDate
                },
                this.searchForm
            )
            const res = await accountingSvc.actualPage(param)
            this.table.data = res.data || []
            this.table.total = res.total
        },
        // 数据补录删除
        handleDel (val) {
            this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    await accountingSvc.actualDelete([val], 'json')
                    this.initData()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        async getBudgetProjects () {
            const { data = [] } = await accountingSvc.getBudgetProjects()
            this.budgetProjects = data
        },
        // 获取列表数据的唯一标识
        getRowKey (row) {
            return row.id
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            console.log('====')
            this.table.currentPage = 1
            this.initData()
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            this.searchForm = {}
            this.$refs.dept.cascaderValue = ''
            this.table.currentPage = 1
            this.dateRange = ['', '']
            this.initData()
        },
        // 点击导入按钮
        handleInput () {
            this.$refs.uploadDialog.dialogVisible = true
        }
    }
}
</script>
