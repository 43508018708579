<template>
    <el-dialog
        v-loading="loading"
        class="budget-projects-sort"
        :visible.sync="visible"
        :title="title"
        :lock-scroll="false"
        :close-on-click-modal="false"
        width="600px"
    >
        <div>
            <el-row>
                <el-radio-group v-model="radio">
                    <el-radio :label="1">调整一级收支项目</el-radio>
                    <el-radio :label="2">调整二级收支项目</el-radio>
                </el-radio-group>
            </el-row>
            <el-row v-if="radio ===2">
                <span style="margin-right: 15px;">父收支项目</span>
                <el-select v-model="parentId" style="width: 240px;" @change="selectParent">
                    <el-option
                        v-for="item in allList"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-row>
            <draggable
                v-if="displayList.length >0"
                class="column-body"
                element="ul"
                :list="displayList"
                :options="{ group: 'label', animation: 150 }"
                :no-transition-on-drag="true"
            >
                <li
                    v-for="(item, index) in displayList"
                    :key="index"
                    class="column-item"
                >
                    <div class="left">
                        <i class="iconfont icon-tuodong"></i>
                        {{ item.title }}
                    </div>
                </li>
            </draggable>
            <empty v-else text="暂无收支项目" />
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" :disabled="displayList.length === 0" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import empty from '@/components/empty'
import accountSvc from 'services/accounting'
export default {
    name: 'AddPeriod',
    components: {
        empty
    },
    props: {
        listData: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            visible: false,
            title: '收支项目顺序调整',
            allList: [],
            radio: 1,
            displayList: [],
            parentId: null,
            loading: false
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                this.radio = 1
                this.parentId = null
                console.log(this.listData)
                this.allList = _.cloneDeep(this.listData)
                this.displayList = _.cloneDeep(this.listData)
            }
        },
        radio (newValue) {
            if (newValue === 2) {
                this.displayList = []
            } else {
                this.parentId = null
                this.displayList = _.cloneDeep(this.listData)
            }
        }
    },
    methods: {
        async handleConfirm () {
            try {
                let param = []
                if (this.radio === 1) {
                    param = this.displayList.map(item => {
                        item.childAccountingItems = item.children
                        return item
                    })
                } else {
                    param = this.allList.filter(item => item.id == this.parentId)
                    param[0].childAccountingItems = this.displayList
                }
                console.log(param)
                this.loading = true
                await accountSvc.budgetProjectSort(param)
                this.loading = false
                this.handleVisible(false)
                this.$message.success('操作成功！')
                this.$emit('handle-refresh')
            } catch (error) {
                this.loading = false
            }
            // this.visible = false
        },
        handleCancel () {
            this.handleVisible(false)
        },
        handleVisible (boo) {
            this.visible = boo
        },
        selectParent (val) {
            console.log(val)
            const allList = _.cloneDeep(this.listData)
            allList.forEach(item => {
                if (item.id == val) {
                    this.displayList = item.children || []
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.budget-projects-sort {
    ::v-deep .el-dialog__body {
        max-height: 560px;
        // padding: 0;
        // overflow-y: auto;
        .el-row {
            padding: 10px 0;
        }

        .column {
            &-container {
                display: flex;
                flex-direction: column;
                height: 100%;
            }

            &-title,
            &-item {
                display: flex;

                // .left,
                // .right {
                //     width: 50%;
                //     height: 100%;
                //     line-height: 54px;
                // }

                .left {
                    padding-left: 20px;
                    line-height: 54px;

                    .iconfont {
                        margin-right: 20px;
                        font-size: 14px;
                        color: #c0c4cc;
                    }
                }

                .right {
                    text-align: center;
                }
            }

            &-title {
                height: 54px;
                font-weight: 600;
                background-color: #f7f9fc;
            }

            &-body {
                flex: 1;
                // max-height: 560px;
                // overflow: auto;
            }

            &-item {
                cursor: all-scroll;
                border-bottom: 1px solid #f0f0f0;
            }
        }
    }

    ::v-deep .el-dialog__footer {
        padding: 10px;
        border-top: 2px solid #f0f0f0;

        .el-button {
            width: auto;
            height: auto;
            padding: 10px 18px;
            font-size: 14px;
            border-radius: 8px;
        }
    }
}
</style>
