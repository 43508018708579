<template>
    <div class="budget-setting">
        <el-form class="page-search">
            <div class="left">
                <el-form-item>
                    <el-select
                        v-model="groupId"
                        filterable
                        placeholder="组织"
                        @change="getGroupBudgetList"
                    >
                        <el-option
                            v-for="leafDept in depts"
                            :key="leafDept.id"
                            :value="leafDept.id"
                            :label="leafDept.groupName"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="setBudget">
                    <i class="iconfont icon-shezhi1">设置收支</i>
                </span>
                <span @click="showCopyDialog">
                    <i class="el-icon-document-copy">复制规则</i>
                </span>
                <span @click="accountGroupCheck">
                    <i class="iconfont">数据校验</i>
                </span>
                <span @click="handleBdgSort">
                    <i class="iconfont">调整顺序</i>
                </span>
                <span>
                    <el-dropdown trigger="click">
                        <i class="iconfont icon-gengduocaozuo">更多操作</i>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                @click.native="showFillingDia"
                            >归档收支设置</el-dropdown-item>
                            <el-dropdown-item>查看已有归档</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
            </div>
            <div class="gb-table">
                <el-table
                    ref="table"
                    border
                    height="500"
                    :data="listData"
                    row-key="id"
                    :expand-row-keys="expandRowKeys"
                >
                    <el-table-column type="index" min-width="70">
                        <template slot="header">
                            <i
                                class="el-icon-s-tools"
                                @click="showColumnSortDia"
                            ></i>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(column, index) in activeColumns"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :min-width="column.minWidth || 100"
                        :formatter="column.formatter"
                        :align="column.align ? column.align : 'center'"
                        :show-overflow-tooltip="column.showTip"
                    >
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        fixed="right"
                        :min-width="150"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                @click="handleEdit(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                type="text"
                                @click="handleDel(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <set-dialog
            ref="setDialog"
            :cate-dicts="cateDicts"
            :peoperty-dicts="peopertyDicts"
            @handle-refresh="getGroupBudgetList"
        ></set-dialog>
        <filling-dialog ref="fillingDialog"></filling-dialog>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
        <copy-dialog
            ref="copyDialog"
            :depts="depts"
            @handle-refresh="getGroupBudgetList"
        ></copy-dialog>
        <edit-dialog
            ref="editDialog"
            :budget-options="budgetOptions"
            :list-data="list"
            @handle-search="getGroupBudgetList"
        ></edit-dialog>
        <bdgSortDialog
            ref="bdgSortDialog"
            :group-id="groupId"
            :list-data="listData"
            @handle-refresh="getGroupBudgetList"
        />
    </div>
</template>

<script>
import _ from 'lodash'
import oaSvc from 'services/oa'
import comSvc from 'services/common'
import accountingSvc from 'services/accounting'
import utilsTable from '@/utils/table'
import { makeDataToTree } from '@/utils'

import setDialog from './components/setDialog'
import fillingDialog from './components/fillingDialog'
import columnSortDia from '@/components/columnSortDialog'
import copyDialog from './components/copyDialog'
import editDialog from './components/editDialog'
import bdgSortDialog from './components/budget-projects-sort'
import breadSlot from './components/breadSlot'
import BreadSlotMixin from 'mixins/breadSlot' // 插入自定义面包屑
import { mapMutations } from 'vuex'

const breadSlotMixin = new BreadSlotMixin({ breadSlot })

export default {
    name: 'BudgetSetting',
    components: {
        setDialog,
        fillingDialog,
        copyDialog,
        columnSortDia,
        editDialog,
        bdgSortDialog
    },
    mixins: [breadSlotMixin],
    data () {
        return {
            depts: [],
            groupId: null,
            listData: [],
            expandRowKeys: [],
            columns: [
                {
                    prop: 'accountingItemTitle',
                    label: '收支项目',
                    minWidth: '100',
                    hidden: false,
                    showTip: true,
                    align: 'left'
                },
                {
                    prop: 'itemNo',
                    label: '编码',
                    minWidth: '100',
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'resultSign',
                    label: '属性',
                    minWidth: '100',
                    formatter: this.peopertyDictsFilter,
                    hidden: false,
                    showTip: true
                },
                {
                    prop: 'itemCategory',
                    label: '分类',
                    hidden: false,
                    formatter: this.cateDictsFilter,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'itemPriority',
                    label: '优先级',
                    hidden: false,
                    minWidth: '100',
                    showTip: true
                },
                {
                    prop: 'taxRate',
                    label: '税率',
                    hidden: false,
                    minWidth: '150',
                    formatter: this.percent,
                    showTip: true
                },
                {
                    prop: 'budgetType',
                    label: '预算填写方式',
                    minWidth: '150',
                    hidden: false,
                    formatter: this.budgetTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'planType',
                    label: '迭代规划方式',
                    minWidth: '100',
                    hidden: false,
                    formatter: this.planTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'dataFrom',
                    label: '实际取数方式',
                    minWidth: '100',
                    hidden: false,
                    formatter: this.actualTypeDictsFilter,
                    showTip: true
                },
                {
                    prop: 'dailyType',
                    label: '日常管理方式',
                    formatter: this.dailyTypeDictsFilter,
                    minWidth: '150',
                    hidden: false,
                    showTip: true
                }
            ],
            list: [],
            groupInfo: {},
            cateDicts: [],
            peopertyDicts: [],
            huanbijizhunDicts: [],
            budgetTypeDicts: [],
            planTypeDicts: [],
            dailyTypeDicts: [],
            actualTypeDicts: [],
            budgetOptions: []
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        },
        ownedIds () {
            return this.list.map(item => item.itemId)
        }
    },
    created () {
        this.getAccoutingProjects()
        this.getDictionary()
        this.getLeafDepts()
    },
    activated () {
        this.addSlot(breadSlot)
    },
    methods: {
        ...utilsTable,
        ...mapMutations('transmit', {
            addSlot: 'UPDATE_BREAD_SLOT'
        }),
        // 顺序调整
        handleBdgSort () {
            const _ref = this.$refs.bdgSortDialog
            _ref.visible = true
        },
        async getAccoutingProjects () {
            const { data = [] } = await accountingSvc.getBudgetListCommon()
            this.budgetOptions = data
            // this.budgetOptions = data.filter(item => !item.leafFlag)
            // this.budgetOptions.push({
            //     id: 0,
            //     title: '（无）'
            // })
        },
        async getDictionary () {
            const { data = [] } = await comSvc.getDictionary(
                '管会收支项目分类,管会收支项目属性,管会同环比基准,管会预算填写方式,管会迭代规划方式,管会日常管理方式,管会实际取数方式'
            )
            this.cateDicts = data.filter(
                item => item.itemGroup === '管会收支项目分类'
            )
            this.peopertyDicts = data.filter(
                item => item.itemGroup === '管会收支项目属性'
            )
            this.huanbijizhunDicts = data.filter(
                item => item.itemGroup === '管会同环比基准'
            )
            this.budgetTypeDicts = data.filter(
                item => item.itemGroup === '管会预算填写方式'
            )
            this.planTypeDicts = data.filter(
                item => item.itemGroup === '管会迭代规划方式'
            )
            this.dailyTypeDicts = data.filter(
                item => item.itemGroup === '管会日常管理方式'
            )
            this.actualTypeDicts = data.filter(
                item => item.itemGroup === '管会实际取数方式'
            )
            console.log(this.peopertyDicts)
        },
        async getLeafDepts () {
            const { data = [] } = await oaSvc.getOwnDepts()
            this.depts = data.filter(item => item.leaf)
            this.setDefault()
            this.getGroupBudgetList()
        },
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        // 默认组织
        setDefault () {
            this.groupId = this.depts.length > 0 ? this.depts[0].id : null
            if (this.groupId) {
                this.groupInfo = this.depts.find(
                    item => item.id === this.groupId
                )
            }
        },
        // 查询组织下收支项目
        async getGroupBudgetList () {
            try {
                if (this.groupId) {
                    this.groupInfo = this.depts.find(
                        item => item.id === this.groupId
                    )
                }
                const { data = [] } = await accountingSvc.getGroupBudgetList({
                    groupId: this.groupId
                })
                this.list = _.cloneDeep(data)
                this.listData = makeDataToTree(data, 'parentId', 'itemId')
            } catch (error) {
                console.log(error)
            }
        },
        handleEdit (row) {
            const _ref = this.$refs.editDialog
            row.parentId = Number(row.parentId) ? Number(row.parentId) + '' : null
            row.formula = row.chineseFormula ? row.chineseFormula : ''
            row.basedItemId = row.basedItemId == 0 ? null : row.basedItemId
            _ref.form = _.cloneDeep(row)
            _ref.isEdit = true
            _ref.visible = true
        },
        handleDel (row) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(async () => {
                    await accountingSvc.delGroupBudget([row])
                    this.$message.success('删除成功')
                    this.getGroupBudgetList()
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    })
                })
        },
        setBudget () {
            const _ref = this.$refs.setDialog
            _ref.searchForm.filterAaccountingItemIds = this.ownedIds
            // _ref.getTableData()
            _ref.groupId = this.groupId
            _ref.visible = true
        },
        showCopyDialog () {
            const _ref = this.$refs.copyDialog
            _ref.depts = this.depts
            _ref.visible = true
        },
        showFillingDia () {
            // const _ref = this.$refs.fillingDialog
            // _ref.groupName = this.groupInfo.groupName
            // _ref.visible = true
        },
        // 数据校验
        async accountGroupCheck () {
            try {
                await accountingSvc.accountGroupCheck({
                    groupId: this.groupId
                })
                this.$message.success('检验成功！')
            } catch (error) {
                console.log(error)
            }
        },
        cateDictsFilter (row, column, value, index) {
            const res = this.cateDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        peopertyDictsFilter (row, column, value, index) {
            const res = this.peopertyDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        huanbijizhunDictsFilter (row, column, value, index) {
            const res = this.huanbijizhunDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        budgetTypeDictsFilter (row, column, value, index) {
            const res = this.budgetTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        planTypeDictsFilter (row, column, value, index) {
            const res = this.planTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        dailyTypeDictsFilter (row, column, value, index) {
            const res = this.dailyTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        },
        actualTypeDictsFilter (row, column, value, index) {
            const res = this.actualTypeDicts.find(
                item => Number(item.itemValue) === Number(value)
            )
            return res ? res.title : '-'
        }
    }
}
</script>
<style lang="scss" scoped>
.budget-setting {
    .el-icon-s-tools {
        cursor: pointer;
    }

    ::v-deep .el-table {
        .el-table_1_column_2 {
            .cell {
                position: relative;
                padding-left: 25px;

                .el-table__indent {
                    padding-left: 0 !important;
                }

                .el-table__expand-icon {
                    position: absolute;
                    left: 0;
                }
            }
        }

        .el-table__row.el-table__row--level-1 {
            background-color: #f7f9fc;
        }
    }
    // }
    .el-table__row.el-table__row--level-1 {
        background-color: #f7f9fc;
    }
}
</style>
