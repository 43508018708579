export default {
    status(row, column, value, index) {
        return value ? '有效' : '无效'
    },
    syncStatus(row, column, value, index) {
        return value ? '是' : '否'
    },
    date(row, column, value, index) {
        return value ? value.substr(0, 10) : ''
    },
    percent(row, column, value, index) {
        let scale = ''
        if (value != undefined && value != null) {
            // scale = (value * 100.0).toFixed(2) + '%'
            scale = value.toFixed(2) + '%'
        }
        return scale
    },
    price(row, column, value, index) {
        let scale = ''
        if (value != undefined && value != null) {
            // scale = (value * 100.0).toFixed(2) + '%'
            scale = '￥' + Number(value).toFixed(2)
        }
        return scale
    },
    dicFilter(row, column, value, index, arr) {
        const res = arr.find(
            item => Number(item.itemValue) === Number(value)
        )
        return res ? res.title : null
    },
    // 表格重绘
    tableReLoayout(tableRefName) {
        this.$nextTick(() => {
            this.$refs[tableRefName].doLayout()
        })
    }
}
