<template>
    <el-table
        class="fill"
        ref="table"
        v-if="show"
        :data="tableData"
        row-key="id"
        height="500"
    >
        <el-table-column
            prop="accountingItemTitle"
            label="收支项目"
            min-width="120px"
            fixed="left"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            prop="budgetType"
            label="预算填写方式"
            min-width="120px"
            fixed="left"
            align="left"
            :formatter="this.budgetTypeDictsFilter"
            :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
            prop="resultSign"
            label="属性"
            fixed="left"
            :show-overflow-tooltip="true"
            align="center"
        >
            <template slot-scope="scope">
                {{ Number(scope.row.resultSign) === 1 ? '正' : '负' }}
            </template>
        </el-table-column>
        <el-table-column label="合计" align="center">
            <el-table-column
                prop="valueSum"
                label="金额"
                align="center"
                :formatter="price"
                min-width="120"
                :show-overflow-tooltip="true"
            >
                <!-- <template slot-scope="scope">
                    {{ '￥' + scope.row.valueSum.toFixed(2) }}
                </template> -->
            </el-table-column>
            <el-table-column
                prop="rateSum"
                label="比例"
                align="center"
                :formatter="percent"
                :show-overflow-tooltip="true"
                min-width="120"
            >
                <!-- <template slot-scope="scope">
                    {{ scope.row.taxRate.toFixed(2) + '%' }}
                </template> -->
            </el-table-column>
        </el-table-column>

        <template v-if="comName === 'fillDetailTable'">
            <el-table-column
                :label="column.label"
                :key="index"
                v-for="(column, index) in dynamicColumns"
            >
                <el-table-column
                    :prop="column.itemValue"
                    label="金额"
                    align="center"
                    :formatter="price"
                    min-width="120"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        {{ price(null, null, scope.row[`value${index + 1}`]) }}
                        <!-- {{ scope.row }} -->
                    </template>
                </el-table-column>
                <el-table-column
                    :prop="column.taxRate"
                    label="比例"
                    align="center"
                    min-width="120"
                    :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        {{ percent(null, null, scope.row[`rate${index + 1}`]) }}
                    </template>
                </el-table-column>
            </el-table-column>
        </template>
        <!-- <template v-else>
            <el-table-column
                v-for="(column, index) in dynamicColumns"
                :label="column.label"
                :key="column.itemId"
                min-width="70"
            >
                <template slot-scope="scpoe">
                    <el-input
                        v-model="scpoe.row[`value${index + 1}`]"
                    ></el-input>
                </template>
            </el-table-column>
        </template> -->
    </el-table>
</template>

<script>
import {  moneyFilter } from '@/utils'
import tableMethods from 'utils/table'
import _ from 'lodash'
export default {
    name: 'FillDetailTable',
    props: {
        tableData: {
            type: Array,
            default: () => []
        },
        dynamicColumns: {
            type: Array,
            default: () => []
        },
        budgetTypeOptions: {
            type: Array,
            default: () => []
        },
        comName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            show: false,
            list: []
        }
    },
    watch: {
        comName(newValue, oldValue) {
            console.log(newValue)
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        }
    },
    created() {
        // this.list = _.cloneDeep(this.tableData)
        this.show = true
        this.$nextTick(() => {
            this.$refs.table.doLayout()
        })
    },
    computed: {
        // list() {
        //     return makeDataToTree(this.tableData, 'itemParentId', 'itemId')
        // }
    },
    methods: {
        moneyFilter,
        ...tableMethods,
        tableLayout() {
            this.tableReLoayout('table')
        },
        budgetTypeDictsFilter(row, column, value, index) {
            const res = this.budgetTypeOptions.find(
                item => Number(item.itemValue) === Number(value)
            )
            let func = ''
            if (res.title === '使用公式' && row.chineseFormula) {
                func = '：' + row.chineseFormula
            }
            return res ? res.title + func : '-'
        }
    }
}
</script>
