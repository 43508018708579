<template>
    <div class="date-type-select">
        <el-select
            v-model="selected"
            v-bind="$attrs"
            placeholder=""
            @change="handleChange"
        >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
export default {
    name: 'DateTypeSelect',
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        value: {
            type: Number,
            default: null
        }
    },
    data () {
        return {
            selected: ''
        }
    },
    computed: {
        options () {
            const obj = {
                1: '日',
                2: '周',
                3: '月'
            }
            return Object.keys(obj).map((item, index) => {
                return {
                    label: obj[item],
                    value: index + 1
                }
            })
        }
    },
    watch: {
        value: {
            immediate: true,
            handler (newVal) {
                this.selected = newVal
            }
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        }
    }
}
</script>
<style lang="scss" scoped>
.date-type-select {
    display: inline-block;
    width: 60px;
}
</style>
