<template>
    <el-dialog
        :visible.sync="visible"
        title="显示字段设置"
        width="600px"
        class="abow_dialog"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <div class="column-container">
            <div class="column-title">
                <div class="left">字段名称</div>
                <div class="right">状态</div>
            </div>
            <draggable
                class="column-body"
                element="ul"
                :list="columnsCopy"
                :options="{ group: 'label', animation: 150 }"
                :no-transition-on-drag="true"
                @end="sortChanged"
            >
                <li
                    class="column-item"
                    v-for="(item, index) in columnsCopy"
                    :key="index"
                >
                    <div class="left">
                        <i class="iconfont icon-tuodong"></i>
                        {{ item.label }}
                    </div>
                    <div class="right">
                        <el-radio v-model="item.hidden" :label="false"
                            >显示</el-radio
                        >
                        <el-radio v-model="item.hidden" :label="true"
                            >隐藏</el-radio
                        >
                    </div>
                </li>
            </draggable>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'
export default {
    name: 'ColumnSortDialog',
    components: {},
    props: {
        columns: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            visible: false,
            columnsCopy: []
        }
    },
    created() {},
    watch: {
        visible(newValue) {
            if (newValue) {
                this.columnsCopy = _.cloneDeep(this.columns)
            }
        }
    },
    methods: {
        sortChanged(val) {
            console.log(val)
        },
        handleCancel() {
            this.visible = false
        },
        handleConfirm() {
            this.$emit('column-change', this.columnsCopy)
            this.visible = false
        }
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog {
    max-height: 740px;
    display: flex;
    flex-direction: column;
    .el-dialog__header {
        height: 54px;
        box-sizing: border-box;
    }
    .el-dialog__body {
        padding: 0 20px;
        overflow: hidden;
        flex: 1;
    }
    .column {
        &-container {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        &-title,
        &-item {
            display: flex;
            .left,
            .right {
                width: 50%;
                height: 100%;
                line-height: 54px;
            }
            .left {
                padding-left: 50px;
                .iconfont {
                    color: #c0c4cc;
                    font-size: 14px;
                    margin-right: 20px;
                }
            }
            .right {
                text-align: center;
            }
        }
        &-title {
            height: 54px;
            background-color: #f7f9fc;
            font-weight: 600;
        }
        &-body {
            flex: 1;
            overflow: auto;
            max-height: 560px;
        }
        &-item {
            cursor: all-scroll;
            border-bottom: 1px solid #f0f0f0;
        }
    }
    .el-dialog__footer {
        height: 60px;
        padding: 10px;
        line-height: 40px;
        border-top: 2px solid #f0f0f0;
    }
}
</style>
