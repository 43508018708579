<template>
    <el-dialog
        class="add-dialog"
        :visible.sync="visible"
        :title="title"
        width="800px"
        :lock-scroll="false"
        :close-on-click-modal="false"
    >
        <el-form
            ref="form"
            :model="form"
            :show-message="false"
            label-width="120px"
            inline
        >
            <el-form-item v-if="isEdit" label="编码">
                <el-input
                    v-model="form.itemNo"
                    :disabled="isEdit"
                    placeholder="请输入收支项目"
                ></el-input>
            </el-form-item>
            <el-form-item label="收支项目" prop="accountingItemTitle" required>
                <el-input
                    v-model.trim="form.accountingItemTitle"
                    max-length="20"
                    placeholder="请输入收支项目"
                    :disabled="isEdit"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="父收支项目">
                <el-select
                    v-model="form.parentId"
                    placeholder=""
                    :disabled="isEdit"
                    clearable
                    filterable
                >
                    <el-option
                        v-for="item in budgetOption"
                        :key="item.id"
                        :value="item.id"
                        :label="item.title"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="属性" prop="resultSign" required>
                <gb-dictionary
                    v-model="form.resultSign"
                    group="管会收支项目属性"
                    placeholder="请选择属性"
                    clearable
                ></gb-dictionary>
            </el-form-item>
            <el-form-item label="分类" prop="itemCategory" required>
                <gb-dictionary
                    v-model="form.itemCategory"
                    group="管会收支项目分类"
                    placeholder="分类"
                    clearable
                ></gb-dictionary>

                <!-- <el-select
                    v-model="form.itemCategory"
                    placeholder="管会收支项目分类"
                    clearable
                    filterable
                >
                    <el-option
                        v-for="item in $attrs.cateDicts"
                        :key="item.itemValue"
                        :value="Number(item.itemValue)"
                        :label="item.title"
                    ></el-option>
                </el-select> -->
            </el-form-item>
            <el-form-item label="税率（%）" prop="taxRate" required>
                <el-input-number
                    v-model="form.taxRate"
                    label="税率"
                ></el-input-number>
            </el-form-item>
            <el-form-item label="预算填写方式" prop="budgetType" required>
                <gb-dictionary
                    v-model="form.budgetType"
                    group="管会预算填写方式"
                    placeholder="请选择预算填写方式"
                    clearable
                ></gb-dictionary>
            </el-form-item>
            <el-form-item label="迭代规划方式" prop="planType" required>
                <gb-dictionary
                    v-model="form.planType"
                    group="管会迭代规划方式"
                    placeholder="请选择迭代规划方式"
                    clearable
                ></gb-dictionary>
            </el-form-item>
            <el-form-item label="日常管理方式" prop="dailyType" required>
                <gb-dictionary
                    v-model="form.dailyType"
                    group="管会日常管理方式"
                    placeholder="请选择日常管理方式"
                    clearable
                ></gb-dictionary>
            </el-form-item>
            <el-form-item label="实际取数方式" prop="dataFrom" required>
                <gb-dictionary
                    v-model="form.dataFrom"
                    group="管会实际取数方式"
                    placeholder="请选择实际取数方式"
                    clearable
                ></gb-dictionary>
            </el-form-item>
            <div v-if="showDay" class="hisActualRate">
                <el-form-item prop="beforeDate" required>
                    历史实际比例默认取历史&nbsp;&nbsp;
                    <el-input-number
                        v-model="form.beforeDate"
                        controls-position="right"
                        :min="0"
                        size="mini"
                    ></el-input-number>
                </el-form-item>
                <el-form-item prop="beforeDateCount" required>
                    &nbsp;&nbsp;天前&nbsp;
                    <el-input-number
                        v-model="form.beforeDateCount"
                        controls-position="right"
                        :min="0"
                        size="mini"
                    ></el-input-number>&nbsp;&nbsp;天实际发生比例，无历史数据时取预计比例
                </el-form-item>
            </div>
            <div v-if="showDay2" class="hisActualRate">
                <el-form-item prop="beforeDateCount" required>
                    比例基于收支
                </el-form-item>
                <el-form-item prop="basedItemId" required>
                    <el-select
                        v-model="form.basedItemId"
                        class="budgetOption"
                        clearable
                    >
                        <el-option
                            v-for="item in budgetOption"
                            :key="item.id"
                            :value="item.id"
                            :label="item.title"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </div>

            <el-form-item
                v-if="showFormula"
                label="公式"
                class="func"
                required
                prop="formula"
            >
                <el-input
                    ref="formula"
                    v-model="form.formula"
                    type="textarea"
                    :autosize="true"
                    placeholder="请输入公式"
                ></el-input>
                <div class="var">
                    注意：引用收支项目数据自带正负，对应收支属性为负则为负数，属性为正则常规为正数，计算结果往往也同理，若计算的为一个费用项通常结果为负数，系数请在有
                    选择系数计算时选择，选择后将会通过导入的计算进行对应计算
                </div>
            </el-form-item>
            <el-form-item v-if="showFormula" class="vars" label="变量">
                <div class="var">
                    <el-input v-model="searchKey" @input="search">
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="search"
                        ></el-button>
                    </el-input>
                    <ul v-if="vars.length > 0" class="items">
                        <li
                            v-for="(item, index) in vars"
                            :key="index"
                            class="itemq"
                            @click="selectVar(item)"
                        >
                            <el-tooltip
                                v-if="item.length > 9"
                                class="item"
                                effect="dark"
                                :content="item"
                                placement="top-start"
                            >
                                <span>{{ item }}</span>
                            </el-tooltip>
                            <span v-else>{{ item }}</span>
                        </li>
                    </ul>
                    <div v-else class="empty">没有搜索结果</div>
                </div>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="handleConfirm">保存</el-button>
            <el-button @click="handleCancel">取消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import accountingSvc from 'services/accounting'
import _ from 'lodash'
import accountSvc from 'services/accounting'
import gbDictionary from '@/components/dictionary'

export default {
    name: 'EditDialog',
    components: {
        gbDictionary
    },
    props: {
        listData: {
            type: Array,
            default: null
        }
    },
    data () {
        return {
            visible: false,
            isEdit: false,
            form: {
                accountingItemTitle: null,
                parentId: 0,
                leafFlag: true,
                platformId: null,
                tradeType: null,
                budgetType: null,
                dailyType: null,
                dataFrom: null,
                planType: null,
                formula: '',
                beforeDate: 0,
                beforeDateCount: 0,
                beforeDateRate: 0
            },
            searchKey: '',
            vars: [],
            originData: [],
            budgetOption: [],
            budgetOptionCoefficient: []
        }
    },
    computed: {
        title () {
            return this.isEdit ? '编辑收支项目' : '新增收支项目'
        },
        showFormula () {
            return (
                Number(this.form.budgetType) === 22 ||
                    Number(this.form.planType) === 22 ||
                    Number(this.form.dailyType) === 22 ||
                    Number(this.form.dataFrom) === 22
            )
        },
        showDay () {
            // 日常管理方式为历史实际比例
            return Number(this.form.budgetType) === 26 ||
                    Number(this.form.planType) === 26 ||
                    Number(this.form.dailyType) === 26 ||
                    Number(this.form.dataFrom) === 26
        },
        showDay2 () {
            // 日常/迭代管理方式为历史实际比例和预计比例
            return Number(this.form.budgetType) === 26 ||
                    Number(this.form.planType) === 26 ||
                    Number(this.form.dailyType) === 26 ||
                    Number(this.form.dataFrom) === 26 ||
                    Number(this.form.planType) === 24 ||
                    Number(this.form.dailyType) === 24 ||
                    Number(this.form.dataFrom) === 24 ||
                    Number(this.form.dataFrom) === 51 ||
                    Number(this.form.planType) === 51 ||
                    Number(this.form.dailyType) === 51
        }
    },
    watch: {
        visible (newValue) {
            if (newValue) {
                const data = this.budgetOptionCoefficient
                this.vars = data.map((item) => item.title)
                this.originData = _.cloneDeep(this.vars)
                // this.getBudgetProjects()
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate()
                })
                console.log(this.form)
            }
        }
        // showDay(val){
        //   if(!val){
        //     this.form.beforeDate = this.form.beforeDateCount = this.form.beforeDateRate = 0
        //   }
        // }
    },
    created () {
        this.getBudgetOption()
        this.getBudgetOptionCoefficient()
    },
    methods: {
        async getBudgetProjects () {
            const { data = [] } = await accountingSvc.getBudgetProjects()
            this.vars = data.map((item) => item.budgetProjectName)
            this.originData = _.cloneDeep(this.vars)
        },
        handleConfirm () {
            this.$refs['form'].validate(async (valid) => {
                if (valid) {
                    try {
                        const apiName = 'editGroupBudget'
                        if (!this.showFormula) {
                            this.form.formula = ''
                        }
                        await accountingSvc[apiName]([this.form])
                        this.visible = false
                        this.$emit('handle-search')
                        this.$message.success('保存成功！')
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    console.log('error submit!!')
                    this.$message.warning('请完善表单内容！')
                    return false
                }
            })
        },
        handleCancel () {
            this.visible = false
        },
        selectVar (val) {
            this.form.formula += `<${val}>`
            this.$refs.formula.focus()
        },
        search () {
            this.vars = this.originData.filter((item) =>
                item.includes(this.searchKey)
            )
        },
        // 获取收支项目列表
        async getBudgetOption () {
            const { data = [] } = await accountSvc.getBudgetListCommon()
            console.log(data, '=====data')
            this.budgetOption = data
        },
        // 获取收支项目列表
        async getBudgetOptionCoefficient () {
            const { data = [] } = await accountSvc.getBudgetListCoefficient()
            console.log(data, '=====data')
            this.budgetOptionCoefficient = data
        }
    }
}
</script>

<style lang="less" scoped>
    /deep/ .el-dialog {
        display: flex;
        flex-direction: column;
        max-height: 740px;

        .el-form {
            width: 100%;

            .el-input,
            .el-input-number {
                width: 240px;

                .el-input__inner {
                    border-radius: 2px;
                }
            }

            .el-textarea {
                width: 610px;
                height: auto;

                textarea {
                    height: 80px;
                }
            }

            .hisActualRate {
                position: relative;
                display: flex;
                margin-left: 25px;

                .el-input,
                .el-input-number {
                    width: 70px;
                }

                .budgetOption {
                    .el-input {
                        width: 240px;
                    }
                }

                input {
                    padding-right: 30px;
                    padding-left: 10px;
                }

                &::before {
                    position: absolute;
                    top: 10px;
                    left: -10px;
                    color: #f56c6c;
                    content: '*';
                }
            }

            .el-form-item.vars {
                display: flex;

                .el-form-item__content {
                    flex: 1;
                }
            }

            .vars {
                width: 730px;
                padding-right: 13px;
            }
            .var {
                display: flex;
                flex-direction: column;
                padding: 10px;
                border: 1px solid #eee;
                .el-input {
                    width: 100%;
                }

                .el-input__inner,
                .el-input-group__append {
                    border-radius: 0;
                }

                .items {
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 50px;
                    max-height: 200px;
                    overflow: auto;

                    .itemq {
                        box-sizing: border-box;
                        width: 18%;
                        height: 44px;
                        margin-top: 10px;
                        margin-right: 1%;
                        margin-left: 1%;
                        color: #909399;
                        text-align: center;
                        cursor: pointer;
                        background-color: #f4f4f5;
                        border: 1px solid #f4f4f5;

                        > span {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    .itemq:hover {
                        color: #92c4f6;
                        background-color: #f8f8f8;
                        border: 1px solid #d9ecff;
                    }
                }

                .empty {
                    color: #909399;
                    text-align: center;
                }
            }

            .el-form-item.func {
                display: flex;

                .el-form-item__content {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .el-dialog__body {
            flex: 1;
            overflow-y: auto;
        }

        .el-dialog__footer {
            height: 60px;
            padding: 10px;
            line-height: 40px;
            border-top: 2px solid #f0f0f0;
        }
    }
</style>
