<template>
    <div>
        <total :loading="totalLoading" :total-list="totalList"></total>
        <query
            class="query"
            :query="query"
            :default-query="defaultQuery"
            @handle-search="handleCallSearch"
            @handle-reset="handleReset"
        ></query>
        <div class="tool-list">
            <i class="iconfont icon-daoru" @click="handleExport">数据导出</i>
        </div>
        <list v-loading="listLoading" :list="list"></list>
        <el-pagination
            v-if="total > 0"
            class="custom-page"
            :current-page="query.current"
            :page-sizes="[50, 100, 200]"
            :page-size="query.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
import components from './components/index'
import customerSvc from 'services/customer'
import tradeSvc from 'services/trade'
import oaSvc from 'services/oa'
import dataExport from 'components/data-manipulation/data-export'
import qs from 'qs'
import { downloadExcel } from '@/utils'
import axios from 'axios'
import settings from 'settings'

export default {
    name: 'DataTrackingOrder',
    components: {
        ...components
    },
    beforeRouteLeave (from, to, next) {
        this.setAllDepts(null)
        this.setChannels(null)
        next()
    },
    data () {
        return {
            query: {
                current: 1, // 当前页码
                size: 50, // 每页数量
                tradeNo: null, // 订单编号
                onlineTradeNo: null, // 网店订单号
                channelIds: null, // 渠道列表
                skuIds: null, // SKUID
                detailTypes: null, // 单据类型
                searchType: '1', // 查询类型 1:按照订单编号查询，2按网店订单号查询，3按账单流水号查询
                searchTypeValue: null, // 查询类型对应的查询值
                groupId: null, // 组织ID
                // lastTradeTimeStart: null, // 还原单据时间(开始时间)
                // lastTradeTimeEnd: null, // 还原单据时间(结束时间)
                lastTradeTime: null, // 还原单据时间(查询条件) [lastTradeTimeStart, lastTradeTimeEnd]
                // tradeTimeStart: null, // 下单时间(开始时间)
                // tradeTimeEnd: null, // 下单时间(结束时间)
                tradeTime: null, // 下单时间(查询条件) [tradeTimeStart, tradeTimeEnd]
                // payTimeStart: null, // 支付时间(开始时间)
                // payTimeEnd: null, // 支付时间(结束时间)
                payTime: null, // 支付时间(查询条件) [payTimeStart, payTimeEnd]
                tradeStatus: null // 订单状态
            },
            list: [],
            total: 0,
            totalList: {},
            listLoading: false,
            totalLoading: false,
            defaultQuery: {
                size: 50,
                searchType: '1'
            }
        }
    },
    computed: {
        // moreFilterVisible () {
        //     return Object.keys(this.moreFilters).length
        // },
        // moreFilters () {
        //     const filters = {}
        //     const fields = {
        //         searchType: '查询类型',
        //         searchTypeValue: '查询值',
        //         groupId: '组织',
        //         lastTradeTime: '还原单据时间',
        //         tradeTime: '下单时间',
        //         payTime: '支付时间'
        //     }
        //     this.drawerQueryField.forEach((key, index) => {
        //         console.log(this.query[key], index)
        //         if (!_.isEmpty(this.query[key])) {
        //             let queryVal = this.query[key]
        //             if (this.filter[key] !== undefined) {
        //                 queryVal = this.filter[key]
        //             }
        //             filters[key] = `${fields[key]}:${queryVal}`
        //         }
        //     })
        //     return filters
        // }
        params () {
            const query = _.cloneDeep(this.query)
            Object.keys(query).forEach(item => {
                if (['lastTradeTime', 'tradeTime', 'payTime'].includes(item)) {
                    const [start, end] = query[item] || []
                    query[`${item}Start`] = start
                    query[`${item}End`] = end
                    delete query[item]
                } else if (['searchTypeValue'].includes(item)) {
                    query[item] = query[item] ? query[item].split(/[(\s\r\n,，)\s\r\n,，]+/g) : null
                }
            })
            return query
        }
    },
    mounted () {
        this.getAllData()
    },
    methods: {
        handleRefresh () {
            // 刷新
            this.handleSearch()
        },
        handleSizeChange (size) {
            this.query.size = size
            this.handleSearch()
        },
        handleCurrentChange (current) {
            this.query.current = current
            this.handleSearch()
        },
        handleReset (query) {
            // 重置
            this.query = _.cloneDeep(query)
            this.getSearchData()
        },
        handleCallSearch (query) {
            this.query = _.cloneDeep(query)
            this.query.current = 1
            this.getSearchData()
        },
        handleExport: _.debounce(async function () {
            if (this.totalLoading) {
                this.$message.warning('正在加载数据，请稍后再试')
                return
            }
            if (this.total >= 50000) {
                this.$message.warning('数据导出不能超过50000条')
                return
            }
            const query = _.cloneDeep(this.query)
            Object.keys(query).forEach(item => {
                if (['lastTradeTime', 'tradeTime', 'payTime'].includes(item)) {
                    const [start, end] = query[item] || []
                    query[`${item}Start`] = start
                    query[`${item}End`] = end
                    delete query[item]
                } else if (['searchTypeValue'].includes(item)) {
                    query[item] = query[item] ? query[item].split(/[(\s\r\n,，)\s\r\n,，]+/g) : null
                }
            })
            axios({
                method: 'post',
                url:
                    settings.servers.tradeServer +
                    '/order/order-export', // 请求地址
                responseType: 'blob', // 表明返回服务器返回的数据类型
                data: qs.stringify(query),
                headers: {
                    authToken: this.$store.getters['currentUser'].id || '',
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(async ({ data }) => {
                // console.log(code)
                // if (text.includes('110006')) {
                //     this.$message.warning('数据导出不能超过50000条')
                // } else
                // if (text.includes('500')) {
                //     this.$message.error('导出失败，请重试')
                // } else {
                //     downloadExcel(data, '订单数据导出.xlsx')
                // }
                downloadExcel(data, '订单数据导出.xlsx')
            }).catch(err => {
                console.log(err)
            })
        }, 500),
        handleSearch: _.debounce(async function () {
            // 查询
            this.listLoading = true
            const query = _.cloneDeep(this.query)
            Object.keys(query).forEach(item => {
                if (['lastTradeTime', 'tradeTime', 'payTime'].includes(item)) {
                    const [start, end] = query[item] || []
                    query[`${item}Start`] = start
                    query[`${item}End`] = end
                    delete query[item]
                } else if (['searchTypeValue'].includes(item)) {
                    query[item] = query[item] ? query[item].split(/[(\s\r\n,，)\s\r\n,，]+/g) : null
                }
            })
            const { data } = await tradeSvc.getOrderPage(query)
            this.list = data
            const {
                lastTradeTimeStart,
                lastTradeTimeEnd,
                tradeTimeStart,
                tradeTimeEnd,
                payTimeStart,
                payTimeEnd
            } = data || {}
            this.list.lastTradeTime = [lastTradeTimeStart, lastTradeTimeEnd]
            this.list.tradeTime = [tradeTimeStart, tradeTimeEnd]
            this.list.payTime = [payTimeStart, payTimeEnd]
            this.listLoading = false
        }, 500),
        getTotalList: _.debounce(async function () {
            this.totalLoading = true
            const query = _.cloneDeep(this.query)
            Object.keys(query).forEach(item => {
                if (['lastTradeTime', 'tradeTime', 'payTime'].includes(item)) {
                    const [start, end] = query[item] || []
                    query[`${item}Start`] = start
                    query[`${item}End`] = end
                    delete query[item]
                } else if (['searchTypeValue'].includes(item)) {
                    query[item] = query[item] ? query[item].split(/[(\s\r\n,，)\s\r\n,，]+/g) : null
                }
            })
            const { data } = await tradeSvc.getOrderSum(query)
            const {
                sellCount, // 销量合计
                contributions, // 实付金额合计
                detailCost, // 采购成本合计
                skuCost, // 标准成本合计
                sumCost, // 综合成本合计
                total
            } = data
            this.total = total * 1
            this.totalList = {
                sellCount, // 销量合计
                contributions, // 实付金额合计
                detailCost, // 采购成本合计
                skuCost, // 标准成本合计
                sumCost // 综合成本合计
            }
            this.totalLoading = false
        }, 500),
        async getSearchData () {
            await this.handleSearch()
            await this.getTotalList()
        },
        async getChannels () {
            // 获取渠道列表
            const query = {
                enabled: true,
                foeFlag: false
            }
            const { data = [] } = await customerSvc.getChannels(query)
            this.setChannels(data)
        },
        async getAllDepts () {
            // 获取管理组织
            const { data = [] } = await oaSvc.getAllDepts()
            this.setAllDepts(data)
        },
        async getAllData () {
            try {
                this.getChannels()
                this.getAllDepts()
                await this.getSearchData()
            } catch (e) {
                console.log(e)
            } finally {
            }
        },
        ...mapMutations('customer', {
            setChannels: 'UPDATE_CHANNELS',
            setAllDepts: 'UPDATE_ALL_DEPTS'
        })
    },
    activated () {
        // 触发缓存
        this.getAllDepts()
        this.getChannels()
    }
}
</script>
<style lang="less" scoped>
.query {
    margin-top: 0.3rem;
}

.custom-page {
    margin: 0.2rem 0 0.1rem;
    text-align: right;
}

.tool-list {
    margin-bottom: 12px;

    i {
        font-size: 0.14rem;
        color: #3595e1;
        cursor: pointer;

        &::before {
            margin-right: 0.1rem;
        }
    }
}
</style>
