import moment from 'moment'

// 时间本地化设置 周一为本周的第一天
moment.updateLocale('zh-cn', {
    week: {
        dow: 1 // Monday is the first day of the week
    },
    meridiem: function (hour, minute, isLowercase) {
        console.log(hour, '===hour')
        if (hour <= 9) {
            return '早上'
        } else if (hour < 12) {
            return '上午'
        } else if (hour <= 13) {
            return '中午'
        } else if (hour < 18) {
            return '下午'
        } else {
            return '晚上'
        }
    }
})

/**
 *
 * @param {start | end} type 获取上周的开始和结束日期
 * @returns
 */
export const getPrevWeek = (type) => {
    return moment()
        .week(moment().week() - 1)
        [`${type}Of`]('week')
        .format('YYYY-MM-DD')
}
/**
 *
 * @param {start | end} type 开始或者结束
 * @param {*} date
 * @param {*} dateType
 * @returns 获取指定日期的周或者月
 */
export const getCurrentMonthOrWeek = (type, date, dateType) => {
    return moment(date || new Date())
        [`${type}Of`](dateType)
        .format('YYYY-MM-DD')
}

/**
 *
 * @param date 指定日期
 * @returns 获取指定日期的周或者月
 */
export const getMeridiem = (date = null) => {
    return moment().localeData().meridiem(moment().hour())
}
/**
 *
 * @param {数值} number
 * @returns 返回某年/月的同期
 */
export const getTheSamePeriod = (date, number, type = 'year', format = 'YYYY-MM-DD') => {
    return moment(date).subtract(number, type).format(format)
}
// 计算两个日期的差值
export const getDiff = (date1, date2) => {
    return moment(date1).diff(moment(date2), 'days')
}

export const getDateRangeDisplay = (dateRange, separator = '-', format = 'MM.DD') => {
    // console.log(dateRange, '===dateRange')
    if (!dateRange || !(dateRange instanceof Array) || dateRange.length !== 2) {
        return false
    }
    const [startDate, endDate] = dateRange
    const diff = moment(startDate).diff(moment(endDate), 'days')
    return diff
        ? `${moment(startDate).format(format)}${separator}${moment(
            endDate
        ).format(format)}`
        : `${moment(startDate).format(format)}`
}

export default moment
